import React, { useEffect, useState } from 'react';
import Divider from '@mui/material/Divider';
// components
import Page from '../components/Page';
// imports
import CarouselImages from '../components/CarouselImages';
import Publications from '../components/Publications';
import Timeline from '../components/Timeline';
import ImageListC from '../components/ImageList';
import CardList from '../components/CardList';
import { Api } from '../app-controller';

const host = window.location.host.split(":")[0];

const settings = {
  autoPlay: false,
  indicators: true,
  swipe: true,
  cycleNavigation: true,
  navButtonsAlwaysVisible: false,
  navButtonsAlwaysInvisible: false,
  fullHeightHover: true,
  animation: 'fade',
  duration: '500'
};

export default function PaginaPrueba() {
  const [, idPage] = window.location.href.split(`${Api.protocol}//${host}`);
  const hiddenStyle = {
    display: 'none'
  };

  const [menu, setMenu] = useState([]);

  useEffect(() => {
    fetch(`${Api.url}getContenidoPestana`)
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setMenu(data);
        }
      });
  }, []);

  return (
    <Page title= {localStorage.titulo}>
      <div id="test" style={{ scrollBehavior: 'smooth !important' }}>
        {menu.map((pestana) => {
          const { elementos } = pestana;

          return (
            <>
              {elementos.length !== 0 ? (
                elementos.map((elemento, i) => {
                  const { contenido } = elemento;

                  return (
                    <>
                      <section
                        key={elemento.title}
                        id={elemento.id}
                        style={idPage.includes(pestana.path) ? {} : hiddenStyle}
                      >
                        {contenido.length !== 0 ? (
                          <>
                            {parseInt(elemento.elemento_id, 10) === 1 ? (
                              <>
                                <CarouselImages
                                  key={elemento.created}
                                  CarouselSettings={settings}
                                  arrayElements={contenido}
                                />
                                <Divider key={i} variant="middle" sx={{ margin: '20px' }} />
                              </>
                            ) : (
                              <></>
                            )}
                            {parseInt(elemento.elemento_id, 10) === 2 ? (
                              <>
                                <Publications
                                  key={elemento.created}
                                  relId={elemento.id}
                                  titulo={elemento.descripcion}
                                  arrayPublications={contenido}
                                />
                                <Divider key={i} variant="middle" sx={{ margin: '20px' }} />
                              </>
                            ) : (
                              <></>
                            )}
                            {parseInt(elemento.elemento_id, 10) === 3 && Object.keys(contenido).length !== 0? (
                              <>
                                <div key={elemento.created} dangerouslySetInnerHTML={{ __html: contenido.contenido }} />
                                <Divider key={i} variant="middle" sx={{ margin: '20px' }} />
                              </>
                            ) : (
                              <></>
                            )}
                            {parseInt(elemento.elemento_id, 10) === 4 ? (
                              <>
                                <Timeline key={elemento.created} infoTimeline={contenido} />
                                <Divider key={i} variant="middle" sx={{ margin: '20px' }} />
                              </>
                            ) : (
                              <></>
                            )}
                            {parseInt(elemento.elemento_id, 10) === 5 ? ( // resumen
                              <>
                              <CardList key={elemento.created} titulo={elemento.descripcion} arrayCard={contenido}/>
                              <Divider key={i} variant="middle" sx={{ margin: '20px' }} />
                              </>
                            ) : (
                              <></>
                            )}
                            {parseInt(elemento.elemento_id, 10) === 6 ? ( // resumen
                              <>
                                <ImageListC  key={elemento.created} images={contenido} />
                                <Divider key={i} variant="middle" sx={{ margin: '20px' }} />
                              </>
                            ) : (
                              <></>
                            )}
                          </>
                        ) : (
                          <></>
                        )}
                      </section>
                    </>
                  );
                })
              ) : (
                <></>
              )}
            </>
          );
        })}
      </div>
    </Page>
  );
}
