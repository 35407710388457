import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import plusFill from "@iconify/icons-eva/plus-fill";
// material
import {
  Box,
  Card,
  Table,
  Stack,
  Button,
  IconButton,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  Paper,
} from "@mui/material";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";
// components
import Label from "../components/Label";
import Page from "../components/Page";
import Scrollbar from "../components/Scrollbar";
import Iconify from "../components/Iconify";
import NuevoElementoPublicacion from "../components/NuevoElementoPublicacion";
import { Api } from '../app-controller';
// ----------------------------------------------------------------------
const host = window.location.host.split(":")[0];

const tableHead = [
  { id: "titulo", label: "Título", alignRight: false },
  { id: "status", label: "Estado", alignRight: false },
  { id: "opciones", label: "Opciones", alignRight: true },
];

const title = "Nuevo";

class PublicationsAdmin extends React.Component {
  constructor(props) {
    super();
    const location = window.location.pathname;
    const { id } = props.params;
    this.inicialState = {
      elementoInfo: {
        contenido: "",
        imagen: null,
        switchValue: false,
        titulo: "",
        url_pdf: null,
      },
      mensajeError: {
        error: false,
        mensaje: "",
      },
      mensajeErrorSubmit: {
        error: false,
        mensaje: "",
      },
    };

    this.state = {
      publicaciones: [],
      elementoInfo: this.inicialState.elementoInfo,
      rpeInfo: [],
      rpeID: id,
      notFound: false,
      modalShow: false,
      editar: false,
      ver: false,
      nuevo: false,
      mensajeError: this.inicialState.mensajeError,
      mensajeErrorSubmit: this.inicialState.mensajeErrorSubmit,
    };
  }

  componentDidMount() {
    this.setElementos();
  }

  setElementos = () => {
    const id = this.state.rpeID;

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.token}`,
      },
    };

    fetch(
      `${Api.url}getElementosPublicaciones?id=${id}`,
      requestOptions
    )
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        }
        throw new Error(response.status);
      })
      .then((data) => {
        if (data) {
          const auxNotFound = data.publicaciones.length === 0;

          this.setState({
            publicaciones: data.publicaciones,
            rpeInfo: data.rpeInfo,
            notFound: auxNotFound,
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: "No tiene acceso a ésta información",
          icon: "error",
          showCancelButton: false,
          cancelButtonColor: "green",
          confirmButtonText: "Continuar",
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = "/admin/inicio";
          }
        });
      });
  };

  handleOpen = (e) => {
    if (e) this.setState({ nuevo: true });
    this.setState({ modalShow: true });
  };

  handleClose = () => {
    this.setState({
      modalShow: false,
      nuevo: false,
      editar: false,
      ver: false,
      elementoInfo: this.inicialState.elementoInfo,
      mensajeError: this.inicialState.mensajeError,
      mensajeErrorSubmit: this.inicialState.mensajeErrorSubmit,
    });
  };

  handleAddElemento = (e) => {
    e.preventDefault();
    this.setState({ mensajeErrorSubmit: this.inicialState.mensajeErrorSubmit });

    const postData = this.state.elementoInfo;
    postData.rpeID = this.state.rpeID;

    const valido = this.submitValidacion(postData);
    if (valido) {
      this.handleClose();
      Api.loading(true);
      if (this.state.nuevo) {
        const post = new FormData();
        const keys = Object.keys(postData);

        keys.forEach((key) => {
          post.append(key, postData[key]);
        });

        const requestOptions = {
          method: "POST",
          headers: {
            Authorization: `Bearer ${sessionStorage.token}`,
          },
          body: post,
        };

        fetch(
          `${Api.url}createElementoPublicaciones`,
          requestOptions
        )
          .then((response) => {
            if (response.status === 200) {
              return response.json();
            }
            throw new Error(response.status);
          })
          .then((data) => {
            Api.loading(false);
            Swal.fire({
              text: data.message,
              allowOutsideClick: false,
            });
            this.setElementos(); // Actualizo tabla de elementos
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              text: "No tiene acceso a ésta información",
              icon: "error",
              showCancelButton: false,
              cancelButtonColor: "green",
              confirmButtonText: "Continuar",
              allowOutsideClick: false,
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.href = "/admin/inicio";
              }
            });
          });
      } else {
        const post = new FormData();
        const keys = Object.keys(postData);

        keys.forEach((key) => {
          post.append(key, postData[key]);
        });
        post.append("_method", "PUT");

        const requestOptions = {
          method: "POST",
          headers: {
            Authorization: `Bearer ${sessionStorage.token}`,
          },
          body: post,
        };

        fetch(
          `${Api.url}editElementoPublicaciones`,
          requestOptions
        )
          .then((response) => {
            if (response.status === 200) {
              return response.json();
            }
            throw new Error(response.status);
          })
          .then((data) => {
            Api.loading(false);
            Swal.fire({
              text: data.message,
              allowOutsideClick: false,
            });
            this.setElementos(); // Actualizo tabla de elementos
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              text: "No tiene acceso a ésta información",
              icon: "error",
              showCancelButton: false,
              cancelButtonColor: "green",
              confirmButtonText: "Continuar",
              allowOutsideClick: false,
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.href = "/admin/inicio";
              }
            });
          });
      }
    }
  };

  submitValidacion = (postData) => {
    const validationPublicacion =
      postData.switchValue === false &&
      (postData.imagen === null ||
        postData.contenido === "" ||
        postData.contenido === "<p><br></p>");

    const validationPdf =
      postData.switchValue === true && postData.url_pdf === null;

    if (validationPublicacion) {
      const mensaje =
        postData.imagen === null
          ? "Favor de agregar imagen"
          : "Favor de agregar información";

      const mensajeErrorSubmit = {
        error: true,
        mensaje,
      };
      this.setState({ mensajeErrorSubmit });
      return false;
    }

    if (validationPdf) {
      const mensajeErrorSubmit = {
        error: true,
        mensaje: "Favor de agregar pdf",
      };
      this.setState({ mensajeErrorSubmit });
      return false;
    }

    return true;
  };

  handleStatusElemento = (id) => {
    Swal.fire({
      icon: "question",
      title: "¿Está seguro de realizar la acción?",
      showDenyButton: true,
      denyButtonColor: "red",
      confirmButtonColor: "#4CD964",
      showCancelButton: false,
      confirmButtonText: `Continuar`,
      denyButtonText: `Regresar`,
      reverseButtons: true,
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        const requestOptions = {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.token}`,
          },
          body: JSON.stringify({ id }),
        };

        fetch(`${Api.url}deactivatePublicacion`, requestOptions)
          .then((response) => {
            if (response.status === 200) {
              return response.json();
            }
            throw new Error(response.status);
          })
          .then((data) => {
            Swal.fire({
              text: data.message,
              allowOutsideClick: false,
            });
            this.setElementos(); // Actualizo tabla de elementos
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              text: "No tiene acceso a ésta información",
              icon: "error",
              showCancelButton: false,
              cancelButtonColor: "green",
              confirmButtonText: "Continuar",
              allowOutsideClick: false,
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.href = "/admin/inicio";
              }
            });
          });
      }
    });
  };

  handleChangeValueE = (e) => {
    const datos = { ...this.state.elementoInfo };

    switch (e.target.id) {
      case "titulo":
        datos.titulo = e.target.value;
        break;
      case "switch":
        datos.switchValue = e.target.checked;
        datos.imagen = null;
        datos.contenido = "";
        datos.url_pdf = null;

        this.setState({
          mensajeErrorSubmit: this.inicialState.mensajeErrorSubmit,
        });
        break;
      case "imagen":
        {
          datos.imagen = null;
          const element = e.target;

          const file = element.files[0];
          if (file !== undefined) {
            const ext = file.type.split("/")[1];

            if (
              ext === "svg+xml" ||
              ext === "png" ||
              ext === "jpg" ||
              ext === "jpeg" ||
              ext === "svg"
            ) {
              this.setState({ mensajeError: this.inicialState.mensajeError }); // cuando img esta null
              datos.imagen = file;

              if (
                this.state.mensajeErrorSubmit.error &&
                datos.switchValue === false &&
                (datos.contenido === "" || datos.contenido === "<p><br></p>")
              ) {
                const mensajeErrorSubmit = {
                  error: true,
                  mensaje: "Favor de agregar información",
                };
                this.setState({ mensajeErrorSubmit });
              } else {
                this.setState({
                  mensajeErrorSubmit: this.inicialState.mensajeErrorSubmit,
                });
              }
            } else {
              const mensajeError = {
                error: true,
                mensaje: "Tipo de archivo no aceptado",
              };
              this.setState({ mensajeError });
            }
          }
        }
        break;

      case "pdf":
        {
          datos.url_pdf = null;
          const element = e.target;

          const file = element.files[0];
          if (file !== undefined) {
            const ext = file.type.split("/")[1];
            if (ext === "pdf") {
             this.setState({
                mensajeError: this.inicialState.mensajeError,
                mensajeErrorSubmit: this.inicialState.mensajeErrorSubmit,
              });
              datos.url_pdf = file;
            } else {
              const mensajeError = {
                error: true,
                mensaje: "Tipo de archivo no aceptado",
              };
              this.setState({ mensajeError });
            }
          }
        }
        break;
      default:
        break;
    }

    this.setStateElementoInfo(datos);
  };

  handleChangeEditor = (event) => {
    const datos = { ...this.state.elementoInfo };
    datos.contenido = event;

    if (this.state.mensajeErrorSubmit.error && datos.imagen === null) {
      const mensajeErrorSubmit = {
        error: true,
        mensaje: "Favor de agregar imagen",
      };
      this.setState({ mensajeErrorSubmit });
    } else if (event === "" || event === "<p><br></p>") {
      const mensajeErrorSubmit = {
        error: true,
        mensaje: "Favor de agregar información",
      };
      this.setState({ mensajeErrorSubmit });
    } else {
      this.setState({
        mensajeErrorSubmit: this.inicialState.mensajeErrorSubmit,
      });
    }

    this.setStateElementoInfo(datos);
  };

  setStateElementoInfo = (datos) => {
    this.setState({ elementoInfo: datos });
  };

  handleGetElementoEdit = (e, id, aux) => {
    e.preventDefault();

    if (aux) {
      this.setState({ editar: true });
    } else {
      this.setState({ ver: true });
    }

    this.setElemento(id);
  };

  setElemento = (id) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.token}`,
      },
    };

    fetch(`${Api.url}getPublicacion?id=${id}`, requestOptions)
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        }
        throw new Error(response.status);
      })
      .then((data) => {
        if (data) {
          this.setState({ elementoInfo: data });
          this.handleOpen();
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: "No tiene acceso a ésta información",
          icon: "error",
          showCancelButton: false,
          cancelButtonColor: "green",
          confirmButtonText: "Continuar",
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = "/admin/inicio";
          }
        });
      });
  };

  render() {
    return (
      <Page title={this.state.rpeInfo.descripcion}>
        <Container>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ width: "100%" }}
          >
            <Typography variant="h4" gutterBottom>
              Publicaciones | {this.state.rpeInfo.descripcion}
            </Typography>
            <Box>
              <Button
                variant="contained"
                startIcon={<Icon icon={plusFill} />}
                onClick={(e) => this.handleOpen(true)}
              >
                Nuevo elemento
              </Button>
              <NuevoElementoPublicacion
                title="elemento publicación"
                isOpen={this.state.modalShow}
                handleChangeValueE={(e) => this.handleChangeValueE(e)}
                handleChangeEditor={(e) => this.handleChangeEditor(e)}
                handleClose={() => this.handleClose()}
                onHandleSubmit={(e) => this.handleAddElemento(e)}
                info={this.state.elementoInfo}
                editar={this.state.editar}
                ver={this.state.ver}
                nuevo={this.state.nuevo}
                mensajeError={this.state.mensajeError}
                mensajeErrorSubmit={this.state.mensajeErrorSubmit}
              />
            </Box>
          </Stack>
          <Card>
            <Scrollbar>
              <TableContainer sx={{ minWidth: "100%" }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      {tableHead.map((headCell, index) => (
                        <TableCell key={index} align="left">
                          {headCell.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.publicaciones.map((c, index) => (
                      <TableRow key={index}>
                        <TableCell align="left">{c.titulo}</TableCell>
                        <TableCell align="left">
                          <Label
                            variant="ghost"
                            color={(parseInt(c.status,10) === 0 && "error") || "success"}
                          >
                            {c.status_descripcion}
                          </Label>
                        </TableCell>
                        <TableCell>
                          <IconButton
                            onClick={(e) =>
                              this.handleGetElementoEdit(e, c.id, false)
                            }
                          >
                            <Iconify icon="eva:eye-outline" />
                          </IconButton>
                          <IconButton
                            onClick={(e) =>
                              this.handleGetElementoEdit(e, c.id, true)
                            }
                          >
                            <Iconify icon="eva:edit-2-outline" />
                          </IconButton>
                          <IconButton
                            onClick={() => this.handleStatusElemento(c.id)}
                          >
                            {parseInt(c.status,10) === 1 ? (
                              <Iconify icon="eva:close-fill" />
                            ) : (
                              <Iconify icon="eva:checkmark-outline" />
                            )}
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                  {this.state.notFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <Paper>
                            <Typography variant="body2" align="center">
                              No se encontraron resultados.
                            </Typography>
                          </Paper>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>
          </Card>
        </Container>
      </Page>
    );
  }
}

export default (props) => <PublicationsAdmin {...props} params={useParams()} />;
