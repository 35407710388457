import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import Stack from '@mui/material/Stack';

const Input = styled('input')({
  display: 'none'
});

UploadButton.propTypes = {
  id: PropTypes.string,
  onHandleChange: PropTypes.func,
  required: PropTypes.bool
};

export default function UploadButton({ id, onHandleChange, ext, isDisabled = false, ...props}) {
  return (
    <label htmlFor={id}>
      <Input accept={ext} id={id} onChange={(e) => onHandleChange(e)} type="file" />
      <Button variant="outlined" endIcon={<FileUploadOutlinedIcon />} component="span"  sx={{ marginTop: 2 }} disabled={isDisabled}>
        Subir archivo
      </Button>
    </label>
  );
}
