import * as Yup from 'yup';
import { useState } from 'react';
import Swal from 'sweetalert2';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-outline';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// material
import { Stack, TextField, IconButton, InputAdornment, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Api } from '../../../app-controller';


// ----------------------------------------------------------------------

const host = window.location.host.split(':')[0];

export default function ChangePasswordForm({ token }) {
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);

  const LoginSchema = Yup.object().shape({
    password: Yup.string()
      .min(6, 'Contraseña mínima de 6 carácteres')
      .max(30, 'Contraseña máxima de 30 carácteres')
      .required('Constraseña es requerida'),
    passwordConfirm: Yup.string()
      .min(6, 'Contraseña mínima de 6 carácteres')
      .max(30, 'Contraseña máxima de 30 carácteres')
      .required('Constraseña es requerida')
      .oneOf([Yup.ref('password'), null], 'Las contraseñas deben coincidir')
  });

  const formik = useFormik({
    initialValues: {
      password: '',
      passwordConfirm: ''
    },
    validationSchema: LoginSchema,
    onSubmit: (values) => {
      const postData = {};

      Object.assign(postData, { password: values.password });
      Object.assign(postData, { passwordConfirm: values.passwordConfirm });
      Object.assign(postData, { token });

      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(postData)
      };

      fetch(`${Api.url}passwordUpdate`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          Swal.fire({
            text: data.msg,
            icon: 'success',
            allowOutsideClick: false
          }).then(() => {
            window.location.href = '/login';
          });
        });
      // }
    }
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const handleShowPasswordConfirm = () => {
    setShowPasswordConfirm((show) => !show);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Typography variant="h8" gutterBottom>
          Favor de ingresar la nueva contraseña
        </Typography>
        <Stack spacing={3} alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Contraseña"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />

          <TextField
            fullWidth
            autoComplete="current-passwordConfirm"
            type={showPasswordConfirm ? 'text' : 'password'}
            label="Confirmar contraseña"
            {...getFieldProps('passwordConfirm')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPasswordConfirm} edge="end">
                    <Icon icon={showPasswordConfirm ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.passwordConfirm && errors.passwordConfirm)}
            helperText={touched.passwordConfirm && errors.passwordConfirm}
          />
        </Stack>

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          Recuperar contraseña
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}
