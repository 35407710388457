import React, { useState } from 'react';
// material
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  TextField,
  Stack,
  Typography,
  Paper,
  IconButton
} from '@mui/material';
import Switch from '@mui/material/Switch';
import ImageIcon from '@mui/icons-material/Image';
import Iconify from './Iconify';
import UploadButton from './loadImages';
import EditorQuill from './EditorQuill';
import { Api } from '../app-controller';

const host = window.location.host.split(':')[0];
const label = { inputProps: { 'aria-label': 'Switch demo' } };

const imgStyle = {
  border: 'solid 0px red',
  float: 'center',
  padding: '0 10px 10px',
  height: '180px',
  width: '200px'
};

class NuevoElementoCarrusel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
    this.windowWidth = window.innerWidth;
  }

  render() {
    return (
      <Dialog open={this.props.isOpen} onClose={(e) => this.props.handleClose(e)} fullWidth>
        <DialogTitle>
          {this.props.view ? 'Ver' : ''} {this.props.editar ? 'Editar' : ''}{' '}
          {this.props.nuevo ? 'Nuevo' : ''} {this.props.title}
          <IconButton
            aria-label="close"
            onClick={(e) => this.props.handleClose(e)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}
          >
            <Iconify icon="eva:close-outline" />
          </IconButton>
        </DialogTitle>
        <form onSubmit={(e) => this.props.handleAddElemento(e)}>
          <DialogContent>
            <Typography>
              Título <span style={{ color: 'red' }}>*</span>
            </Typography>
            <TextField
              id="titulo"
              placeholder="Título"
              maxRows={5}
              // error //como lo puedo obtener?
              multiline
              fullWidth
              required
              value={this.props.info.titulo}
              disabled={this.props.view}
              onChange={(e) => {
                this.props.handleChangeValueE(e);
              }}
              inputProps={{ maxLength: '50' }}
            />

            <Typography>
              Resumen <span style={{ color: 'red' }}>*</span>
            </Typography>
            <TextField
              id="resumen"
              placeholder="Resumen ..."
              maxRows={10}
              // error //como lo puedo obtener?
              multiline
              fullWidth
              required
              value={this.props.info.resumen}
              disabled={this.props.view}
              onChange={(e) => {
                this.props.handleChangeValueE(e);
              }}
              inputProps={{ maxLength: '255' }}
            />
            <br />
            <br />
            <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-start">
              {this.props.info.imagen !== null && !this.props.view ? (
                <>
                  <ImageIcon />
                  <Typography sx={{ marginTop: 2 }}>
                    {typeof this.props.info.imagen === 'string'
                      ? this.props.info.imagen
                      : this.props.info.imagen.name}
                  </Typography>
                </>
              ) : (
                <>
                  {this.props.mensajeError.error ? (
                    <Typography>
                      <span style={{ color: 'red' }}>{this.props.mensajeError.mensaje}</span>
                    </Typography>
                  ) : null}
                </>
              )}
            </Stack>
            <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-start">
              <Typography>
                Imagen <span style={{ color: 'red' }}>*</span>
              </Typography>
              <UploadButton
                id="imagen"
                isDisabled={this.props.view}
                onHandleChange={(e) => this.props.handleChangeValueE(e)}
                ext="image/*"
                required
              />
            </Stack>

            <br />
            <Paper>
              {this.props.info.imagen !== null && this.props.view ? (
                <img
                  style={imgStyle}
                  src={`${Api.url}getImagenCarrusel?id=${this.props.info.id}`}
                  alt="Nombre de la imagen"
                />
              ) : null}
            </Paper>

            <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-end">
              <Typography>Información</Typography>
              <Switch
                id="switch"
                value={this.props.info.switchValue}
                checked={this.props.info.switchValue}
                onChange={(e) => this.props.handleChangeValueE(e)}
                disabled={this.props.view}
                {...label}
              />
              <Typography>Liga</Typography>
            </Stack>

            {this.props.info.switchValue ? (
              <>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography>
                    Liga<span style={{ color: 'red' }}>*</span>
                  </Typography>
                  <TextField
                    id="link"
                    placeholder="Liga ..."
                    maxRows={5}
                    // error //como lo puedo obtener?
                    multiline
                    fullWidth
                    required
                    value={this.props.info.link}
                    disabled={this.props.view}
                    onChange={(e) => this.props.handleChangeValueE(e)}
                    inputProps={{ maxLength: '255' }}
                  />
                </Stack>
              </>
            ) : (
              <>
                <Typography>
                  Información <span style={{ color: 'red' }}>*</span>
                </Typography>
                <EditorQuill
                  onHandleContent={(e) => this.props.handleChangeEditor(e)}
                  only={this.props.view}
                  valueDefaul={this.props.info.contenido}
                />
              </>
            )}

            {this.props.mensajeErrorSubmit.error ? (
              <Typography>
                <span style={{ color: 'red' }}>{this.props.mensajeErrorSubmit.mensaje}</span>
              </Typography>
            ) : null}
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="error" onClick={(e) => this.props.handleClose(e)}>
              {this.props.editar ? 'Cancelar' : 'Regresar'}
            </Button>

            {this.props.view ? (
              <></>
            ) : (
              <Button variant="contained" type="submit">
                Guardar
              </Button>
            )}
          </DialogActions>
        </form>
      </Dialog>
    );
  }
}

export default NuevoElementoCarrusel;
