import React, { useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import Swal from 'sweetalert2';
//
import DashboardNavbar from './DashboardNavbar';
import DashboardAdmin from './DashboardAdmin';
import DashboardSidebar from './DashboardSidebar';
import Footer from './Footer';
import { Api } from '../../app-controller';
import { MHidden } from '../../components/@material-extend';

// ----------------------------------------------------------------------
const host = window.location.host.split(":")[0];

const APP_BAR_MOBILE = 140;
const APP_BAR_DESKTOP = 180;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100vh',
  overflow: 'hidden'
});

const RootStyleFullScreen = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
  overflow: 'hidden'
});

const Style = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10)
  }
}));

const APP_BAR_MOBILE_L = 64;
const APP_BAR_DESKTOP_L = 92;

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE_L + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP_L + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));

// ----------------------------------------------------------------------

const location = window.location.pathname;

export default class index extends React.Component {
  constructor() {
    super();
    this.state = {
      links: [],
      nivel: null,
      open: false,
      menu: [
        {
          id: 1,
          title: 'Inicio',
          path: '/pagina#inicio',
          elementos: [],
          subMenu: []
        }
      ]
    };
    this.handleOpen = this.handleOpen.bind(this);
  }

  componentDidMount() {
    fetch(`${Api.url}getLinks`)
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          this.setState({ links: data.links });
        }
      });

    fetch(`${Api.url}getTitle`)
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          localStorage.setItem('titulo', data.titulo);
        }
      });

    this.MenuConfig();
    this.menuAdmin();

    if (!sessionStorage.nivel && location.includes('admin') ) {
      Swal.fire({
        title: 'Error',
        text: 'Tu sesión ha expirado, por favor accede de nuevo.',
        icon: 'error',
        showCancelButton: false,
        cancelButtonColor: 'green',
        confirmButtonText: 'Continuar'
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.href = '/login';
        }
      });
    }
  }

  handleOpen(e) {
    console.log(e);
    this.setState({ open: e });
  }

  menuAdmin() {
    if (location.includes('admin')) {
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${sessionStorage.token}`
        }
      };

      fetch(`${Api.url}auth/getNivelActual`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          if (data) {
            this.setState({ nivel: data });
          }
        });
    }
  }

  MenuConfig() {
    fetch(`${Api.url}getMenuPublic`)
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          this.setState({ menu: data });
        }
      });
  }

  render() {
    return (
      <>
        {location.includes('admin') ? (
          <RootStyle>
            <DashboardSidebar
              isOpenSidebar={this.state.open}
              onCloseSidebar={() => this.handleOpen(false)}
              onOpenSidebar={() => this.handleOpen(true)}
              nivel={this.state.nivel}
            />
            <MHidden width="lgDown">
              <DashboardAdmin />
            </MHidden>

            <MainStyle>
              <Outlet />
            </MainStyle>
          </RootStyle>
        ) : (
          <RootStyleFullScreen>
            {location === '/login' || location === '/recuperar-contrasena' || location.includes('cambiar-contrasena/') ? (
              <>
                <MainStyle>
                  <Outlet />
                </MainStyle>
              </>
            ) : (
              <>
                <Style>
                  <Outlet />
                </Style>
                <DashboardNavbar
                  links={this.state.links} /* onOpenSidebar={ this.setState({open: true})} */
                  menu={this.state.menu}
                />
                <Footer />
              </>
            )}
          </RootStyleFullScreen>
        )}
      </>
    );
  }
}
