import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// material
import {
  Card,
  Table,
  IconButton,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  Checkbox
} from '@mui/material';
import Scrollbar from '../Scrollbar';
import Iconify from '../Iconify';

TablaListaAccesosCarpeta.propTypes = {
  arrayColumn: PropTypes.array,
  arrayRows: PropTypes.array,
  onHandleDelete: PropTypes.func,
  onHandleAcceso: PropTypes.func
};

export default function TablaListaAccesosCarpeta({
  arrayColumn,
  arrayRows,
  onHandleAcceso,
  onHandleDelete
}) {
  const [columnas, setColumnas] = useState(arrayColumn);
  useEffect(() => {
    setColumnas(arrayColumn);
  }, [arrayColumn]);

  const [rows, setRows] = useState(arrayRows);
  useEffect(() => {
    setRows(arrayRows);
  }, [arrayRows]);

  return (
    <Card>
      <Scrollbar>
        <TableContainer sx={{ minWidth: '100%' }}>
          <Table>
            <TableBody>
              {rows.map((row, index) => (
                <TableRow key={index}>
                  <TableCell align="left">
                    <Checkbox
                      checked={parseInt(row.acceso, 10)}
                      inputProps={{ 'aria-label': 'controlled' }}
                      onChange={(e) => onHandleAcceso(e, row.id)}
                    />
                  </TableCell>
                  <TableCell align="left">{row.nombre}</TableCell>
                  <TableCell align="center">
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={(e) => onHandleDelete(e, row.id)}
                    >
                      <Iconify icon="eva:close-fill" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>
    </Card>
  );
}
