import * as React from 'react';
import { Icon } from '@iconify/react';
import expand from '@iconify/icons-eva/expand-outline';
import collapse from '@iconify/icons-eva/collapse-fill';
// material
import {
  Dialog,
  DialogContent,
  IconButton,
  DialogTitle,
  DialogActions,
  Button,
  Typography
} from '@mui/material';
import Iconify from './Iconify';
import EditorQuill from './EditorQuill';

class NuevoElementoEditor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fullScreen : false
    };
  }

  handlefullScreen = () => {
    if (this.state.fullScreen === false) {
      this.setState({fullScreen: true});
    } else {
      this.setState({fullScreen: false});
    }
  };


  render() {
    return (
      <Dialog open={this.props.isOpen} onClose={(e) => this.props.handleClose(e) }fullScreen={this.state.fullScreen} fullWidth>
        <DialogTitle>
          {this.props.ver ? 'Ver' : ''} {this.props.editar ? 'Editar' : ''}{' '}
          {this.props.nuevo ? 'Nuevo' : ''} {this.props.title}
          <IconButton
            aria-label="close"
            onClick={(e) => this.props.handleClose(e)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}
          >
            <Iconify icon="eva:close-outline" />
          </IconButton>
        </DialogTitle>
        <form onSubmit={(e) => this.props.onHandleSubmit(e)}>
          <DialogContent>
            {this.props.ver ? (
              <div dangerouslySetInnerHTML={{ __html: this.props.contenido }} />
            ) : (
              <>
                <Typography>
                  Información <span style={{ color: 'red' }}>*</span>
                </Typography>
                <EditorQuill
                  onHandleContent={(e) => this.props.handleChangeEditor(e)}
                  valueDefaul={this.props.contenido}
                />
              </>
            )}

            {this.props.mensajeErrorSubmit.error ? (
              <Typography>
                <span style={{ color: 'red' }}>{this.props.mensajeErrorSubmit.mensaje}</span>
              </Typography>
            ) : null}
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="error" onClick={(e) => this.props.handleClose(e)}>
              {this.props.editar ? 'Cancelar' : 'Regresar'}
            </Button>

            {this.props.ver ? (
              <></>
            ) : (
              <Button variant="contained" type="submit">
                Guardar
              </Button>
            )}
            <IconButton onClick={this.handlefullScreen}>
              {this.state.fullScreen === false ? (
                <Icon icon={expand} width={20} height={20} color="blue" />
              ) : (
                <Icon icon={collapse} width={20} height={20} color="blue" />
              )}
            </IconButton>
          </DialogActions>
        </form>
      </Dialog>
    );
  }
}

export default NuevoElementoEditor;
