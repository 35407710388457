import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import { red, pink, purple, deepPurple, indigo, blue, lightBlue, cyan, teal, green, lightGreen, lime, yellow, amber, orange, deepOrange, brown, grey } from '@mui/material/colors';
// ----------------------------------------------------------------------

PaletteBd.propTypes = {
  children: PropTypes.node
};

export default function PaletteBd(color) {
  function createGradient(color1, color2) {
    return `linear-gradient(to bottom, ${color1}, ${color2})`;
  }

  let lightBD = '#5BE584';
  let darkBD = '#007B55';

  switch(color){
    case '#f44336':
      lightBD = red.A200;
      darkBD = red.A700;
      break;
    case '#e91e63':
      lightBD = pink.A200;
      darkBD = pink.A700;
      break;
    case '#9c27b0':
      lightBD = purple.A200;
      darkBD = purple.A700;
      break;
    case '#673ab7':
      lightBD = deepPurple.A200;
      darkBD = deepPurple.A700;
      break;
    case '#3f51b5':
      lightBD = indigo.A200;
      darkBD = indigo.A700;
      break;
    case '#2196f3':
      lightBD = blue.A200;
      darkBD = blue.A700;
      break;
    case '#03a9f4':
      lightBD = lightBlue.A200;
      darkBD = lightBlue.A700;
      break;
    case '#00bcd4':
      lightBD = cyan.A200;
      darkBD = cyan.A700;
      break;
    case '#009688':
      lightBD = teal.A200;
      darkBD = teal.A700;
      break;
    case '#4caf50':
      lightBD = green.A200;
      darkBD = green.A700;
      break;
    case '#8bc34a':
      lightBD = lightGreen.A200;
      darkBD = lightGreen.A700;
      break;
    case '#cddc39':
      lightBD = lime.A200;
      darkBD = lime.A700;
      break;
    case '#ffeb3b':
      lightBD = yellow.A200;
      darkBD = yellow.A700;
      break;
    case '#ffc107':
      lightBD = amber.A200;
      darkBD = amber.A700;
      break;
    case '#ff9800':
      lightBD = orange.A200;
      darkBD = orange.A700;
      break;
    case '#ff5722':
      lightBD = deepOrange.A200;
      darkBD = deepOrange.A700;
      break;
    case '#795548':
      lightBD = brown.A200;
      darkBD = brown.A700;
      break;
    case '#9e9e9e':
      lightBD = grey.A200;
      darkBD = grey.A700;
      break;
    default:
      break;
  }

  // SETUP COLORS
  const GREY = {
    0: '#FFFFFF',
    100: '#F9FAFB',
    200: '#F4F6F8',
    300: '#DFE3E8',
    400: '#C4CDD5',
    500: '#919EAB',
    600: '#637381',
    700: '#454F5B',
    800: '#212B36',
    900: '#161C24',
    500_8: alpha('#919EAB', 0.08),
    500_12: alpha('#919EAB', 0.12),
    500_16: alpha('#919EAB', 0.16),
    500_24: alpha('#919EAB', 0.24),
    500_32: alpha('#919EAB', 0.32),
    500_48: alpha('#919EAB', 0.48),
    500_56: alpha('#919EAB', 0.56),
    500_80: alpha('#919EAB', 0.8)
  };

  const PRIMARY = {
    lighter: '#C8FACD',
    light: lightBD,
    main: color,
    dark: darkBD,
    darker: '#005249',
    contrastText: '#fff'
  };
  const SECONDARY = {
    lighter: '#D6E4FF',
    light: '#84A9FF',
    main: '#3366FF',
    dark: '#1939B7',
    darker: '#091A7A',
    contrastText: '#fff'
  };
  const INFO = {
    lighter: '#D0F2FF',
    light: '#74CAFF',
    main: '#1890FF',
    dark: '#0C53B7',
    darker: '#04297A',
    contrastText: '#fff'
  };
  const SUCCESS = {
    lighter: '#E9FCD4',
    light: '#AAF27F',
    main: '#54D62C',
    dark: '#229A16',
    darker: '#08660D',
    contrastText: GREY[800]
  };
  const WARNING = {
    lighter: '#FFF7CD',
    light: '#FFE16A',
    main: '#FFC107',
    dark: '#B78103',
    darker: '#7A4F01',
    contrastText: GREY[800]
  };
  const ERROR = {
    lighter: '#FFE7D9',
    light: '#FFA48D',
    main: '#FF4842',
    dark: '#B72136',
    darker: '#7A0C2E',
    contrastText: '#fff'
  };

  const GRADIENTS = {
    primary: createGradient(PRIMARY.light, PRIMARY.main),
    info: createGradient(INFO.light, INFO.main),
    success: createGradient(SUCCESS.light, SUCCESS.main),
    warning: createGradient(WARNING.light, WARNING.main),
    error: createGradient(ERROR.light, ERROR.main)
  };

  const CHART_COLORS = {
    violet: ['#826AF9', '#9E86FF', '#D0AEFF', '#F7D2FF'],
    blue: ['#2D99FF', '#83CFFF', '#A5F3FF', '#CCFAFF'],
    green: ['#2CD9C5', '#60F1C8', '#A4F7CC', '#C0F2DC'],
    yellow: ['#FFE700', '#FFEF5A', '#FFF7AE', '#FFF3D6'],
    red: ['#FF6C40', '#FF8F6D', '#FFBD98', '#FFF2D4']
  };

  const palette = {
    common: { black: '#000', white: '#fff' },
    primary: { ...PRIMARY },
    secondary: { ...SECONDARY },
    info: { ...INFO },
    success: { ...SUCCESS },
    warning: { ...WARNING },
    error: { ...ERROR },
    grey: GREY,
    gradients: GRADIENTS,
    chart: CHART_COLORS,
    divider: GREY[500_24],
    text: { primary: GREY[800], secondary: GREY[600], disabled: GREY[500] },
    background: { paper: '#fff', default: '#fff', neutral: GREY[200] },
    action: {
      active: GREY[600],
      hover: GREY[500_8],
      selected: GREY[500_16],
      disabled: GREY[500_80],
      disabledBackground: GREY[500_24],
      focus: GREY[500_24],
      hoverOpacity: 0.08,
      disabledOpacity: 0.48
    }
  };

  return palette;
}
