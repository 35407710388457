import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import React from 'react';
import { Icon } from '@iconify/react';
import Phone from '@iconify/icons-eva/phone-outline';
import Email from '@iconify/icons-eva/email-outline';
import Swal from 'sweetalert2';
// components
import ContactForm from './ContactForm';
import Maps from './Map';

const boxStyle = {
  overflow: 'hidden',
  paddingRight: '10%',
  paddingLeft: '10%'
};

const stylediv = {
  border: 'solid 0px blue',
  margin: '10px',
  textAlign: 'justify'
};

Contacto.propTypes = {
  info: PropTypes.object
};

export default function Contacto({ info }) {
  info.center = /* { lat: Number(info.latitud), lng: Number(info.longitud)} */ {
    lat: 26.011972,
    lng: -98.190472
  };
  info.zoom = 15;

  return (
    <Box style={boxStyle}>
      <Typography
        variant="h1"
        component="div"
        fontFamily="monospace"
        fontWeight="light"
        textAlign="center"
        gutterBottom
      >
        Contáctanos
      </Typography>
      <hr />
      <Box sx={{ flexGrow: 0, paddingTop: '4%' }}>
        <Grid container spacing={7}>
          <Grid item md={6}>
            <Typography
              variant="h3"
              fontWeight="regular"
              textAlign="center"
              component="div"
              gutterBottom
            >
              Formulario de Contacto
            </Typography>
            <hr />
            <br />
            <ContactForm />
          </Grid>
          <Grid item md={6}>
            <Typography
              variant="h3"
              fontWeight="regular"
              textAlign="center"
              component="div"
              gutterBottom
            >
              Localización
            </Typography>
            <hr />
            <br />
            <Box align="center">
              <Box align="center" sx={{ height: '25vh'}}>
                <Maps center={info.center} zoom={info.zoom} />
              </Box>
              <div style={stylediv}>
                <Typography
                  gutterBottom
                  variant="h3"
                  fontWeight="regular"
                  textAlign="center"
                  component="div"
                >
                  Dirección
                </Typography>
                <hr />
                <br />
                <Typography gutterBottom variant="h6" component="div">
                  {info.titulo}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {info.direccion}
                </Typography>
              </div>
              <div style={stylediv}>
                <p>
                  <Icon icon={Phone} /> {info.telefono_principal}
                </p>
                {info.telefono_secundario ? (
                  <p>
                    <Icon icon={Phone} /> {info.telefono_secundario}
                  </p>
                ) : null}
              </div>
              <div style={stylediv}>
                <p>
                  <Icon icon={Email} /> {info.email}
                </p>
              </div>
              <div style={stylediv}>
                <Typography variant="body2" color="text.secondary">
                  {info.otros}
                </Typography>
              </div>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
