import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/system';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from './Modal';
import Pdf from './Pdf';
import { Api } from '../app-controller';

const host = window.location.host.split(":")[0];

const resumeStyle = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  // height: '150px',
  textAlign: 'justify',
  border: '0px solid green',
};

const buttonStyle = {
  padding: '0px 24px 12px',
  position: 'absolute',
  bottom: '10px',
  border: '0px solid #8AC007',
};

const hiddenStyle = {
  display: 'none'
};

const itemStyle = {
  border: '0px solid green',
  padding: '10px',
  margin: '0px',
  overflow: 'hidden',
  height: '145px'
};

CardInformation.propTypes = {
  contents: PropTypes.object
};

export default function CardInformation({ contents }) {
  const [modalShow, setModalShow] = useState(false);

  const handleOpen = () => {
    setModalShow(true);
  };

  const handleClose = () => {
    setModalShow(false);
  };

  return (
    <Card sx={{ width: '270px',  height: '493.5px' }}>
      {contents.imagen !== null ? (
        <CardMedia component="img" height="140" image={`${Api.url}getArchivoTI?id=${contents.id}`} alt={contents.titulo} />
      ) : null}
      <Box sx={contents.url_pdf !== null? itemStyle: hiddenStyle}>
        <Pdf urlPdf={`${Api.url}getArchivoTI?id=${contents.id}`} modal={200} imagen />
      </Box>

      <CardContent>
        <Typography gutterBottom variant="h6" component="div">
          {contents.titulo}
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={resumeStyle}>
          {contents.resumen}
        </Typography>
      </CardContent>
      <CardActions sx={buttonStyle}>
        <Button size="small" color="primary" onClick={handleOpen}>
          Leer más
        </Button>
      </CardActions>

      <Modal
        title={contents.titulo}
        information={
          contents.url_pdf === null ? (
            contents.contenido
          ) : (
            <Pdf urlPdf={`${Api.url}getArchivoTI?id=${contents.id}`} />
          )
        }
        isOpen={modalShow}
        handleClose={handleClose}
      />

    </Card>
  );
}
