import * as React from 'react';
import PropTypes from 'prop-types';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';

OppositeContentTimeline.propTypes = {
  infoTimeline: PropTypes.array,
};

export default function OppositeContentTimeline({infoTimeline}) {

  const timelineCount = infoTimeline.length;

  return (
    <div>
      {infoTimeline.map ((info, index) => 
      <>
      <Timeline key={info} position="alternate">
        <TimelineItem key={index}>
          <TimelineOppositeContent color="primary">
            {info.fecha}
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot variant="outlined" color="primary" />
            {
              timelineCount !== (index + 1) ?
                <TimelineConnector />
              : null
            }
          </TimelineSeparator>
          <TimelineContent>{info.texto}</TimelineContent>
        </TimelineItem>
      </Timeline>
      </>
    )}
    </div>
  );
}