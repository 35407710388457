import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
// material
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object
};

export default function Logo({ sx }) {
  return (
    <Box
      component="img"
      src={JSON.parse(localStorage.getItem("logo"))}
      sx={{ height: 80, ...sx }}
    />
  );
}
