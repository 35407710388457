import * as React from 'react';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { DatePicker, LocalizationProvider } from '@mui/lab';
// material
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  IconButton,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
  OutlinedInput,
  Select,
  useTheme,
  Typography,
  List,
  ListItem,
  Stack
} from '@mui/material';
import ArchiveIcon from '@mui/icons-material/Archive';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { useState, useEffect } from 'react';
import UploadButton from './loadImages';
import Iconify from './Iconify';
import TablaLista from './auxiliaresComponentes/TablaListaLinks';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

export default function ModalEdit({
  title,
  elementos,
  isOpen,
  handleClose,
  onHandleSubmit,
  modalView,
  lista
}) {
  const cult = '';
  const theme = useTheme();
  const [personName, setPersonName] = useState([]);

  const [val1, setValue1] = useState('');
  const [val2, setValue2] = useState('');
  const [elim, setElim] = useState('');
  const [elementosEdith, setElementosEdith] = useState([]);

  const addToList = () => {
    lista.push({
      descripcion: document.getElementById('descripcion_array').value,
      link: document.getElementById('link_array').value
    });

    setValue1('');
    setValue2('');
  };

  const deleteItem = (event,index) => {
    // lista.splice(index, 1); // lista.filter((item, i) => i !== index);
    event.preventDefault();
    lista.splice(index, 1);
    setElim(index);
  };

  useEffect(() => {
    console.log(lista);
  }, [elim]);

  const handleChange = (event) => {
    const {
      target: { value }
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  const handleGetData = (event) => {
    event.preventDefault();

    const data = [];
    elementosEdith.forEach((item) => {
      switch (item.input) {
        case 'pdf':
          data.push({ nombre: item.id.slice(0, -2), value: pdf });
          break;
        case 'list':
          data.push({ nombre: item.id.slice(0, -2), value: lista });
          break;
        case 'archivo':
          data.push({ nombre: item.id.slice(0, -2), value: pdf });
          break;
        default:
          data.push({
            nombre: item.id.slice(0, -2),
            value: item.value
          });
          break;
      }
    });

    // console.log(data);
    onHandleSubmit(data);
    setPdf(null);
  };

  const fullWidth = true;
  const [date, setDate] = useState(new Date());
  const [pdf, setPdf] = useState(null); // Por ahora solo se puede agregar un subir archivo por modal

  const handleChangeSelect = (event, value, index) => {
    const aux = [...elementosEdith];
    aux[index].value = value;
    setElementosEdith(aux);
  };

  const handleChangeFile = (event) => {
    const element = event.target;
    const file = element.files[0];
    const ext = file.type.split('/')[1];
    
    if (file !== undefined) {
      file.ext = ext;
      file.archivo = element.id.slice(0, -2);
      setPdf(file);
    }
  };

  useEffect(() => {
    setElementosEdith(elementos);
  }, [elementos]);

  const handleChangeValue = (newValue, index) => {
    const aux = [...elementosEdith];
    if (aux[index].input === 'number') {
      const regex = new RegExp(/^[0-9]*$/);
      if (regex.test(newValue)) {
        aux[index].value = newValue;
      }
    } else {
      aux[index].value = newValue;
    }

    setElementosEdith(aux);
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} fullWidth={fullWidth}>
      <DialogTitle>
        {title}
        {handleClose ? (
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}
          >
            <Iconify icon="eva:close-outline" />
          </IconButton>
        ) : null}
      </DialogTitle>
      <form onSubmit={(e) => handleGetData(e)}>
        {elementosEdith.map((item, indexElement) => (
          <fragment>
            <DialogContent>
              <Typography>
                {' '}
                {item.label} {item.required ? <span style={{ color: 'red' }}>*</span> : null}
              </Typography>
              <FormControl fullWidth>
                {item.input === 'select' ? (
                  <>
                    <InputLabel htmlFor={item.id}>Seleccione</InputLabel>
                    <Select
                      id={item.id}
                      placeholder={item.id}
                      required={item.required}
                      defaultValue={item.value}
                      inputProps={{ readOnly: modalView }}
                    >
                      {item.select.map((option, index) => (
                        <MenuItem
                          key={option.id}
                          value={option.id}
                          onClick={(e) => handleChangeSelect(e, option.id, indexElement)}
                        >
                          {option.descripcion}
                        </MenuItem>
                      ))}
                    </Select>
                  </>
                ) : null}
                {item.input === 'selectMultiple' ? (
                  <Box
                    noValidate
                    component="form"
                    sx={{
                      display: 'flex',
                      flexDirection: 'column'
                    }}
                  >
                    <InputLabel htmlFor={item.id}>Seleccione</InputLabel>
                    <Select
                      placeholder={item.id}
                      multiple
                      value={personName}
                      onChange={handleChange}
                      input={<OutlinedInput label={item.id} />}
                      MenuProps={MenuProps}
                      required={item.required}
                      inputProps={{ readOnly: modalView }}
                    >
                      {item.select.map((option, index) => (
                        <MenuItem value={index}>{option}</MenuItem>
                      ))}
                    </Select>
                  </Box>
                ) : null}
                {item.input === 'number' ? (
                  <TextField
                    id={item.id}
                    placeholder={item.label}
                    type="text"
                    fullWidth
                    required={item.required}
                    value={item.value}
                    onChange={(e) => handleChangeValue(e.target.value, indexElement)}
                    inputProps={{ maxLength: item.maxLength, readOnly: modalView }}
                  />
                ) : null}
                {item.input === 'text' && item.upperCase ? ( // MAYÚSCULAS
                  <TextField
                    id={item.id}
                    placeholder={item.label}
                    maxRows={10}
                    limit={item.limit}
                    multiline
                    fullWidth
                    required={item.required}
                    onChange={(e) => handleChangeValue(e.target.value.toUpperCase(), indexElement)}
                    inputProps={{ maxlength: item.maxLength, readOnly: modalView }}
                    value={item.value}
                  />
                ) : null}
                {item.input === 'text' && !item.upperCase ? ( // MINÚSCULAS
                  <TextField
                    id={item.id}
                    placeholder={item.label}
                    maxRows={10}
                    limit={item.limit}
                    multiline
                    fullWidth
                    required={item.required}
                    inputProps={{ maxlength: item.maxLength, readOnly: modalView }}
                    onChange={(e) => handleChangeValue(e.target.value, indexElement)}
                    defaultValue={item.value}
                  />
                ) : null}
                {item.input === 'email' ? (
                  <TextField
                    id={item.id}
                    placeholder={item.label}
                    fullWidth
                    key={item.label}
                    required={item.required}
                    inputProps={{
                      maxlength: item.maxLength,
                      readOnly: modalView,
                      pattern: '[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+[.]{1}[a-zA-Z]{2,}$'
                    }}
                    onChange={(e) => handleChangeValue(e.target.value, indexElement)}
                    defaultValue={item.value}
                  />
                ) : null}
                {item.input === 'date' ? (
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      onChange={(newValue) => handleChangeValue(newValue, indexElement)}
                      value={item.value}
                      renderInput={(params) => <TextField {...params} />}
                      required={item.required}
                      inputProps={{ readOnly: modalView }}
                    />
                  </LocalizationProvider>
                ) : null}
                {item.input === 'pdf' ? (
                  <>
                  <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-start">
                    {pdf !== null ? (
                      <>
                        <PictureAsPdfIcon />
                        <Typography sx={{ marginTop: 2 }}>
                          {pdf.name}
                        </Typography>
                      </>
                    ) : (
                      <Typography sx={{ marginTop: 2 }}>
                        <span style={{ color: 'red' }}>Favor de seleccionar un archivo pdf</span>
                      </Typography>
                    )}
                    </Stack>
                    <UploadButton
                      id={item.id}
                      onHandleChange={handleChangeFile}
                      ext="application/pdf"
                      required={item.required}
                    />
                  </>
                ) : null}
                {item.input === 'archivo' ? (
                  <>
                    <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-start">
                      <ArchiveIcon />
                      <Typography sx={{ marginTop: 2 }}>
                        {pdf !== null ? pdf.name : item.value}
                      </Typography>
                    </Stack>
                    <UploadButton
                      id={item.id}
                      onHandleChange={handleChangeFile}
                      ext="multipart/form-data"
                      required={item.required}
                    />
                  </>
                ) : null}
                {item.input === 'list' && !modalView ? (
                  <>
                  <Stack
                      direction="row"
                      spacing={3}
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <TextField
                        id="descripcion_array"
                        placeholder="Descripción"
                        onChange={(e) => setValue1(e.target.value)}
                        value={val1}
                        inputProps={{ maxLength: 50 }}
                      />
                      <TextField
                        id="link_array"
                        placeholder="Ligas"
                        onChange={(e) => setValue2(e.target.value)}
                        value={val2}
                        inputProps={{ maxLength: 100 }}
                      />
                      <IconButton onClick={addToList} disabled={val1 === '' || val2 === ''}>
                        <Iconify icon="eva:plus-outline" />
                      </IconButton>
                    </Stack>
                    <br />
                    {lista.length > 0 ? (
                      <TablaLista
                        arrayColumn={[
                          { id: 'descripcion', label: ' ', alignRight: false },
                          { id: 'link', label: ' ', alignRight: false },
                          { id: ' ', label: ' ', alignRight: false }
                        ]}
                        arrayRows={lista}
                        onHandleDelete={deleteItem}
                      />
                    ) : null}
                  </>
                ) : null}
                {item.input === 'list' && modalView ? (
                  <>
                  {item.value.length > 0 ? (
                      <TablaLista
                        arrayColumn={[
                          { id: 'descripcion', label: ' ', alignRight: false },
                          { id: 'link', label: ' ', alignRight: false },
                          { id: ' ', label: ' ', alignRight: false }
                        ]}
                        arrayRows={item.value}
                        onHandleDelete={deleteItem}
                        view
                      />
                    ) : null}
                  </>
                ) : null}
              </FormControl>
            </DialogContent>
          </fragment>
        ))}
        {!modalView ? (
          <DialogActions>
            <Button variant="contained" type="submit">
              Guardar
            </Button>
          </DialogActions>
        ) : null}
      </form>
    </Dialog>
  );
}