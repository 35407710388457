import React, { useState } from "react";
// material
import { Box, Grid, Button, Container, Stack, Typography } from "@mui/material";
import Swal from "sweetalert2";
import TextField from "@mui/material/TextField";
import { CirclePicker } from "react-color";
import ImageIcon from "@mui/icons-material/Image";
// components
import Page from "../components/Page";
import UploadButton from "../components/loadImages";
import { Api } from "../app-controller";
// ----------------------------------------------------------------------
const host = window.location.host.split(":")[0];

export default class GeneralInfo extends React.Component {
  constructor() {
    super();
    this.state = {
      titulo: "",
      logo: null,
      logo_nombre: "",
      logo_pestana: null,
      logo_pestana_nombre: "",
      color: "",
    };
  }

  componentDidMount() {
    this.handleGetInfo();
  }

  handleGetInfo = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.token}`,
      },
    };

    fetch(`${Api.url}getGeneralInfoAdmin`, requestOptions)
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        }
        throw new Error(response.status);
      })
      .then((data) => {
        if (data) {
          this.setState({ titulo: data.info.titulo });
          this.setState({ logo_nombre: data.info.logo });
          this.setState({ logo_pestana_nombre: data.info.logo_pestana });
          this.setState({ color: data.info.color });
          localStorage.setItem("color", JSON.stringify(data.info.color));
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: "No tiene acceso a ésta información",
          icon: "error",
          showCancelButton: false,
          cancelButtonColor: "green",
          confirmButtonText: "Continuar",
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = "/admin/inicio";
          }
        });
      });
  };

  handleChange = (event) => {
    const element = event.target;

    switch (element.id) {
      case "titulo":
        this.setState({ titulo: element.value });
        break;
      case "Logo": {
        const file = element.files[0];
        const ext = file.type.split("/")[1];
        if (
          ext === "svg+xml" ||
          ext === "png" ||
          ext === "jpg" ||
          ext === "jpeg" ||
          ext === "svg"
        ) {
          this.setState({ logo: file });
          this.setState({ logo_nombre: file.name });
        } else {
          Swal.fire({
            icon: "error",
            text: "Tipo de archivo no aceptado",
            allowOutsideClick: false,
          });
        }

        break;
      }
      case "LogoPestana": {
        const file2 = element.files[0];
        const ext2 = file2.type.split("/")[1];

        if (
          ext2 === "svg+xml" ||
          ext2 === "png" ||
          ext2 === "jpg" ||
          ext2 === "jpeg" ||
          ext2 === "svg"
        ) {
          this.setState({ logo_pestana: file2 });
          this.setState({ logo_pestana_nombre: file2.name });
        } else {
          Swal.fire({
            icon: "error",
            text: "Tipo de archivo no aceptado",
            allowOutsideClick: false,
          });
        }
        break;
      }
      default:
        break;
    }
  };

  handleSubmit = (data) => {
    data.preventDefault();
    Api.loading(true);
    const postData = new FormData();

    postData.append("titulo", this.state.titulo);
    postData.append("logo_nombre", this.state.logo_nombre);
    postData.append("logo", this.state.logo);
    postData.append("logo_pestana", this.state.logo_pestana);
    postData.append("logo_pestana_nombre", this.state.logo_pestana_nombre);
    postData.append("color", this.state.color);
    postData.append("_method", "PUT");

    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${sessionStorage.token}`,
      },
      body: postData,
    };

    fetch(`${Api.url}updateGeneralInfo`, requestOptions)
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        }
        throw new Error(response.status);
      })
      .then((data) => {
        Api.loading(false);
        
        Swal.fire({
          text: data.message,
          allowOutsideClick: false,
        }).then(() => {
          window.location.reload(true);
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: "No tiene acceso a ésta información",
          icon: "error",
          showCancelButton: false,
          cancelButtonColor: "green",
          confirmButtonText: "Continuar",
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = "/admin/inicio";
          }
        });
      });
  };

  handleColor = (c) => {
    this.setState({ color: c });
    localStorage.setItem("color", JSON.stringify(c));
  };

  render() {
    return (
      <Page title="Información de contacto">
        <Container>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ width: "100%" }}
          >
            <Typography variant="h4" gutterBottom>
              Información General
            </Typography>
          </Stack>
          <br />
          <Box>
            <form onSubmit={(e) => this.handleSubmit(e)}>
              <Typography>
                Título <span style={{ color: "red" }}>*</span>
              </Typography>
              <TextField
                value={this.state.titulo}
                onChange={this.handleChange}
                required
                id="titulo"
                fullWidth
                inputProps={{ maxLength: 100 }}
              />

              <Typography sx={{ marginTop: 2 }}>
                Logo:
                <span style={{ color: "red" }}>
                  * solo archivos ".png", ".svg" y ".jpg"
                </span>
              </Typography>

              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                justifyContent="flex-start"
              >
                <ImageIcon />
                <Typography sx={{ marginTop: 2 }}>
                  {this.state.logo_nombre !== null
                    ? this.state.logo_nombre
                    : ""}
                </Typography>
              </Stack>
              <UploadButton
                id="Logo"
                onHandleChange={this.handleChange}
                ext=".png, .jpg, .svg"
              />

              <Typography sx={{ marginTop: 2 }}>
                Logo pestaña:
                <span style={{ color: "red" }}>
                  * solo archivos ".png", ".svg" y ".jpg"
                </span>
              </Typography>
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                justifyContent="flex-start"
              >
                <ImageIcon />
                <Typography sx={{ marginTop: 2 }}>
                  {this.state.logo_pestana_nombre !== null
                    ? this.state.logo_pestana_nombre
                    : ""}
                </Typography>
              </Stack>
              <UploadButton
                id="LogoPestana"
                onHandleChange={this.handleChange}
                ext=".png, .jpg, .svg"
              />
              <Typography sx={{ marginTop: 2, marginBottom: 2 }}>
                Color <span style={{ color: "red" }}>*</span>
              </Typography>
              <CirclePicker
                color={this.state.color}
                onChangeComplete={(color) => {
                  this.handleColor(color.hex);
                }}
              />

              <div align="right">
                <Button
                  size="large"
                  variant="contained"
                  type="submit"
                  sx={{ marginTop: 2 }}
                >
                  Guardar
                </Button>
              </div>
            </form>
          </Box>
        </Container>
      </Page>
    );
  }
}
