import { Box } from '@mui/system';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import { Button, Typography } from '@mui/material';
import CardListPublications from './CardListPublications';

Publications.propTypes = {
  titulo: PropTypes.string,
  relId: PropTypes.number,
  arrayPublications: PropTypes.array,
};

export default function Publications({ titulo, relId, arrayPublications }) {
  const theme = useTheme();
  const [publications, ] = useState(
    arrayPublications.length > 3 ? arrayPublications.slice(0, 3) : arrayPublications
  );
  const columns = publications.length;

  const buttonStyle = {
    // display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2, 2.5),
    // borderRadius: theme.shape.borderRadiusSm,
    backgroundColor: theme.palette.primary.main,
    color: '#ffffff',
    width: '100%'
  };

  const handleOnCLick = () => {
    window.location.href = `/publicaciones/${titulo}/${relId}`;
  };

  const changeBackground = (e) => {
    e.target.style.background = theme.palette.primary.dark;
  };
  const changeBackgroundLeave = (e) => {
    e.target.style.background = theme.palette.primary.main;
  };

  return (
    <Box>
      <CardListPublications arrayCard={arrayPublications} columns={columns} />
      <Button
        sx={buttonStyle}
        onMouseOver={changeBackground}
        onMouseLeave={changeBackgroundLeave}
        onClick={handleOnCLick}
      >
        Ver más
      </Button>
    </Box>
  );
}
