import React from 'react';
// Componentes
import Page from '../components/Page';
import Contacto from '../components/Contacto';
import { Api } from '../app-controller';

const host = window.location.host.split(":")[0];

export default class Contact extends React.Component {

  constructor() {
    super();
    this.state = {
      infoContacto: {}
    }
  }

  componentDidMount(){
    fetch(`${Api.url}getInfoContact`)
    .then((response) => response.json())
    .then((data) => {
      if (data) {
        this.setState({ infoContacto: data.info });
      }
    });
  }

  render() {
    return (
      <Page title="Contacto" >
        <Contacto info={this.state.infoContacto} />
      </Page>
    );
  }
}
