import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import CardPublications from './CardPublications';

CardListPublications.propTypes = {
  arrayCard: PropTypes.array,
  columns: PropTypes.number
};

export default function CardListPublications({ arrayCard, columns }) {
  const windowWidth = window.innerWidth;
  const [aux, ] = useState(windowWidth < 400 && columns % 2 === 0 ? 2: columns  );
  const [listPublications] = useState(arrayCard.slice(0, aux));

  return (
    <ImageList cols={aux}>
      {listPublications.map((card) => (
        <ImageListItem key={card.id} sx={{ padding: '10px', alignItems: 'center' }}>
          <CardPublications key={card.id} contents={card} columns={aux} />
        </ImageListItem>
      ))}
    </ImageList>
  );
}
