import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import plusFill from "@iconify/icons-eva/plus-fill";
// material
import {
  Box,
  Card,
  IconButton,
  Table,
  Stack,
  Button,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  Paper,
  TablePagination,
  Popover,
  ListItemIcon,
} from "@mui/material";
import Swal from "sweetalert2";
// Icono
import eyeFill from "@iconify/icons-eva/eye-outline";
// components
import Label from "../components/Label";
import Page from "../components/Page";
import Scrollbar from "../components/Scrollbar";
import Search from "../components/Search";
import ModalInput from "../components/ModalInput";
import Modal from "../components/Modal";
import Pdf from "../components/Pdf";
import Iconify from "../components/Iconify";
import { Api } from "../app-controller";

// ----------------------------------------------------------------------
const host = window.location.host.split(":")[0];

const tableHead = [
  { id: "titulo", label: "Titulo", alignRight: false },
  { id: "vista_previa", label: "Vista previa", alignRight: false },
  { id: "status", label: "Estado", alignRight: false },
  { id: "opciones", label: "Opciones", alignRight: true },
];

const title = "Nueva Licitación";

const elementos = [
  {
    id: "titulo_M",
    label: "Titulo",
    input: "text",
    required: true,
    value: "",
    maxLength: 100,
  },
  {
    id: "pdf_M",
    label: "Archivo pdf",
    input: "archivo",
    value: null,
    ext: ".png, .jpg, .svg, .pdf",
    required: true,
  },
];

const imgStyle = {
  float: "left",
  padding: "10px 10px 10px",
  height: "200px",
  width: "300px",
};

const hiddenStyle = {
  display: "none",
};

// ----------------------------------------------------------------------

function ShowLicitacion({ ...props }) {
  const windowWidth = window.innerWidth;
  const { id, titulo, ext } = props.item;
  const [anchorEl, setAnchorEl] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    event.stopPropagation();
    setModalShow(true);
  };

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleClose = () => {
    setModalShow(false);
  };

  return (
    <>
      <ListItemIcon
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        onClick={(e) => handleClick(e)}
      >
        <Icon icon={eyeFill} width={24} height={24} />
      </ListItemIcon>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography sx={{ p: 1 }}>Ver</Typography>
      </Popover>

      <Modal
        key={id}
        title={titulo}
        information={
          ext !== "pdf" ? (
            <img
              src={`${Api.url}getLicitacionPdf?id=${id}`}
              alt="Nombre de la imagen"
              style={windowWidth > 400 ? imgStyle : hiddenStyle}
            />
          ) : (
            <Pdf urlPdf={`${Api.url}getLicitacionPdf?id=${id}`} />
          )
        }
        isOpen={modalShow}
        handleClose={handleClose}
      />
    </>
  );
}
// ----------------------------------------------------------------------

export default class LicitacionesAdmin extends React.Component {
  constructor() {
    super();
    this.state = {
      licitaciones: [],
      modalShow: false,
      notFound: false,
      search: "",
      searchArray: [],
      rowsPerPage: 5,
      page: 0,
      total: 0,
    };

    this.handleClose = this.handleClose.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
  }

  componentDidMount() {
    this.setLicitacion();
    this.setCatalogos();
  }

  setCatalogos = () => {
    fetch(`${Api.url}getStatus`)
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          this.setSearch(data.statusArray);
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: "No tiene acceso a ésta información",
          icon: "error",
          showCancelButton: false,
          cancelButtonColor: "green",
          confirmButtonText: "Continuar",
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = "/admin/inicio";
          }
        });
      });
  };

  setSearch = (statusArray) => {
    this.setState({
      searchArray: [
        {
          id: "descripcion",
          label: "Descripción",
          input: "text",
        },
        {
          id: "status",
          label: "Estado",
          input: "select",
          select: statusArray,
        },
      ],
    });
  };

  setLicitacion = (query = "") => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.token}`,
      },
    };

    fetch(`${Api.url}getLicitacionesAdmin?${query}`, requestOptions)
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        }
        throw new Error(response.status);
      })
      .then((data) => {
        if (data) {
          const auxNotFound = data.licitaciones.total === 0;

          this.setState({
            licitaciones: data.licitaciones.data,
            total: data.licitaciones.total,
            page: data.licitaciones.current_page - 1,
            notFound: auxNotFound,
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: "No tiene acceso a ésta información",
          icon: "error",
          showCancelButton: false,
          cancelButtonColor: "green",
          confirmButtonText: "Continuar",
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = "/admin/inicio";
          }
        });
      });
  };

  handleSearch = (search) => {
    let src = "";
    search.forEach((param) => {
      if (
        param.value !== undefined &&
        param.value !== null &&
        param.value !== ""
      )
        src += `&${param.nombre}=${param.value}`;
    });

    this.setState({ search: src });
    this.setLicitacion(src);
  };

  handleChangeRowsPerPage = (event) => {
    const auxRowsPerPage = parseInt(event.target.value, 10);
    this.setState({ rowsPerPage: auxRowsPerPage, page: 0 });
    this.setLicitacion(`rowsPerPage=${auxRowsPerPage}${this.state.search}`);
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
    this.setLicitacion(
      `page=${newPage + 1}&rowsPerPage=${this.state.rowsPerPage}${
        this.state.search
      }`
    );
  };

  handleOpen = () => {
    this.setState({ modalShow: true });
  };

  handleClose = () => {
    this.setState({ modalShow: false });
  };

  handleAddLicitacion = (data) => {
    const postData = new FormData();
    let pasa = true;

    data.forEach((e) => {
      const newObject = {};
      newObject[e.nombre] = e.value;

      if (
        e.nombre === "pdf" &&
        e.value.ext !== "svg+xml" &&
        e.value.ext !== "png" &&
        e.value.ext !== "jpg" &&
        e.value.ext !== "jpeg" &&
        e.value.ext !== "svg" &&
        e.value.ext !== "pdf"
      ) {
        this.handleClose();
        Swal.fire({
          icon: "error",
          text: "Tipo de archivo no aceptado, intentelo nuevamente",
          allowOutsideClick: false,
        });
        pasa = false;
        return;
      }

      if (e.nombre === "titulo") {
        postData.append(e.nombre, JSON.stringify(e.value));
      } else {
        postData.append(e.nombre, e.value);
      }
    });

    if (pasa) {
      this.handleClose();
      Api.loading(true);

      const requestOptions = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${sessionStorage.token}`,
        },
        body: postData,
      };

      fetch(`${Api.url}createLicitacion`, requestOptions)
        .then((response) => {
          if (response.status === 200) {
            return response.json();
          }
          throw new Error(response.status);
        })
        .then((data) => {
          Api.loading(false);

          Swal.fire({
            text: data.message,
            allowOutsideClick: false,
          });

          this.setLicitacion(); // Actualizo tabla de links
        })
        .catch((error) => {
          Swal.fire({
            title: "Error",
            text: "No tiene acceso a ésta información",
            icon: "error",
            showCancelButton: false,
            cancelButtonColor: "green",
            confirmButtonText: "Continuar",
            allowOutsideClick: false,
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.href = "/admin/inicio";
            }
          });
        });
    }
  };

  handleStatusElemento = (id) => {
    Swal.fire({
      icon: "question",
      title: "¿Está seguro de realizar la acción?",
      showDenyButton: true,
      denyButtonColor: "red",
      confirmButtonColor: "#4CD964",
      showCancelButton: false,
      confirmButtonText: `Continuar`,
      denyButtonText: `Regresar`,
      reverseButtons: true,
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        const requestOptions = {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.token}`,
          },
          body: JSON.stringify({ id }),
        };

        fetch(`${Api.url}deactivateLicitacion`, requestOptions)
          .then((response) => {
            if (response.status === 200) {
              return response.json();
            }
            throw new Error(response.status);
          })
          .then((data) => {
            Swal.fire({
              text: data.message,
              allowOutsideClick: false,
            });
            this.setLicitacion(); // Actualizo tabla de licitaciones
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              text: "No tiene acceso a ésta información",
              icon: "error",
              showCancelButton: false,
              cancelButtonColor: "green",
              confirmButtonText: "Continuar",
              allowOutsideClick: false,
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.href = "/admin/inicio";
              }
            });
          });
      }
    });
  };

  render() {
    return (
      <Page title="Licitaciones">
        <Container>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ width: "100%" }}
          >
            <Typography variant="h4" gutterBottom>
              Licitaciones
            </Typography>
            <Box>
              <Button
                variant="contained"
                startIcon={<Icon icon={plusFill} />}
                onClick={() => this.handleOpen()}
              >
                Nueva licitación
              </Button>
              <ModalInput
                title={title}
                elementos={elementos}
                isOpen={this.state.modalShow}
                handleClose={this.handleClose}
                onHandleSubmit={this.handleAddLicitacion}
              />
            </Box>
          </Stack>
          <Box sx={{ my: 2 }}>
            <Search
              onHandleSearch={this.handleSearch}
              elementos={this.state.searchArray}
            />
          </Box>
          <Card>
            <Scrollbar>
              <TableContainer sx={{ minWidth: "100%" }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      {tableHead.map((headCell, index) => (
                        <TableCell key={index} align="left">
                          {headCell.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.licitaciones.map((licitacion, index) => (
                      <TableRow key={index}>
                        <TableCell align="left">{licitacion.titulo}</TableCell>
                        <TableCell align="left">
                          <ShowLicitacion item={licitacion} />
                        </TableCell>
                        <TableCell align="left">
                          <Label
                            variant="ghost"
                            color={
                              (parseInt(licitacion.status, 10) === 0 &&
                                "error") ||
                              "success"
                            }
                          >
                            {parseInt(licitacion.status, 10) === 0
                              ? "Inactivo"
                              : "Activo"}
                          </Label>
                        </TableCell>
                        <TableCell>
                          <IconButton
                            onClick={() =>
                              this.handleStatusElemento(licitacion.id)
                            }
                          >
                            {parseInt(licitacion.status, 10) === 1 ? (
                              <Iconify icon="eva:close-fill" />
                            ) : (
                              <Iconify icon="eva:checkmark-outline" />
                            )}
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                  {this.state.notFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <Paper>
                            <Typography variant="body2" align="center">
                              No se encontraron resultados.
                            </Typography>
                          </Paper>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={this.state.total}
              rowsPerPage={this.state.rowsPerPage}
              page={this.state.page}
              onPageChange={this.handleChangePage}
              onRowsPerPageChange={this.handleChangeRowsPerPage}
            />
          </Card>
        </Container>
      </Page>
    );
  }
}
