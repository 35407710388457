import { useEffect, useState } from 'react';
import { useParams, Link as RouterLink } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Card, Stack, Container, Typography } from '@mui/material';
// layouts
import Swal from 'sweetalert2';
import { Api } from '../app-controller';
// components
import Logo from '../components/Logo';
import Page from '../components/Page';
import { MHidden } from '../components/@material-extend';
import ChangePasswordForm from '../components/authentication/login/ChangePasswordForm';
// ----------------------------------------------------------------------

const host = window.location.host.split(":")[0];

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function ChangePassword() {
  const params = useParams();
  console.log(params.token);
  const [token] = useState(params.token);
  const [showForm, setShowForm] = useState(true);

  useEffect(() => {
    Api.loading(true);
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ token })
    };

    fetch(`${Api.url}verifyPasswordRequest`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        Api.loading(false);
        if (data.status === 200) {
          setShowForm(true);
        } else {
          Swal.fire({
            text: data.error,
            icon: 'error',
            allowOutsideClick: false
          }).then(() => {
            window.location.href = '/login';
          });
        }
      });
  }, []);

  return (
    <RootStyle title="Cambio de contraseña">
      <MHidden width="mdDown">
        <SectionStyle>
          <RouterLink to="/">
            <Logo />
          </RouterLink>
          <img alt="register" src="/static/logo_jl.png" />
        </SectionStyle>
      </MHidden>

      <Container maxWidth="sm">
        <ContentStyle>
          <Stack sx={{ mb: 5 }}>
            <Typography variant="h4" gutterBottom>
              Cambiar contraseña
            </Typography>
          </Stack>
          {showForm ? <ChangePasswordForm token={token} /> : null}
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
