import * as React from 'react';
import PropTypes from 'prop-types';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { Api } from '../app-controller';

const host = window.location.host.split(":")[0];

ImageListC.propTypes = {
  images: PropTypes.array
};

export default function ImageListC({ images }) {
  const columns = images.length > 5 ? 5 : images.length;
  const altura = images.length > 5 ? 450 : 170;

  return (
    <ImageList sx={{ width: '100%', height: altura }} cols={columns} rowHeight={164}>
      {images.map((item, index) => (
        <ImageListItem key={index}>
          <img
            src={`${Api.url}getImagenGallery?id=${item.id}?w=164&h=164&fit=crop&auto=format`}
            srcSet={`${Api.url}getImagenGallery?id=${item.id}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
            // height="164px"
            style={{ width: "100%", height: "164px" }}
            alt={item.titulo}
            loading="lazy"
          />
        </ImageListItem>
      ))}
    </ImageList>
  );
}
