import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Carousel from 'react-material-ui-carousel';
import { Paper, Button, Box } from '@mui/material';
import Modal from './Modal';
import { Api } from '../app-controller';

const host = window.location.host.split(':')[0];

const boxStyle = {
  overflow: 'hidden'
};

const itemStyle = {
  backgroundColor: '#F2EFF2',
  border: '0px solid green',
  padding: '10px',
  margin: '0px',
  overflow: 'hidden',
  height: '300px'
};

const imgStyle = {
  float: 'left',
  padding: '0 10px 10px',
  height: '280px',
  width: '400px'
};

const titleStyle = {
  border: 'solid 0px red',
  overflow: 'hidden',
  height: '70px'
  /* width: '450px' */
};

const paragraphStyle = {
  border: 'solid 0px blue',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  height: '150px',
  textAlign: 'justify'
};

const auxStyle = {
  border: 'solid 0px blue',
  height: '310px'
};

const carStyle = {
  border: 'solid 0px green',
  height: '300px'
};

Item.propTypes = {
  item: PropTypes.object
};

function Item(props) {
  // const windowWidth = window.innerWidth;
  const isBrowser = typeof window !== 'undefined';
  const [windowWidth, setWidth] = useState(isBrowser ? window.innerWidth : 0);

  useEffect(() => {
    if (!isBrowser) return false;

    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const [modalShow, setModalShow] = useState(false);

  const handleOpen = (e) => {
    e.preventDefault();

    setModalShow(true);
  };

  const handleClose = () => {
    setModalShow(false);
  };

  return (
    <Paper style={itemStyle}>
      {windowWidth > 400 ? (
        <img
          style={imgStyle}
          src={`${Api.url}getImagenCarrusel?id=${props.item.id}`}
          alt="Nombre de la imagen"
        />
      ) : (
        <></>
      )}
      <br />
      <h2 style={titleStyle}>{props.item.titulo}</h2>

      <p style={paragraphStyle}>
        {props.item.resumen.length > 300 ? (
          <>{props.item.resumen.substr(0, 300)}...</>
        ) : (
          <>{props.item.resumen}</>
        )}
      </p>

      {props.item.link !== null ? (
        <Button className="CheckButton" href={props.item.link}>
          Leer más
        </Button>
      ) : (
        <Button className="CheckButton" onClick={(e) => handleOpen(e, props.item)}>
          Leer más
        </Button>
      )}

      <Modal
        title={props.item.titulo}
        information={props.item.contenido}
        isOpen={modalShow}
        handleClose={handleClose}
      />
    </Paper>
  );
}

CarouselImages.propTypes = {
  CarouselSettings: PropTypes.object,
  arrayElements: PropTypes.array
};

export default function CarouselImages({ CarouselSettings, arrayElements }) {
  return (
    <div style={auxStyle}>
      <Carousel
        sx={carStyle}
        height={300}
        autoPlay={CarouselSettings.autoPlay}
        indicators={CarouselSettings.indicators}
        swipe={CarouselSettings.swipe}
        cycleNavigation={CarouselSettings.cycleNavigation}
        navButtonsAlwaysVisible={CarouselSettings.navButtonsAlwaysVisible}
        navButtonsAlwaysInvisible={CarouselSettings.navButtonsAlwaysInvisible}
        fullHeightHover={CarouselSettings.fullHeightHover}
        duration={CarouselSettings.duration}
      >
        {arrayElements.map((item, i) => (
          <Item key={i} item={item} />
        ))}
      </Carousel>
    </div>
  );
}
