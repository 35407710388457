import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
// material
import { alpha, styled, useTheme } from '@mui/material/styles';
import { Box, AppBar, Toolbar } from '@mui/material';
import MenuNavbar from '../../components/MenuNavbar';
// components
import Logo from '../../components/Logo';
import NavbarLinks from '../../components/NavbarLinks';
// imports
// import MenuConfig from './MenuConfig';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 0;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 75;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 1),
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`
  }
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5)
  }
}));

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func
};

const handleOpenTab = (elementLink) => {
  window.open(elementLink, '_blank');
};
// ---------------- ARREGLO DE PESTAÑAS DEL MENÚ ------------------------
const MENU = [
  {
    id: '1',
    title: 'Inicio',
    route: '/inicio', // Este debe llevarte a /dashboard/app
    type: 'inicio', // tipo de plantilla
    showSubmenu: false,
    submenu: []
  },
  {
    id: '2',
    title: 'Contacto',
    route: '/contacto',
    type: 'texto_imagen',
    showSubmenu: false,
    submenu: []
  }
];

// ---------------- ARREGLO DE PESTAÑAS DE LINKS ------------------------
const ARRAYLINKS = [
  {
    id: '1',
    a_link: '/login',
    title: 'Iniciar sesión Iniciar sesión'
  },
  {
    id: '2',
    a_link: 'https://www.google.com',
    title: 'Google'
  },
  {
    id: '3',
    a_link: 'https://www.youtube.com',
    title: 'Youtube'
  },
  {
    id: '4',
    a_link: '/login',
    title: 'Iniciar sesión'
  },
  {
    id: '5',
    a_link: 'https://www.google.com',
    title: 'Google'
  },
  {
    id: '6',
    a_link: 'https://www.youtube.com',
    title: 'Youtube'
  }
];

export default function DashboardNavbar({ links, menu, onOpenSidebar }) {
  // const [menu ] = useState(MENU);
  const [elementsLink] = useState(ARRAYLINKS);
  const theme = useTheme();
  const aStyle = {
    border: '10px',
    padding: '10px',
    textDecoration: 'none',
    color: theme.palette.primary.main
  };

  return (
    <RootStyle>
      <ToolbarStyle>
        <Logo />
        <Box sx={{ flexGrow: 1 }} />
        <NavbarLinks ItemsLinks={links} />
      </ToolbarStyle>
      <MenuNavbar menu={menu} />
    </RootStyle>
  );
}
