import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Document, Page, pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function getWindowDimensions() {
  return document.documentElement.clientWidth;
}

Pdf.propTypes = {
  urlPdf: PropTypes.string,
  modal: PropTypes.number,
  imagen: PropTypes.bool,
};

export default function Pdf({ urlPdf, modal, imagen = false}) {
  const [numPages, setNumPages] = useState([]);
  const [widthPx, setWidthPx] = useState(
    document.documentElement.scrollWidth > 1000 ? 1000 : document.documentElement.scrollWidth
  );

  useEffect(() => {
    function handleResize() {
      if (getWindowDimensions() < 1000) setWidthPx(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const onDocumentLoadSuccess = ({ numPages }) => {
    const pageArray = Array.from(Array(numPages).keys());

    if (modal) setWidthPx(modal);

    setNumPages(imagen ? [0] : pageArray);
  };

  return (
    <Document file={urlPdf} onLoadSuccess={onDocumentLoadSuccess}>
      {numPages.map((number) => (
        <Page key={number} pageNumber={number + 1} width={widthPx}/>
      ))}
    </Document>
  );
}
