import React, { useEffect, useState } from 'react';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { DatePicker, LocalizationProvider } from '@mui/lab';
// material
import {
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  Button,
  IconButton,
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
  OutlinedInput,
  Select,
  useTheme,
  Typography,
  InputAdornment,
  List,
  ListItem,
  Stack
} from '@mui/material';
import { Icon } from '@iconify/react';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import eyeFill from '@iconify/icons-eva/eye-outline';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import data from '@iconify/icons-eva/arrow-ios-downward-fill';
import ArchiveIcon from '@mui/icons-material/Archive';
import Swal from 'sweetalert2';
import UploadButton from './loadImages';
import Iconify from './Iconify';
import TablaLista from './auxiliaresComponentes/TablaListaLinks';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

export default function ModalInput({ title, elementos, isOpen, handleClose, onHandleSubmit }) {
  const [elementosInput, setElementosInput] = useState([]);
  useEffect(() => {
    setElementosInput(elementos);
  }, [elementos]);

  const handleChangeValue = (newValue, index) => {
    const aux = [...elementosInput];

    switch (aux[index].input) {
      case 'text':
        aux[index].value = aux[index].upperCase ? newValue.toUpperCase() : newValue;
        break;
      default:
        aux[index].value = newValue;
        break;
    }

    setElementosInput(aux);
  };

  const cult = '';
  const theme = useTheme();
  const [personName, setPersonName] = React.useState([]);
  const [password, setPassword] = React.useState('');
  const [showPassword, setShowPassword] = useState(false);
  const handleShowPassword = () => setShowPassword((show) => !show);
  const [mensaje, setMensaje] = useState('');
  // const [confirmPassword, setConfirmPassword] = React.useState('');

  const [list, setList] = useState([]);
  const [val1, setValue1] = useState('');
  const [val2, setValue2] = useState('');

  const addToList = () => {
    const tempArr = list;

    tempArr.push({
      descripcion: document.getElementById('descripcion_array').value,
      link: document.getElementById('link_array').value
    });

    setList(tempArr);
    setValue1('');
    setValue2('');
  };

  const deleteItem = (event, index) => {
    event.preventDefault();
    const temp = list.filter((item, i) => i !== index);
    setList(temp);
  };

  const handleChange = (event) => {
    const {
      target: { value }
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  const fullWidth = true;
  const [date, setDate] = useState(null);
  const [pdf, setPdf] = useState(null); // Por ahora solo se puede agregar un subir archivo por modal
  const [errorPdf, setErrorPdf] = useState(false);

  const handleGetData = (event) => {
    event.preventDefault();
    console.log('entro a handleGetData');

    const data = [];
    elementos.forEach((item) => {
      switch (item.input) {
        case 'date':
          data.push({ nombre: item.id.slice(0, -2), value: date });
          break;
        case 'pdf':
          data.push({ nombre: item.id.slice(0, -2), value: pdf });
          break;
        case 'list':
          data.push({ nombre: item.id.slice(0, -2), value: list });
          break;
        case 'archivo':
          data.push({ nombre: item.id.slice(0, -2), value: pdf });
          break;
        default:
          data.push({
            nombre: item.id.slice(0, -2),
            value: document.getElementById(item.id).value
          });
          break;
      }
    });

    const valido = validarArchivo(elementos, data);
    // console.log(valido);

    if (valido) {
      onHandleSubmit(data);
      limpiarCampos(elementos);
    }
  };

  const validarArchivo = (arrayElements, data) => {
    const archivo = arrayElements.find((i) => i.input === 'archivo');
    const existeElemento = archivo !== undefined; // si existe elemento para archivo

    if (existeElemento) {
      // validar que no este vácio
      const aux = data.find((d) => d.nombre === archivo.id.slice(0, -2));
      if (aux.value === null) {
        setMensaje('Favor de seleccionar un archivo');
        return false;
      }

      setMensaje('');
      return true;
    }

    return true;
  };

  const handleCloseInput = () => {
    handleClose();
    limpiarCampos(elementosInput);
  };

  const limpiarCampos = (arrayElements) => {
    arrayElements.forEach((item) => {
      switch (item.input) {
        case 'date':
          setDate(null);
          break;
        case 'pdf':
          setPdf(null);
          break;
        case 'list':
          break;
        case 'archivo':
          setPdf(null);
          break;
        default:
          item.value = '';
          break;
      }
    });

    setElementosInput(arrayElements);
  };

  const handleChangeSelect = (event, id, value) => {
    const selectElement = document.querySelector(`#${id}`);
    selectElement.value = value;
  };

  const handleChangePdf = (event) => {
    const element = event.target;
    const file = element.files[0];

    if (file !== undefined) {
      const ext = file.type.split('/')[1];

      if (ext === 'pdf') {
        file.ext = ext;

        setErrorPdf(false);
        setPdf(file);
      } else {
        console.log('error');
        setErrorPdf(true);
      }
    }
  };

  const handleChangeFile = (event) => {
    const element = event.target;
    const file = element.files[0];

    if (file !== undefined) {
      const ext = file.type.split('/')[1];
      file.ext = ext;
      setPdf(file);
      setMensaje('');
    }
  };

  return (
    <Dialog open={isOpen} onClose={handleCloseInput} fullWidth={fullWidth}>
      <DialogTitle>
        {title}
        {handleClose ? (
          <IconButton
            aria-label="close"
            onClick={handleCloseInput}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}
          >
            <Iconify icon="eva:close-outline" />
          </IconButton>
        ) : null}
      </DialogTitle>
      <form onSubmit={(e) => handleGetData(e)}>
        {elementosInput.map((item, indexElement) => (
          <fragment>
            <DialogContent>
              <Typography>
                {item.label} {item.required ? <span style={{ color: 'red' }}>*</span> : null}
              </Typography>
              <FormControl fullWidth>
                {item.input === 'select' ? (
                  <>
                    <InputLabel htmlFor={item.id}>Seleccione</InputLabel>
                    <Select id={item.id} placeholder={item.id} required={item.required}>
                      {item.select.map((option, index) => (
                        <MenuItem
                          key={option.id}
                          value={option.id}
                          onClick={(e) => handleChangeSelect(e, item.id, option.id)}
                        >
                          {option.descripcion}
                        </MenuItem>
                      ))}
                    </Select>
                  </>
                ) : null}
                {item.input === 'selectMultiple' ? (
                  <Box
                    noValidate
                    component="form"
                    sx={{
                      display: 'flex',
                      flexDirection: 'column'
                    }}
                  >
                    <InputLabel htmlFor={item.id}>Seleccione</InputLabel>
                    <Select
                      placeholder={item.id}
                      multiple
                      value={personName}
                      onChange={handleChange}
                      input={<OutlinedInput label={item.id} />}
                      MenuProps={MenuProps}
                      required={item.required}
                    >
                      {item.select.map((option, index) => (
                        <MenuItem value={index}>{option}</MenuItem>
                      ))}
                    </Select>
                  </Box>
                ) : null}
                {item.input === 'number' ? (
                  <TextField
                    id={item.id}
                    placeholder={item.labelDefault}
                    fullWidth
                    key={item.label}
                    required={item.required}
                    inputProps={{ inputMode: 'numeric', pattern: `[0-9]{${item.maxLength}}` }}
                    value={item.value}
                    onChange={(e) => handleChangeValue(e.target.value, indexElement)}
                  />
                ) : null}
                {item.input === 'text' && item.upperCase ? ( // MAYÚSCULAS
                  <TextField
                    id={item.id}
                    placeholder={item.label}
                    maxRows={10}
                    multiline
                    fullWidth
                    key={item.label}
                    required={item.required}
                    value={item.value}
                    onChange={(e) => handleChangeValue(e.target.value, indexElement)}
                    inputProps={{ maxLength: item.maxLength }}
                  />
                ) : null}
                {item.input === 'text' && !item.upperCase ? (
                  <TextField
                    id={item.id}
                    placeholder={item.label}
                    maxRows={10}
                    multiline
                    fullWidth
                    key={item.label}
                    required={item.required}
                    inputProps={{ maxLength: item.maxLength }}
                    value={item.value}
                    onChange={(e) => handleChangeValue(e.target.value, indexElement)}
                  />
                ) : null}
                {item.input === 'email' ? (
                  <TextField
                    id={item.id}
                    placeholder={item.label}
                    fullWidth
                    key={item.label}
                    required={item.required}
                    inputProps={{
                      maxLength: item.maxLength,
                      pattern: '[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+[.]{1}[a-zA-Z]{2,}$'
                    }}
                    value={item.value}
                    onChange={(e) => handleChangeValue(e.target.value, indexElement)}
                  />
                ) : null}
                {item.input === 'password' ? (
                  <TextField
                    required={item.required}
                    fullWidth
                    id={item.id}
                    type={showPassword ? 'text' : 'password'}
                    placeholder={item.label}
                    inputProps={{
                      maxLength: 30,
                      minlength: 6
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleShowPassword} edge="end">
                            <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                ) : null}
                {item.input === 'date' ? (
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      value={date}
                      onChange={(newValue) => {
                        setDate(newValue);
                      }}
                      renderInput={(params) => <TextField {...params} />}
                      required={item.required}
                    />
                  </LocalizationProvider>
                ) : null}
                {item.input === 'pdf' ? (
                  <>
                    {pdf !== null ? (
                      <>
                        <Stack
                          direction="row"
                          spacing={1}
                          alignItems="center"
                          justifyContent="flex-start"
                        >
                          <PictureAsPdfIcon />
                          <Typography sx={{ marginTop: 2 }}>
                            {(() => {
                              if (errorPdf) {
                                return (
                                  <span style={{ color: 'red' }}>
                                    Favor de seleccionar un archivo ".pdf"
                                  </span>
                                );
                              }

                              return pdf.name;
                            })()}
                          </Typography>
                        </Stack>
                      </>
                    ) : null}
                    <UploadButton
                      id={item.id}
                      onHandleChange={handleChangePdf}
                      ext="application/pdf"
                      required={item.required}
                    />
                  </>
                ) : null}
                {item.input === 'archivo' ? (
                  <>
                    {pdf !== null ? (
                      <>
                        <Stack
                          direction="row"
                          spacing={1}
                          alignItems="center"
                          justifyContent="flex-start"
                        >
                          <ArchiveIcon />
                          <Typography sx={{ marginTop: 2 }}>{pdf.name}</Typography>
                        </Stack>
                      </>
                    ) : null}
                    <UploadButton
                      id={item.id}
                      onHandleChange={handleChangeFile}
                      ext={item.ext}
                      required={item.required}
                    />
                  </>
                ) : null}
                {item.input === 'list' ? (
                  <>
                    <Stack
                      direction="row"
                      spacing={3}
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <TextField
                        id="descripcion_array"
                        placeholder="Descripción"
                        onChange={(e) => setValue1(e.target.value)}
                        value={val1}
                        inputProps={{ maxLength: 50 }}
                      />
                      <TextField
                        id="link_array"
                        placeholder="Liga"
                        onChange={(e) => setValue2(e.target.value)}
                        value={val2}
                        inputProps={{ maxLength: 100 }}
                      />
                      <IconButton onClick={addToList} disabled={val1 === '' || val2 === ''}>
                        <Iconify icon="eva:plus-outline" />
                      </IconButton>
                    </Stack>
                    <br />
                    {list.length > 0 ? (
                      <TablaLista
                        arrayColumn={[
                          { id: 'descripcion', label: ' ', alignRight: false },
                          { id: 'link', label: ' ', alignRight: false },
                          { id: ' ', label: ' ', alignRight: false }
                        ]}
                        arrayRows={list}
                        onHandleDelete={deleteItem}
                      />
                    ) : null}
                  </>
                ) : null}
              </FormControl>
            </DialogContent>
          </fragment>
        ))}
        <DialogContent>
          <Typography>
            <span style={{ color: 'red' }}>{mensaje}</span>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" type="submit">
            Guardar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
