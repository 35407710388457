import { Icon } from '@iconify/react';
import React, { useEffect, useState } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
// material
import {
  Box,
  Card,
  Table,
  Stack,
  Button,
  IconButton,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  Paper,
  TablePagination
} from '@mui/material';
import Swal from 'sweetalert2';
// components
import Label from '../components/Label';
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import Search from '../components/Search';
import ModalInput from '../components/ModalInput';
import ModalEdit from '../components/ModalEdit';
import Iconify from '../components/Iconify';
import { Api } from "../app-controller";

// ----------------------------------------------------------------------
const host = window.location.host.split(':')[0];

const tableHead = [
  { id: 'descripcion', label: 'Descripción', alignRight: false },
  { id: 'links', label: 'Ligas', alignRight: false },
  { id: 'status', label: 'Estado', alignRight: false },
  { id: 'opciones', label: 'Opciones', alignRight: true }
];

const title = 'Nuevo pie de página';
let titleE = '';

export default class FooterAdmin extends React.Component {
  constructor() {
    super();
    this.state = {
      footer: [],
      searchArray: [],
      footerID: null,
      elementosA: [],
      elementosE: [],
      lista: [],
      modalShow: false,
      modalShowE: false,
      modalView: false,
      notFound: false,
      // search: '',
      // rowsPerPage: 5,
      // page: 0,
      // total: 0
    };

    this.handleClose = this.handleClose.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
  }

  componentDidMount() {
    this.setFooter();
    this.setCatalogos();
    this.setElementos();
  }

  setFooter = (query = '') => {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.token}`
      }
    };

    fetch(`${Api.url}getFooterAdmin?${query}`, requestOptions)
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        }
        throw new Error(response.status);
      })
      .then((data) => {
        if (data) {
          const auxNotFound = data.footer.length === 0;

          this.setState({
            footer: data.footer,
            // total: data.footer.total,
            // page: data.footer.current_page - 1,
            notFound: auxNotFound
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          title: 'Error',
          text: 'No tiene acceso a ésta información',
          icon: 'error',
          showCancelButton: false,
          cancelButtonColor: 'green',
          confirmButtonText: 'Continuar',
          allowOutsideClick: false
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = '/admin/inicio';
          }
        });
      });
  };

  setCatalogos = () => {
    fetch(`${Api.url}getStatus`)
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          this.setSearch(data.statusArray);
        }
      });
  };

  setSearch = (statusArray) => {
    this.setState({
      searchArray: [
        {
          id: 'descripcion',
          label: 'Descripción',
          input: 'text'
        },
        {
          id: 'link',
          label: 'Liga',
          input: 'text'
        },
        {
          id: 'status',
          label: 'Estado',
          input: 'select',
          select: statusArray
        }
      ]
    });
  };

  setElementos = (arrayLevel) => {
    this.setState({
      elementosA: [
        {
          id: 'descripcion_M',
          label: 'Descripción',
          input: 'text',
          value: "",
          required: true,
          maxLength: 100
        },
        {
          id: 'lista_M',
          label: 'Ligas',
          input: 'list'
        }
      ]
    });
  };

  handleSearch = (search) => {
    let src = '';
    search.forEach((param) => {
      if (param.value !== undefined && param.value !== null && param.value !== '')
        src += `&${param.nombre}=${param.value}`;
    });

    // this.setState({ search: src });
    this.setFooter(src);
  };

/*   handleChangeRowsPerPage = (event) => {
    const auxRowsPerPage = parseInt(event.target.value, 10);
    this.setState({ rowsPerPage: auxRowsPerPage, page: 0 });
    this.setFooter(`rowsPerPage=${auxRowsPerPage}${this.state.search}`);
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
    this.setFooter(`page=${newPage + 1}&rowsPerPage=${this.state.rowsPerPage}${this.state.search}`);
  }; */

  handleOpen = () => {
    this.setState({ modalShow: true });
  };

  handleClose = () => {
    this.setState({ modalShow: false });
  };

  handleOpenE = () => {
    this.setState({ modalShowE: true, modalView: false });
    titleE = 'Editar pie de página';
  };

  handleOpenV = () => {
    this.setState({ modalShowE: true });
    titleE = 'Ver pie de página';
  };

  handleCloseE = () => {
    this.setState({ modalShowE: false, modalView: false, elementosE: [] });
  };

  handleAddFooter = (data) => {
    const postData = {};

    data.forEach((e) => {
      const newObject = {};
      newObject[e.nombre] = e.value;
      Object.assign(postData, newObject);
    });

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.token}`
      },
      body: JSON.stringify({ postData })
    };

    fetch(`${Api.url}createFooter`, requestOptions)
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        }
        throw new Error(response.status);
      })
      .then((data) => {
        this.handleClose();

        Swal.fire({
          text: data.message,
          allowOutsideClick: false
        });

        this.setFooter(); // Actualizo tabla de pie de página
      })
      .catch((error) => {
        Swal.fire({
          title: 'Error',
          text: 'No tiene acceso a ésta información',
          icon: 'error',
          showCancelButton: false,
          cancelButtonColor: 'green',
          confirmButtonText: 'Continuar',
          allowOutsideClick: false
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = '/admin/inicio';
          }
        });
      });
  };

  handleStatusFooter = (id) => {
    Swal.fire({
      icon: 'question',
      title: '¿Está seguro de realizar la acción?',
      showDenyButton: true,
      denyButtonColor: 'red',
      confirmButtonColor: '#4CD964',
      showCancelButton: false,
      confirmButtonText: `Continuar`,
      denyButtonText: `Regresar`,
      reverseButtons: true,
      allowOutsideClick: false
    }).then((result) => {
      if (result.isConfirmed) {
        const requestOptions = {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sessionStorage.token}`
          },
          body: JSON.stringify({ id })
        };

        fetch(`${Api.url}deactivateFooter`, requestOptions)
          .then((response) => {
            if (response.status === 200) {
              return response.json();
            }
            throw new Error(response.status);
          })
          .then((data) => {
            Swal.fire({
              text: data.message,
              allowOutsideClick: false
            });

            this.setFooter(); // Actualizo tabla de links
          })
          .catch((error) => {
            Swal.fire({
              title: 'Error',
              text: 'No tiene acceso a ésta información',
              icon: 'error',
              showCancelButton: false,
              cancelButtonColor: 'green',
              confirmButtonText: 'Continuar',
              allowOutsideClick: false
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.href = '/admin/inicio';
              }
            });
          });
      }
    });
  };

  handleGetFooterEdit = (id) => {
    this.setState({ footerID: id }, this.setFooterByID(id));
    this.handleOpenE();
  };

  handleGetFooterView = (id) => {
    this.setState({ footerID: id, modalView: true }, this.setFooterByID(id), this.handleOpenV());
  };

  setFooterByID = (id) => {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.token}`
      }
    };

    fetch(`${Api.url}getFooterByID?id=${id}`, requestOptions)
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        }
        throw new Error(response.status);
      })
      .then((data) => {
        if (data) {
          this.setState({ lista: data.footer.links });

          this.setState({
            elementosE: [
              {
                id: 'descripcion_M',
                label: 'Descripción',
                input: 'text',
                required: true,
                value: data.footer.descripcion,
                maxLength: 100
              },
              {
                id: 'lista_M',
                label: 'Ligas',
                input: 'list',
                value: data.footer.links
              }
            ]
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          title: 'Error',
          text: 'No tiene acceso a ésta información',
          icon: 'error',
          showCancelButton: false,
          cancelButtonColor: 'green',
          confirmButtonText: 'Continuar',
          allowOutsideClick: false
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = '/admin/inicio';
          }
        });
      });
  };

  handleUpdateFooter = (data) => {
    // const theme = useTheme();
    const postData = {};
    Object.assign(postData, { id: this.state.footerID });

    data.forEach((e) => {
      const newObject = {};
      newObject[e.nombre] = e.value;
      Object.assign(postData, newObject);
    });

    const requestOptions = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.token}`
      },
      body: JSON.stringify({ postData })
    };

    fetch(`${Api.url}updateFooter`, requestOptions)
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        }
        throw new Error(response.status);
      })
      .then((data) => {
        this.handleCloseE();

        Swal.fire({
          text: data.message
        });

        this.setFooter(); // Actualizo tabla de pie de página
        this.setFooterByID(this.state.footerID);
      })
      .catch((error) => {
        Swal.fire({
          title: 'Error',
          text: 'No tiene acceso a ésta información',
          icon: 'error',
          showCancelButton: false,
          cancelButtonColor: 'green',
          confirmButtonText: 'Continuar',
          allowOutsideClick: false
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = '/admin/inicio';
          }
        });
      });
  };

  changeRowOrder = (id, up) => {
    Api.loading(true);
    const requestOptions = {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.token}`,
      },
    };

    fetch(
      `${Api.url}changeOrderRowFooter?id=${id}&up=${up}`,
      requestOptions
    )
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        }
        throw new Error(response.status);
      })
      .then((data) => {
        if (data) {
          this.setFooter();
          Api.loading(false);
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: "No tiene acceso a ésta información",
          icon: "error",
          showCancelButton: false,
          cancelButtonColor: "green",
          confirmButtonText: "Continuar",
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = "/admin/inicio";
          }
        });
      });
  };

  render() {
    return (
      <Page title="Pie de página">
        <Container>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ width: '100%' }}
          >
            <Typography variant="h4" gutterBottom>
              Pie de página
            </Typography>
            <Box>
              <Button
                variant="contained"
                startIcon={<Icon icon={plusFill} />}
                onClick={() => this.handleOpen()}
              >
                Nuevo pie de página
              </Button>
              <ModalInput
                title={title}
                elementos={this.state.elementosA}
                isOpen={this.state.modalShow}
                handleClose={this.handleClose}
                onHandleSubmit={this.handleAddFooter}
              />
              <ModalEdit
                title={titleE}
                elementos={this.state.elementosE}
                isOpen={this.state.modalShowE}
                handleClose={this.handleCloseE}
                onHandleSubmit={this.handleUpdateFooter}
                modalView={this.state.modalView}
                lista={this.state.lista}
              />
            </Box>
          </Stack>
          <Box sx={{ my: 2 }}>
            <Search onHandleSearch={this.handleSearch} elementos={this.state.searchArray} />
          </Box>
          <Card>
            <Scrollbar>
              <TableContainer sx={{ minWidth: '100%' }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell key='changeRow' align="center">
                        Orden
                      </TableCell>
                      {tableHead.map((headCell, index) => (
                        <TableCell key={index} align="left">
                          {headCell.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.footer.map((PP, index) => (
                      <TableRow key={index}>
                         <TableCell align="left">
                          <Stack>
                            {index !== 0 ? (
                              <IconButton
                                size="small"
                                onClick={() =>
                                  this.changeRowOrder(PP.id, true)
                                }
                              >
                                <Iconify icon="eva:arrow-ios-upward-outline" />
                              </IconButton>
                            ) : null}

                            {index !== this.state.footer.length - 1 ? (
                              <IconButton
                                size="small"
                                onClick={() =>
                                  this.changeRowOrder(PP.id, false)
                                }
                              >
                                <Iconify icon="eva:arrow-ios-downward-outline" />
                              </IconButton>
                            ) : null}
                          </Stack>
                        </TableCell>
                        <TableCell align="left">{PP.descripcion}</TableCell>
                        <TableCell align="left">{PP.links_descripcion}</TableCell>
                        <TableCell align="left">
                          <Label variant="ghost" color={(parseInt(PP.status,10) === 0 && 'error') || 'success'}>
                            {PP.status_descripcion}
                          </Label>
                        </TableCell>
                        <TableCell>
                          <IconButton onClick={() => this.handleGetFooterView(PP.id)}>
                            <Iconify icon="eva:eye-outline" />
                          </IconButton>
                          <IconButton onClick={() => this.handleGetFooterEdit(PP.id)}>
                            <Iconify icon="eva:edit-2-outline" />
                          </IconButton>
                          <IconButton onClick={() => this.handleStatusFooter(PP.id)}>
                            {parseInt(PP.status,10) === 1 ? (
                              <Iconify icon="eva:close-fill" />
                            ) : (
                              <Iconify icon="eva:checkmark-outline" />
                            )}
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                  {this.state.notFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <Paper>
                            <Typography variant="body2" align="center">
                              No se encontraron resultados.
                            </Typography>
                          </Paper>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>
{/*             <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={this.state.total}
              rowsPerPage={this.state.rowsPerPage}
              page={this.state.page}
              onPageChange={this.handleChangePage}
              onRowsPerPageChange={this.handleChangeRowsPerPage}
            /> */}
          </Card>
        </Container>
      </Page>
    );
  }
}
