import * as React from "react";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { Icon } from "@iconify/react";
import plusFill from "@iconify/icons-eva/plus-fill";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Card,
  Divider,
  Table,
  Stack,
  Button,
  IconButton,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  Paper,
  TablePagination,
  Popover,
  ListItemIcon,
} from "@mui/material";
import Swal from "sweetalert2";

// componentes
import Page from "../components/Page";
import UploadButton from "../components/loadImages";
import Pdf from "../components/Pdf";
import { Api } from "../app-controller";

//

export default function Directorio() {
  const [directorio, setDirectorio] = React.useState(
    `${Api.url}getDirectorio`
  );
  const [pdf, setPdf] = React.useState(false);
  const [carga, setCargando] = React.useState(false);
  const [archivo, setArchivo] = React.useState(null);
  const [mensajeError, setMensajeError] = React.useState("");

  const handleChange = (event) => {
    const element = event.target;
    const file = element.files[0];

    if (file !== undefined) {
      const ext = file.type.split("/")[1];

      if (
        ext === "pdf" ||
        ext === "svg+xml" ||
        ext === "png" ||
        ext === "jpg" ||
        ext === "jpeg" ||
        ext === "svg"
      ) {
        file.ext = ext;
        setArchivo(file);
        Swal.fire({
          title: "¿Está seguro de agregar este archivo?",
          text: directorio.nombre,
          icon: "warning",
          showCancelButton: false,
          cancelButtonColor: "green",
          confirmButtonText: "Continuar",
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            handleSubmit(file);
          }
        });

      } else {
        setMensajeError("Tipo de archivo no aceptado");
      }
    }
  };

  const handleSubmit = (datos) => {
    Api.loading(true);
    const postData = new FormData();
    postData.append('directorio', datos);
    postData.append("_method", "PUT");

    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${sessionStorage.token}`,
      },
      body: postData,
    };

    fetch(`${Api.url}updateDirectorio`, requestOptions)
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        }
        throw new Error(response.status);
      })
      .then((data) => {
        if (data) {
          Api.loading(false);
          setDirectorio(`${Api.url}getDirectorio`);
          setMensajeError("");
          setPdf(data.esPdf);
          setCargando(true);
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: "No tiene acceso a ésta información",
          icon: "error",
          showCancelButton: false,
          cancelButtonColor: "green",
          confirmButtonText: "Continuar",
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = "/admin/inicio";
          }
        });
      });
  };

  React.useEffect(() => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.token}`,
      },
    };

    fetch(`${Api.url}getExtDirectorio`, requestOptions)
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        }
        throw new Error(response.status);
      })
      .then((data) => {
        if (data[0] !== null) {
          setPdf(data.esPdf);
          setCargando(true);
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: "No tiene acceso a ésta información",
          icon: "error",
          showCancelButton: false,
          cancelButtonColor: "green",
          confirmButtonText: "Continuar",
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = "/admin/inicio";
          }
        });
      });
  }, []);

  // const contenidoDirectorio = React.useMemo(() => ({ contenido: directorio }), [directorio])

  return (
    <Page title="Directorio">
      <Box sx={{ pd: 5 }}>
        <Typography variant="h4" gutterBottom>
          Directorio
        </Typography>
      </Box>

      <box>
        <Card>
          <Stack
            direction="row"
            sx={{ margin: 3 }}
            spacing={2}
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography>
              A continuación, puede agregar el archivo actualizado
            </Typography>
            <UploadButton
              id="directorio"
              onHandleChange={handleChange}
              ext="multipart/form-data"
            />
          </Stack>
          {mensajeError !== "" ? (
            <Typography sx={{ marginBottom: 3, marginLeft: 3, marginRight: 3 }}>
              <span style={{ color: "red" }}>{mensajeError}</span>
            </Typography>
          ) : null}
        </Card>
        <Divider variant="middle" sx={{ margin: "20px" }} />
        <Card>
          {carga ? (
            <Stack alignItems="center" justifyContent="space-between" mb={5}>
              {pdf ? (
                <Pdf urlPdf={directorio} />
              ) : (
                <img
                  src={directorio}
                  alt="Nombre de la imagen"
                  style={{ width: "100%" }}
                />
              )}{" "}
            </Stack>
          ) : null}
        </Card>
      </box>
    </Page>
  );
}
