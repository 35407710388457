import * as React from 'react';
// material
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  IconButton,
  TextField,
  Stack,
  Typography,
  Paper
} from '@mui/material';
import ImageIcon from '@mui/icons-material/Image';
import Iconify from './Iconify';
import UploadButton from './loadImages';
import { Api } from '../app-controller';

const host = window.location.host.split(':')[0];

const imgStyle = {
  border: 'solid 0px red',
  float: 'center',
  padding: '0 10px 10px',
  height: '180px',
  width: '200px'
};

class NuevoElementoFoto extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
    this.windowWidth = window.innerWidth;
  }

  render() {
    return (
      <Dialog open={this.props.isOpen} onClose={(e) => this.props.handleClose(e)} fullWidth>
        <DialogTitle>
          {this.props.ver ? 'Ver' : ''} {this.props.editar ? 'Editar' : ''}{' '}
          {this.props.nuevo ? 'Nueva' : ''} {this.props.title}
          <IconButton
            aria-label="close"
            onClick={(e) => this.props.handleClose(e)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}
          >
            <Iconify icon="eva:close-outline" />
          </IconButton>
        </DialogTitle>
        <form onSubmit={(e) => this.props.onHandleSubmit(e)}>
          <DialogContent>
            <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-start">
              {this.props.info.imagen !== null && !this.props.ver ? (
                <>
                  <ImageIcon />
                  <Typography sx={{ marginTop: 2 }}>
                    {typeof this.props.info.imagen === 'string'
                      ? this.props.info.imagen
                      : this.props.info.imagen.name}
                  </Typography>
                </>
              ) : (
                <>
                  {this.props.mensajeErrorSubmit.error ? (
                    <Typography>
                      <span style={{ color: 'red' }}>{this.props.mensajeErrorSubmit.mensaje}</span>
                    </Typography>
                  ) : null}
                </>
              )}
            </Stack>
            <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-start">
              <Typography>
                Imagen <span style={{ color: 'red' }}>*</span>
              </Typography>
              <UploadButton
                id="imagen"
                isDisabled={this.props.ver}
                onHandleChange={(e) => this.props.handleChangeValue(e)}
                ext="image/*"
                required
              />
            </Stack>

            <br />
            <Paper>
              {this.props.info.imagen !== null && this.props.ver ? (
                <img
                  style={imgStyle}
                  src={`${Api.url}getImagenGallery?id=${this.props.info.id}`}
                  alt="Nombre de la imagen"
                />
              ) : null}
            </Paper>
            <br />
            <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-start">
              <Typography>
                Descripción de la imagen<span style={{ color: 'red' }}>*</span>
              </Typography>
              <TextField
                id="titulo"
                placeholder="Descripción"
                maxRows={10}
                // error //como lo puedo obtener?
                multiline
                fullWidth
                required
                value={this.props.info.titulo}
                disabled={this.props.ver}
                onChange={(e) => this.props.handleChangeValue(e)}
                inputProps={{ maxLength: '150' }}
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="error" onClick={(e) => this.props.handleClose(e)}>
              {this.props.editar ? 'Cancelar' : 'Regresar'}
            </Button>

            {this.props.ver ? (
              <></>
            ) : (
              <Button variant="contained" type="submit">
                Guardar
              </Button>
            )}
          </DialogActions>
        </form>
      </Dialog>
    );
  }
}

export default NuevoElementoFoto;

