import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import plusFill from "@iconify/icons-eva/plus-fill";
import { Link, useParams } from "react-router-dom";
// material
import {
  Box,
  Card,
  Table,
  Stack,
  Button,
  IconButton,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  Paper,
  TablePagination,
} from "@mui/material";
import Swal from "sweetalert2";
// components
import Label from "../components/Label";
import Page from "../components/Page";
import Scrollbar from "../components/Scrollbar";
import ModalInput from "../components/ModalInput";
import Iconify from "../components/Iconify";
import NuevoElementoEditor from "../components/NuevoElementoEditor";
import { Api } from "../app-controller";

// ----------------------------------------------------------------------
const host = window.location.host.split(":")[0];

const tableHead = [
  { id: "descripcion", label: "Descripción", alignRight: false },
  { id: "elemento", label: "Elemento", alignRight: false },
  { id: "status", label: "Estado", alignRight: false },
  { id: "opciones", label: "Opciones", alignRight: true },
];

const title = "Nuevo elemento";

class Layout extends React.Component {
  constructor(props) {
    super();
    const location = window.location.pathname;
    const { id } = props.params;

    this.inicialState = {
      elementoEditor: {
        contenido: "",
      },
      mensajeErrorSubmit: {
        error: false,
        mensaje: "",
      },
    };

    this.state = {
      elementos: [],
      elementosA: [],
      elementoEditor: this.inicialState.elementoEditor,
      pestana: [],
      pestanaID: id,
      notFound: false,
      // rowsPerPage: 5,
      // page: 0,
      // total: 0,
      // search: '',
      modalShow: false,
      modalShowEditor: false,
      editarEditor: false,
      verEditor: false,
      nuevoEditor: false,
      mensajeErrorSubmit: this.inicialState.mensajeErrorSubmit,
    };
  }

  componentDidMount() {
    this.setElementos();
    this.setCatalogos();
  }

  setCatalogos = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.token}`,
      },
    };

    fetch(`${Api.url}getElementos`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          this.setState({
            elementosA: [
              {
                id: "elemento_m",
                label: "Elemento",
                input: "select",
                select: data.elementosArray,
                required: true,
              },
            ],
          });
        }
      });
  };

  setElementos = (query = "") => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.token}`,
      },
    };

    fetch(
      `${Api.url}getElementosPestana?id=${this.state.pestanaID}&${query}`,
      requestOptions
    )
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        }
        throw new Error(response.status);
      })
      .then((data) => {
        if (data) {
          const auxNotFound = data.elementos.length === 0;

          this.setState({
            elementos: data.elementos,
            pestana: data.pestana,
            // total: data.elementos.total,
            // page: data.elementos.current_page - 1,
            notFound: auxNotFound,
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: "No tiene acceso a ésta información",
          icon: "error",
          showCancelButton: false,
          cancelButtonColor: "green",
          confirmButtonText: "Continuar",
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = "/admin/inicio";
          }
        });
      });
  };

  /*   handleChangeRowsPerPage = (event) => {
    const auxRowsPerPage = parseInt(event.target.value, 10);
    this.setState({ rowsPerPage: auxRowsPerPage, page: 0 });
    this.setElementos(`rowsPerPage=${auxRowsPerPage}${this.state.search}`);
  }; */

  /*   handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
    this.setElementos(
      `page=${newPage + 1}&rowsPerPage=${this.state.rowsPerPage}${this.state.search}`
    );
  }; */

  handleOpen = () => {
    this.setState({ modalShow: true });
  };

  handleClose = () => {
    this.setState({ modalShow: false });
  };

  handleAddElemento = (data) => {
    const postData = {};
    Object.assign(postData, { pestanaID: this.state.pestana.id });
    Object.assign(postData, { descripcion: this.state.pestana.descripcion });

    data.forEach((e) => {
      const newObject = {};
      newObject[e.nombre] = e.value;
      Object.assign(postData, newObject);
    });

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.token}`,
      },
      body: JSON.stringify(postData),
    };

    fetch(`${Api.url}createElemento`, requestOptions)
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        }
        throw new Error(response.status);
      })
      .then((data) => {
        this.handleClose();
        Swal.fire({
          text: data.message,
          allowOutsideClick: false,
        });
        this.setElementos(); // Actualizo tabla de elementos
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: "No tiene acceso a ésta información",
          icon: "error",
          showCancelButton: false,
          cancelButtonColor: "green",
          confirmButtonText: "Continuar",
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = "/admin/inicio";
          }
        });
      });
  };

  handleStatusElemento = (id) => {
    Swal.fire({
      icon: "question",
      title: "¿Está seguro de realizar la acción?",
      showDenyButton: true,
      denyButtonColor: "red",
      confirmButtonColor: "#4CD964",
      showCancelButton: false,
      confirmButtonText: `Continuar`,
      denyButtonText: `Regresar`,
      reverseButtons: true,
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        const requestOptions = {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.token}`,
          },
          body: JSON.stringify({ id }),
        };

        fetch(`${Api.url}deactivateElemento`, requestOptions)
          .then((response) => {
            if (response.status === 200) {
              return response.json();
            }
            throw new Error(response.status);
          })
          .then((data) => {
            Swal.fire({
              text: data.message,
              allowOutsideClick: false,
            });
            this.setElementos(); // Actualizo tabla de elementos
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              text: "No tiene acceso a ésta información",
              icon: "error",
              showCancelButton: false,
              cancelButtonColor: "green",
              confirmButtonText: "Continuar",
              allowOutsideClick: false,
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.href = "/admin/inicio";
              }
            });
          });
      }
    });
  };

  // INICIO EDITOR ------------------------------------------------
  handleOpenEditor = (nuevo) => {
    if (nuevo) this.setState({ nuevoEditor: true });
    this.setState({ modalShowEditor: true });
  };

  handleCloseEditor = (e) => {
    this.setState({
      editarEditor: false,
      verEditor: false,
      nuevoEditor: false,
      elementoEditor: this.inicialState.elementoEditor,
      mensajeErrorSubmit: this.inicialState.mensajeErrorSubmit,
      modalShowEditor: false,
    });
  };

  handleGetElementoEdit = (e, id, aux) => {
    e.preventDefault();

    if (aux) {
      this.setState({ editarEditor: true });
    } else {
      this.setState({ verEditor: true });
    }

    this.setState({ peID: id });
    this.setElemento(id);
  };

  setElemento = (id) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.token}`,
      },
    };

    fetch(`${Api.url}getElementoEditor?id=${id}`, requestOptions)
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        }
        throw new Error(response.status);
      })
      .then((data) => {
        if (data) {
          if (data.elemento !== null) {
            // si no esta vacío o es ver
            this.setState({ elementoEditor: data.elemento });
          } else {
            this.setState({ nuevoEditor: true, editarEditor: false });
          }
          this.handleOpenEditor();
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: "No tiene acceso a ésta información",
          icon: "error",
          showCancelButton: false,
          cancelButtonColor: "green",
          confirmButtonText: "Continuar",
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = "/admin/inicio";
          }
        });
      });
  };

  handleChangeEditor = (value) => {
    const editor = this.state.elementoEditor;
    editor.contenido = value;

    this.guardElementoEditor(editor);
  };

  guardElementoEditor = (editor) => {
    this.setState({ elementoEditor: editor });
  };

  handleAddElementoEditar = (e) => {
    e.preventDefault();

    const data = this.state.elementoEditor;
    data.relpeID = this.state.peID;

    const valido = this.submitValidacion(data);

    if (valido) {
      if (this.state.nuevoEditor) {
        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.token}`,
          },
          body: JSON.stringify(data),
        };

        fetch(`${Api.url}createElementoEditor`, requestOptions)
          .then((response) => {
            if (response.status === 200) {
              return response.json();
            }
            throw new Error(response.status);
          })
          .then((data) => {
            Swal.fire({
              text: data.message,
              allowOutsideClick: false,
            });
            this.handleCloseEditor();
            this.setElementos(); // Actualizo tabla de elementos
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              text: "No tiene acceso a ésta información",
              icon: "error",
              showCancelButton: false,
              cancelButtonColor: "green",
              confirmButtonText: "Continuar",
              allowOutsideClick: false,
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.href = "/admin/inicio";
              }
            });
          });
      } else {
        const requestOptions = {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.token}`,
          },
          body: JSON.stringify(data),
        };

        fetch(`${Api.url}editElementoEditor`, requestOptions)
          .then((response) => {
            if (response.status === 200) {
              return response.json();
            }
            throw new Error(response.status);
          })
          .then((data) => {
            Swal.fire({
              text: data.message,
              allowOutsideClick: false,
            });
            this.handleCloseEditor();
            this.setElementos(); // Actualizo tabla de elementos
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              text: "No tiene acceso a ésta información",
              icon: "error",
              showCancelButton: false,
              cancelButtonColor: "green",
              confirmButtonText: "Continuar",
              allowOutsideClick: false,
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.href = "/admin/inicio";
              }
            });
          });
      }
    }
  };

  submitValidacion = (postData) => {
    const validationEditor =
      postData.contenido === "" || postData.contenido === "<p><br></p>";

    if (validationEditor) {
      const mensajeErrorSubmit = {
        error: true,
        mensaje: "Favor de agregar información",
      };
      this.setState({ mensajeErrorSubmit });
      return false;
    }

    return true;
  };

  // FIN EDITOR -------------------------------------------------------

  changeRowOrder = (id, up) => {
    Api.loading(true);
    const requestOptions = {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.token}`,
      },
    };

    fetch(
      `${Api.url}changeOrderRowRelElemento?id=${id}&pesId=${this.state.pestanaID}&up=${up}`,
      requestOptions
    )
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        }
        throw new Error(response.status);
      })
      .then((data) => {
        if (data) {
          this.setElementos();
          Api.loading(false);
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: "No tiene acceso a ésta información",
          icon: "error",
          showCancelButton: false,
          cancelButtonColor: "green",
          confirmButtonText: "Continuar",
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = "/admin/inicio";
          }
        });
      });
  };

  render() {
    return (
      <Page title={this.state.pestana.descripcion}>
        <Container>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ width: "100%" }}
          >
            <Typography variant="h4" gutterBottom>
              Pestaña | {this.state.pestana.descripcion}
            </Typography>
            <Box>
              <Button
                variant="contained"
                startIcon={<Icon icon={plusFill} />}
                onClick={() => this.handleOpen(true)}
              >
                Nuevo elemento
              </Button>
              <ModalInput
                title={title}
                elementos={this.state.elementosA}
                isOpen={this.state.modalShow}
                handleClose={this.handleClose}
                onHandleSubmit={this.handleAddElemento}
              />

              <NuevoElementoEditor
                title="texto informativo"
                isOpen={this.state.modalShowEditor}
                handleClose={() => this.handleCloseEditor()}
                onHandleSubmit={(e) => this.handleAddElementoEditar(e)}
                handleChangeEditor={(e) => this.handleChangeEditor(e)}
                editar={this.state.editarEditor}
                ver={this.state.verEditor}
                nuevo={this.state.nuevoEditor}
                contenido={this.state.elementoEditor.contenido}
                mensajeErrorSubmit={this.state.mensajeErrorSubmit}
              />
            </Box>
          </Stack>
          <Card>
            <Scrollbar>
              <TableContainer sx={{ minWidth: "100%" }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell key="changeRow" align="center">
                        Orden
                      </TableCell>
                      {tableHead.map((headCell, index) => (
                        <TableCell key={index} align="left">
                          {headCell.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.elementos.map((elemento, index) => (
                      <TableRow key={index}>
                        <TableCell align="left">
                          <Stack>
                            {index !== 0 ? (
                              <IconButton
                                size="small"
                                onClick={() =>
                                  this.changeRowOrder(elemento.id, true)
                                }
                              >
                                <Iconify icon="eva:arrow-ios-upward-outline" />
                              </IconButton>
                            ) : null}

                            {index !== this.state.elementos.length - 1 ? (
                              <IconButton
                                size="small"
                                onClick={() =>
                                  this.changeRowOrder(elemento.id, false)
                                }
                              >
                                <Iconify icon="eva:arrow-ios-downward-outline" />
                              </IconButton>
                            ) : null}
                          </Stack>
                        </TableCell>
                        <TableCell align="left">
                          {elemento.descripcion}
                        </TableCell>
                        <TableCell align="left">{elemento.elemento}</TableCell>
                        <TableCell align="left">
                          <Label
                            variant="ghost"
                            color={
                              (parseInt(elemento.status,10) === 0 && "error") || "success"
                            }
                          >
                            {elemento.status_descripcion}
                          </Label>
                        </TableCell>
                        <TableCell>
                          {parseInt(elemento.elemento_id,10) === 1 ? (
                            <IconButton
                              component={Link}
                              to={`/admin/carrusel/${elemento.id}`}
                            >
                              <Iconify icon="eva:external-link-fill" />
                            </IconButton>
                          ) : null}

                          {parseInt(elemento.elemento_id,10) === 2 ? (
                            <IconButton
                              component={Link}
                              to={`/admin/publicaciones/${elemento.id}`}
                            >
                              <Iconify icon="eva:external-link-fill" />
                            </IconButton>
                          ) : null}

                          {parseInt(elemento.elemento_id,10) === 3 ? (
                            <>
                              {/*  <IconButton id="Nuevo-b" onClick={(e) => this.handleOpenEditor(true)}>
                                <Iconify icon="eva:external-link-fill" />
                              </IconButton> */}

                              <IconButton
                                id="Ver-b"
                                onClick={(e) =>
                                  this.handleGetElementoEdit(
                                    e,
                                    elemento.id,
                                    false
                                  )
                                }
                              >
                                <Iconify id="Ver" icon="eva:eye-outline" />
                              </IconButton>
                              <IconButton
                                id="Editar-b"
                                onClick={(e) =>
                                  this.handleGetElementoEdit(
                                    e,
                                    elemento.id,
                                    true
                                  )
                                }
                              >
                                <Iconify
                                  id="Editar"
                                  icon="eva:edit-2-outline"
                                />
                              </IconButton>
                              {/* <IconButton onClick={() => this.handleStatusElemento(c.id)}>
                                {c.status === 1 ? (
                                  <Iconify icon="eva:close-fill" />
                                ) : (
                                  <Iconify icon="eva:checkmark-outline" />
                                )}
                                </IconButton> */}
                            </>
                          ) : null}

                          {parseInt(elemento.elemento_id,10) === 4 ? (
                            <IconButton
                              component={Link}
                              to={`/admin/timeline/${elemento.id}`}
                            >
                              <Iconify icon="eva:external-link-fill" />
                            </IconButton>
                          ) : null}

                          {parseInt(elemento.elemento_id,10) === 5 ? (
                            <IconButton
                              component={Link}
                              to={`/admin/resumen/${elemento.id}`}
                            >
                              <Iconify icon="eva:external-link-fill" />
                            </IconButton>
                          ) : null}

                          {parseInt(elemento.elemento_id,10) === 6 ? (
                            <IconButton
                              component={Link}
                              to={`/admin/galeria/${elemento.id}`}
                            >
                              <Iconify icon="eva:external-link-fill" />
                            </IconButton>
                          ) : null}
                          <IconButton
                            onClick={() =>
                              this.handleStatusElemento(elemento.id)
                            }
                          >
                            {parseInt(elemento.status,10) === 1 ? (
                              <Iconify icon="eva:close-fill" />
                            ) : (
                              <Iconify icon="eva:checkmark-outline" />
                            )}
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                  {this.state.notFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <Paper>
                            <Typography variant="body2" align="center">
                              No se encontraron resultados.
                            </Typography>
                          </Paper>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>
            {/*             <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              labelRowsPerPage="Renglones por página"
              labelDisplayedRows={({ from, to, count }) => `${from} - ${to} de ${count}`}
              count={this.state.total}
              rowsPerPage={this.state.rowsPerPage}
              page={this.state.page}
              onPageChange={this.handleChangePage}
              onRowsPerPageChange={this.handleChangeRowsPerPage}
            /> */}
          </Card>
        </Container>
      </Page>
    );
  }
}
export default (props) => <Layout {...props} params={useParams()} />;
