import * as React from 'react';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { DatePicker, LocalizationProvider } from '@mui/lab';
// material
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  Typography,
  TextField,
  IconButton
} from '@mui/material';
import Iconify from './Iconify';

class NuevoElementoTimeline extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
    this.windowWidth = window.innerWidth;
  }

  render() {
    return (
      <Dialog open={this.props.isOpen} onClose={(e) => this.props.handleClose(e)} fullWidth>
        <DialogTitle>
          {this.props.ver ? 'Ver' : ''} {this.props.editar ? 'Editar' : ''}{' '}
          {this.props.nuevo ? 'Nuevo' : ''} {this.props.title}
          <IconButton
            aria-label="close"
            onClick={(e) => this.props.handleClose(e)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}
          >
            <Iconify icon="eva:close-outline" />
          </IconButton>
        </DialogTitle>
        <form onSubmit={(e) => this.props.onHandleSubmit(e)}>
          <DialogContent>
            <Typography>
              Título<span style={{ color: 'red' }}>*</span>
            </Typography>
            <TextField
              id="titulo"
              placeholder="Título"
              maxRows={5}
              // error //como lo puedo obtener?
              multiline
              fullWidth
              required
              value={this.props.info.texto}
              disabled={this.props.ver}
              onChange={(e) => this.props.handleChangeValue(e)}
              inputProps={{ maxLength: '50' }}
            />

            <Typography>
              Fecha<span style={{ color: 'red' }}>*</span>
            </Typography>
            <TextField
              id="fecha"
              placeholder="Fecha"
              maxRows={5}
              // error //como lo puedo obtener?
              multiline
              fullWidth
              required
              value={this.props.info.fecha}
              disabled={this.props.ver}
              onChange={(e) => this.props.handleChangeValue(e)}
              inputProps={{ maxLength: '50' }}
            />

            {/*               
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography>
                Titulo<span style={{ color: 'red' }}>*</span>
              </Typography>
              <TextField
                id="titulo"
                placeholder="Titulo"
                maxRows={5}
                // error //como lo puedo obtener?
                multiline
                fullWidth
                required
                value={this.props.info.titulo}
                disabled={this.props.ver}
                onChange={(e) => this.props.handleChangeValue(e)}
                inputProps={{ maxLength: '50' }}
              />
            </Stack>
            <br />
                      <br />
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography>
              Fecha<span style={{ color: 'red' }}>*</span>
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                value={date}
                onChange={(newValue) => {
                  setDate(newValue);
                }}
                renderInput={(params) => <TextField {...params} />}
                required
                // maxDate={maxDate}
              />
            </LocalizationProvider>
          </Stack>
          <br /> */}
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="error" onClick={(e) => this.props.handleClose(e)}>
              {this.props.editar ? 'Cancelar' : 'Regresar'}
            </Button>

            {this.props.ver ? (
              <></>
            ) : (
              <Button variant="contained" type="submit">
                Guardar
              </Button>
            )}
          </DialogActions>
        </form>
      </Dialog>
    );
  }
}

export default NuevoElementoTimeline;
