import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { NavLink as RouterLink, matchPath, useLocation, Link } from 'react-router-dom';
// Icon
import { Icon } from '@iconify/react';
import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill';
// material
import { useTheme, styled } from '@mui/material/styles';
import {
  Box,
  List,
  ListItemText,
  ListItemButton,
  Stack,
  IconButton,
  Menu,
  MenuItem,
  Button,
  Typography
} from '@mui/material';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
// components
import MenuPopover from './MenuPopover';
import Iconify from './Iconify';
import { MHidden } from './@material-extend';

// ----------------------------------------------------------------------
const ITEM_HEIGHT = 48;

function LinkTab(props) {
  return <Tab component="a" {...props} />;
}

function LinkRows(props) {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event, aLink) => {
    event.stopPropagation();
    window.open(aLink, '_blank');
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        onClick={handleClick}
        sx={{ mr: 1, color: 'text.primary', display: { lg: 'none' } }}
      >
        <Iconify icon="eva:more-vertical-fill" color={props.textStyle} />
      </IconButton>

      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button'
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '20ch'
          }
        }}
      >
        {props.items.slice(0, 6).map((option) => (
          <MenuItem key={option.id}  onClick={(e) => handleClose(e, option.link)}>
            <Typography  style={props.textStyle} noWrap>{option.descripcion}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

NavbarLinks.propTypes = {
  ItemsLinks: PropTypes.array
};

export default function NavbarLinks({ ItemsLinks, ...other }) {
  const theme = useTheme();
  const textStyle = {
    color: theme.palette.primary.main
  };

  const backGroundStyle = 'background.paper';

  return (
    <div>
      <MHidden width="lgUp">
        <LinkRows items={ItemsLinks} textStyle={textStyle} />
      </MHidden>

      <MHidden width="lgDown">
        <Box
          sx={{
            flexGrow: 1,
            maxWidth: { xs: 320, sm: 450, md: 760, lg: 1000, xl: 1200 },
            bgcolor: backGroundStyle
            // color: theme.palette.primary.main
          }}
        >
          <Tabs
            variant="scrollable"
            // visibleScrollbar
            scrollButtons
            allowScrollButtonsMobile
            aria-label="visible arrows tabs example"
            sx={{
              [`& .${tabsClasses.scrollButtons}`]: {
                color: textStyle
              },
              [`& .${tabsClasses.indicator}`]: {
                backgroundColor: backGroundStyle
              }
            }}
            value={0}
          >
            {ItemsLinks.slice(0, 6).map((item) => (
              <LinkTab
                key={`Tab-${item.id}`}
                label={item.descripcion}
                href={item.link}
                target="_blank"
                rel="noreferrer"
                style={textStyle}
                wrapped
              />
            ))}
          </Tabs>
        </Box>
      </MHidden>
    </div>
  );
}
