import { Icon } from '@iconify/react';
import React, { useEffect, useState } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link } from 'react-router-dom';
// material
import {
  Box,
  Card,
  Table,
  Stack,
  Button,
  IconButton,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  Paper,
  TablePagination
} from '@mui/material';
import Swal from 'sweetalert2';
// components
import Label from '../components/Label';
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import Search from '../components/Search';
import ModalSelectMultiple from '../components/ModalSelectMultiple';
import ModalInput from '../components/ModalInput';
import ModalEdit from '../components/ModalEdit';
import Iconify from '../components/Iconify';
import { Api } from '../app-controller';

// ----------------------------------------------------------------------
const host = window.location.host.split(":")[0];

const tableHead = [
  { id: 'titulo', label: 'Carpeta', alignRight: false },
  { id: 'usuario', label: 'Creador', alignRight: false },
  { id: 'status', label: 'Estado', alignRight: false },
  { id: 'opciones', label: 'Opciones', alignRight: true }
];

const title = 'Nueva carpeta';

export default class Carpetas extends React.Component {
  constructor() {
    super();
    this.state = {
      carpetas: [],
      searchArray: [],
      carpetaID: null,
      elementosA: [],
      elementosE: [],
      users: [],
      select: [],
      nivel: null,
      modalShow: false,
      modalShowE: false,
      modalShowU: false,
      notFound: false,
      search: '',
      rowsPerPage: 5,
      page: 0,
      total: 0
    };
  }

  componentDidMount() {
    this.setCarpetas();
    this.setElementos();
    this.setCatalogos();
  }

  setCarpetas = (query = '') => {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.token}`
      }
    };

    fetch(`${Api.url}getCarpetas?${query}`, requestOptions)
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        }
        throw new Error(response.status);
      })
      .then((data) => {
        if (data) {
          const auxNotFound = data.carpetas.total === 0;

          this.setState({
            carpetas: data.carpetas.data,
            total: data.carpetas.total,
            page: data.carpetas.current_page - 1,
            notFound: auxNotFound,
            nivel: data.nivel
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          title: 'Error',
          text: 'No tiene acceso a ésta información',
          icon: 'error',
          showCancelButton: false,
          cancelButtonColor: 'green',
          confirmButtonText: 'Continuar',
          allowOutsideClick: false
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = '/admin/inicio';
          }
        });
      });
  };

  setCatalogos = () => {
    fetch(`${Api.url}getStatus`)
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          this.setSearch(data.statusArray);
        }
      });
  };

  setSearch = (statusArray) => {
    this.setState({
      searchArray: [
        {
          id: 'titulo',
          label: 'Carpeta',
          input: 'text'
        },
        {
          id: 'usuario',
          label: 'Creador',
          input: 'text'
        },
        {
          id: 'status',
          label: 'Estado',
          input: 'select',
          select: statusArray
        }
      ]
    });
  };

  setElementos = () => {
    this.setState({
      elementosA: [
        {
          id: 'titulo_M',
          label: 'Título',
          input: 'text',
          value: "",
          required: true,
          maxLength: 100
        }
      ]
    });
  };

  handleSearch = (search) => {
    let src = '';
    search.forEach((param) => {
      if (param.value !== undefined && param.value !== null && param.value !== '')
        src += `&${param.nombre}=${param.value}`;
    });

    this.setState({ search: src });
    this.setCarpetas(src);
  };

  handleChangeRowsPerPage = (event) => {
    const auxRowsPerPage = parseInt(event.target.value, 10);
    this.setState({ rowsPerPage: auxRowsPerPage, page: 0 });
    this.setCarpetas(`rowsPerPage=${auxRowsPerPage}${this.state.search}`);
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
    this.setCarpetas(
      `page=${newPage + 1}&rowsPerPage=${this.state.rowsPerPage}${this.state.search}`
    );
  };

  handleOpen = () => {
    this.setState({ modalShow: true });
  };

  handleClose = () => {
    this.setState({ modalShow: false });
  };

  handleOpenE = () => {
    this.setState({ modalShowE: true });
  };

  handleCloseE = () => {
    this.setState({ modalShowE: false, elementosE: [] });
  };

  handleOpenU = () => {
    this.setState({ modalShowU: true });
  };

  handleCloseU = () => {
    this.setState({ modalShowU: false });
  };

  handleAddLayout = (data) => {
    const postData = {};

    data.forEach((e) => {
      const newObject = {};
      newObject[e.nombre] = e.value;
      Object.assign(postData, newObject);
    });

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.token}`
      },
      body: JSON.stringify({ postData })
    };

    fetch(`${Api.url}createCarpeta`, requestOptions)
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        }
        throw new Error(response.status);
      })
      .then((data) => {
        this.handleClose();

        Swal.fire({
          text: data.message,
          allowOutsideClick: false
        });

        this.setCarpetas(); // Actualizo tabla de carpetas
      })
      .catch((error) => {
        Swal.fire({
          title: 'Error',
          text: 'No tiene acceso a ésta información',
          icon: 'error',
          showCancelButton: false,
          cancelButtonColor: 'green',
          confirmButtonText: 'Continuar',
          allowOutsideClick: false
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = '/admin/inicio';
          }
        });
      });
  };

  handleStatusCarpeta = (id) => {
    Swal.fire({
      icon: 'question',
      title: '¿Está seguro de realizar la acción?',
      showDenyButton: true,
      denyButtonColor: 'red',
      confirmButtonColor: '#4CD964',
      showCancelButton: false,
      confirmButtonText: `Continuar`,
      denyButtonText: `Regresar`,
      reverseButtons: true,
      allowOutsideClick: false
    }).then((result) => {
      if (result.isConfirmed) {
        const requestOptions = {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sessionStorage.token}`
          },
          body: JSON.stringify({ id })
        };

        fetch(`${Api.url}deactivateCarpeta`, requestOptions)
          .then((response) => {
            if (response.status === 200) {
              return response.json();
            }
            throw new Error(response.status);
          })
          .then((data) => {
            Swal.fire({
              text: data.message,
              allowOutsideClick: false
            });

            this.setCarpetas(); // Actualizo tabla de carpetas
          })
          .catch((error) => {
            Swal.fire({
              title: 'Error',
              text: 'No tiene acceso a ésta información',
              icon: 'error',
              showCancelButton: false,
              cancelButtonColor: 'green',
              confirmButtonText: 'Continuar',
              allowOutsideClick: false
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.href = '/admin/inicio';
              }
            });
          });
      }
    });
  };

  handleGetCarpetaEdit = (id) => {
    this.setState({ carpetaID: id }, this.setCarpeta(id));
    this.handleOpenE();
  };

  handleGetUsers = (id) => {
    this.setState({ carpetaID: id });

    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.token}`
      }
    };

    fetch(`${Api.url}getUsersCarpeta?id=${id}`, requestOptions)
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        }
        throw new Error(response.status);
      })
      .then((data) => {
        if (data) {
          this.setState({ users: data.users, select: data.select }, this.handleOpenU());
        }
      })
      .catch((error) => {
        Swal.fire({
          title: 'Error',
          text: 'No tiene acceso a ésta información',
          icon: 'error',
          showCancelButton: false,
          cancelButtonColor: 'green',
          confirmButtonText: 'Continuar',
          allowOutsideClick: false
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = '/admin/inicio';
          }
        });
      });
  };

  handleUsers = (data) => {
    const carpeta = this.state.carpetaID;

    const postData = {};
    Object.assign(postData, { carpeta_id: carpeta });
    Object.assign(postData, { usuarios: data });

    const requestOptions = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.token}`
      },
      body: JSON.stringify({ postData })
    };

    fetch(`${Api.url}updateCarpetaUsers`, requestOptions)
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        }
        throw new Error(response.status);
      })
      .then((data) => {
        this.handleCloseU();

        Swal.fire({
          text: data.message,
          allowOutsideClick: false
        });

        this.setCarpetas(); // Actualizo tabla de carpetas
      })
      .catch((error) => {
        Swal.fire({
          title: 'Error',
          text: 'No tiene acceso a ésta información',
          icon: 'error',
          showCancelButton: false,
          cancelButtonColor: 'green',
          confirmButtonText: 'Continuar',
          allowOutsideClick: false
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = '/admin/inicio';
          }
        });
      });
  };

  setCarpeta = (id) => {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.token}`
      }
    };

    fetch(`${Api.url}getCarpeta?id=${id}`, requestOptions)
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        }
        throw new Error(response.status);
      })
      .then((data) => {
        if (data) {
          this.setState({
            elementosE: [
              {
                id: 'titulo_M',
                label: 'Título',
                input: 'text',
                required: true,
                value: data.carpeta.titulo,
                upperCase: true,
                maxLength: 100
              }
            ]
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          title: 'Error',
          text: 'No tiene acceso a ésta información',
          icon: 'error',
          showCancelButton: false,
          cancelButtonColor: 'green',
          confirmButtonText: 'Continuar',
          allowOutsideClick: false
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = '/admin/inicio';
          }
        });
      });
  };

  handleUpdateCarpeta = (data) => {
    // const theme = useTheme();
    const postData = {};
    Object.assign(postData, { id: this.state.carpetaID });

    data.forEach((e) => {
      const newObject = {};
      newObject[e.nombre] = e.value;
      Object.assign(postData, newObject);
    });

    const requestOptions = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.token}`
      },
      body: JSON.stringify({ postData })
    };

    fetch(`${Api.url}updateCarpeta`, requestOptions)
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        }
        throw new Error(response.status);
      })
      .then((data) => {
        this.handleCloseE();

        Swal.fire({
          text: data.message,
          allowOutsideClick: false
        });

        this.setCarpetas(); // Actualizo tabla de carpetas
        this.setCarpeta(this.state.carpetaID);
      })
      .catch((error) => {
        Swal.fire({
          title: 'Error',
          text: 'No tiene acceso a ésta información',
          icon: 'error',
          showCancelButton: false,
          cancelButtonColor: 'green',
          confirmButtonText: 'Continuar',
          allowOutsideClick: false
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = '/admin/inicio';
          }
        });
      });
  };

  render() {
    return (
      <Page title="Carpetas">
        <Container>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ width: '100%' }}
          >
            <Typography variant="h4" gutterBottom>
              Carpetas
            </Typography>
            <Box>
              {parseInt(this.state.nivel,10) === 1 ? (
                <Button
                  variant="contained"
                  startIcon={<Icon icon={plusFill} />}
                  onClick={() => this.handleOpen()}
                >
                  Nueva carpeta
                </Button>
              ) : null}

              <ModalInput
                title={title}
                elementos={this.state.elementosA}
                isOpen={this.state.modalShow}
                handleClose={this.handleClose}
                onHandleSubmit={this.handleAddLayout}
              />
              <ModalEdit
                title="Editar carpeta"
                elementos={this.state.elementosE}
                isOpen={this.state.modalShowE}
                handleClose={this.handleCloseE}
                onHandleSubmit={this.handleUpdateCarpeta}
              />
              <ModalSelectMultiple
                title="Usuarios con acceso"
                usuarios={this.state.users}
                seleccionados={this.state.select}
                isOpen={this.state.modalShowU}
                handleClose={this.handleCloseU}
                onHandleSubmit={this.handleUsers}
              />
            </Box>
          </Stack>
          <Box sx={{ my: 2 }}>
            <Search onHandleSearch={this.handleSearch} elementos={this.state.searchArray} />
          </Box>
          <Card>
            <Scrollbar>
              <TableContainer sx={{ minWidth: '100%' }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      {tableHead.map((headCell, index) => (
                        <TableCell key={index} align="left">
                          {headCell.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.carpetas.map((carpeta, index) => (
                      <TableRow key={index}>
                        <TableCell align="left">{carpeta.titulo}</TableCell>
                        <TableCell align="left">{carpeta.nombre}</TableCell>
                        <TableCell align="left">
                          <Label
                            variant="ghost"
                            color={(parseInt(carpeta.status,10) === 0 && 'error') || 'success'}
                          >
                            {carpeta.status_descripcion}
                          </Label>
                        </TableCell>
                        <TableCell>
                          <IconButton component={Link} to={`/admin/carpeta/${carpeta.id}`}>
                            <Iconify icon="eva:external-link-fill" />
                          </IconButton>
                          {parseInt(this.state.nivel,10) === 1? (
                            <>
                              <IconButton onClick={() => this.handleGetUsers(carpeta.id)}>
                                <Iconify icon="ph:users-bold" />
                              </IconButton>
                              <IconButton onClick={() => this.handleGetCarpetaEdit(carpeta.id)}>
                                <Iconify icon="eva:edit-2-outline" />
                              </IconButton>
                              <IconButton onClick={() => this.handleStatusCarpeta(carpeta.id)}>
                                {parseInt(carpeta.status,10) === 1 ? (
                                  <Iconify icon="eva:close-fill" />
                                ) : (
                                  <Iconify icon="eva:checkmark-outline" />
                                )}
                              </IconButton>
                            </>
                          ) : null}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                  {this.state.notFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <Paper>
                            <Typography variant="body2" align="center">
                              No se encontraron resultados.
                            </Typography>
                          </Paper>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              labelRowsPerPage="Renglones por página"
              labelDisplayedRows={({ from, to, count }) => `${from} - ${to} de ${count}`}
              component="div"
              count={this.state.total}
              rowsPerPage={this.state.rowsPerPage}
              page={this.state.page}
              onPageChange={this.handleChangePage}
              onRowsPerPageChange={this.handleChangeRowsPerPage}
            />
          </Card>
        </Container>
      </Page>
    );
  }
}
