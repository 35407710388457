import { Icon } from "@iconify/react";
import React from "react";
import plusFill from "@iconify/icons-eva/plus-fill";
// material
import {
  Box,
  Card,
  Table,
  Stack,
  Button,
  IconButton,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  Paper,
  TablePagination,
} from "@mui/material";
import Swal from "sweetalert2";
// components
import Label from "../components/Label";
import Page from "../components/Page";
import Scrollbar from "../components/Scrollbar";
import Search from "../components/Search";
import ModalInput from "../components/ModalInput";
import ModalEdit from "../components/ModalEdit";
import Iconify from "../components/Iconify";
import { Api } from "../app-controller";

// ----------------------------------------------------------------------
const host = window.location.host.split(":")[0];

const tableHead = [
  { id: "name", label: "Nombre", alignRight: false },
  { id: "email", label: "Correo Electrónico", alignRight: false },
  { id: "level", label: "Nivel", alignRight: false },
  { id: "status", label: "Estado", alignRight: false },
  { id: "" },
];

const title = "Nuevo usuario";
let titleE = "";

// ----------------------------------------------------------------------

export default class User extends React.Component {
  constructor() {
    super();
    this.state = {
      users: [],
      searchArray: [],
      levelsArray: [],
      userID: null,
      elementosA: [],
      elementosE: [],
      modalShow: false,
      modalShowE: false,
      modalView: false,
      notFound: false,
      search: "",
      rowsPerPage: 5,
      page: 0,
      total: 0,
    };

    this.handleCloseE = this.handleCloseE.bind(this);
    this.handleOpenE = this.handleOpenE.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
  }

  componentDidMount() {
    this.setUsers();
    this.setCatalogos();
  }

  setUsers = (query = "") => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.token}`,
      },
    };

    fetch(`${Api.url}getUsers?${query}`, requestOptions)
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        }
        throw new Error(response.status);
      })
      .then((data) => {
        const auxNotFound = data.total === 0;

        this.setState({
          users: data.data,
          total: data.total,
          page: data.current_page - 1,
          notFound: auxNotFound,
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: "No tiene acceso a ésta información",
          icon: "error",
          showCancelButton: false,
          cancelButtonColor: "green",
          confirmButtonText: "Continuar",
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = "/admin/inicio";
          }
        });
      });
  };

  setCatalogos = () => {
    fetch(`${Api.url}getStatus`)
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          this.setSearch(data.statusArray);
        }
      });

    fetch(`${Api.url}getLevels`)
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          this.setElementos(data.levels);
          this.setState({ levelsArray: data.levels });
        }
      });
  };

  setSearch = (statusArray) => {
    this.setState({
      searchArray: [
        {
          id: "nombre",
          label: "Nombre",
          input: "text",
        },
        {
          id: "email",
          label: "Correo electrónico",
          input: "text",
        },
        {
          id: "status",
          label: "Estado",
          input: "select",
          select: statusArray,
        },
      ],
    });
  };

  setElementos = (arrayLevel) => {
    this.setState({
      elementosA: [
        {
          id: "nombre_M",
          label: "Nombre",
          input: "text",
          required: true,
          maxLength: 60,
          value: "",
          upperCase: false,
          // error: false
        },
        {
          id: "apellido_paterno_M",
          label: "Apellido paterno",
          input: "text",
          required: true,
          maxLength: 60,
          value: "",
          upperCase: false,
          // error: false
        },
        {
          id: "apellido_materno_M",
          label: "Apellido materno",
          input: "text",
          required: true,
          maxLength: 60,
          value: "",
          upperCase: false,
          // error: false
        },
        {
          id: "telefono_M",
          label: "Teléfono",
          input: "number",
          labelDefault: "Telefono",
          required: true,
          maxLength: 10,
          value: "",
          // error: false
        },
        {
          id: "email_M",
          label: "Correo electrónico",
          input: "email",
          required: true,
          maxLength: 100,
          value: "",
          // error: false
        },
        {
          id: "password_M",
          label: "Contraseña",
          input: "password",
          required: true,
          maxLength: 30,
          value: "",
          // error: false
        },
        {
          id: "fecha_nacimiento_M",
          label: "Fecha de nacimiento",
          input: "date",
          required: true,
          // error: false
        },
        {
          id: "nivel_M",
          label: "Nivel",
          input: "select",
          select: arrayLevel,
          required: true,
          // error: false
        },
      ],
    });
  };

  handleSearch = (search) => {
    let src = "";
    search.forEach((param) => {
      if (
        param.value !== undefined &&
        param.value !== null &&
        param.value !== ""
      )
        src += `&${param.nombre}=${param.value}`;
    });

    this.setState({ search: src });
    this.setUsers(src);
  };

  handleChangeRowsPerPage = (event) => {
    const auxRowsPerPage = parseInt(event.target.value, 10);
    this.setState({ rowsPerPage: auxRowsPerPage, page: 0 });
    this.setUsers(`rowsPerPage=${auxRowsPerPage}${this.state.search}`);
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
    this.setUsers(
      `page=${newPage + 1}&rowsPerPage=${this.state.rowsPerPage}${
        this.state.search
      }`
    );
  };

  handleOpen = () => {
    this.setState({ modalShow: true });
  };

  handleClose = () => {
    this.setState({ modalShow: false });
    this.setElementos(this.state.levelsArray);
  };

  handleOpenE = () => {
    this.setState({ modalShowE: true, modalView: false });
    titleE = "Editar usuario";
  };

  handleOpenV = () => {
    this.setState({ modalShowE: true });
    titleE = "Ver usuario";
  };

  handleCloseE = () => {
    this.setState({ modalShowE: false, modalView: false, elementosE: [] });
  };

  handleAddNewUser = (data) => {
    const postData = {};

    data.forEach((e) => {
      const newObject = {};
      newObject[e.nombre] = e.value;
      Object.assign(postData, newObject);
    });

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.token}`,
      },
      body: JSON.stringify({ postData }),
    };

    fetch(`${Api.url}createUser`, requestOptions)
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        }

        throw new Error(response.status);
      })
      .then((data) => {
        this.handleClose();

        if (data.error) {
          Swal.fire({
            text: data.message,
            icon: "error",
            allowOutsideClick: false,
          });
        }else{
          Swal.fire({
            text: data.message,
            allowOutsideClick: false,
          });
        }

        this.setUsers(); // Actualizo tabla de usuarios
        this.setElementos(this.state.levelsArray);
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: "No tiene acceso a ésta información",
          icon: "error",
          showCancelButton: false,
          cancelButtonColor: "green",
          confirmButtonText: "Continuar",
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = "/admin/inicio";
          }
        });
      });
  };

  handleStatusUser = (id) => {
    Swal.fire({
      icon: "question",
      title: "¿Está seguro de realizar la acción?",
      showDenyButton: true,
      denyButtonColor: "red",
      confirmButtonColor: "#4CD964",
      showCancelButton: false,
      confirmButtonText: `Continuar`,
      denyButtonText: `Regresar`,
      reverseButtons: true,
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        const requestOptions = {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.token}`,
          },
          body: JSON.stringify({ id }),
        };

        fetch(`${Api.url}deactivateUser`, requestOptions)
          .then((response) => {
            if (response.status === 200) {
              return response.json();
            }
            throw new Error(response.status);
          })
          .then((data) => {
            Swal.fire({
              text: data.message,
              allowOutsideClick: false,
            });

            this.setUsers(); // Actualizo tabla de usuarios
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              text: "No tiene acceso a ésta información",
              icon: "error",
              showCancelButton: false,
              cancelButtonColor: "green",
              confirmButtonText: "Continuar",
              allowOutsideClick: false,
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.href = "/admin/inicio";
              }
            });
          });
      }
    });
  };

  handleGetUserEdit = (id) => {
    this.setState({ userID: id }, this.setUser(id), this.handleOpenE());
  };

  handleGetUserView = (id) => {
    this.setState(
      { userID: id, modalView: true },
      this.setUser(id),
      this.handleOpenV()
    );
  };

  setUser = (id) => {
    const levels = this.state.levelsArray;

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.token}`,
      },
    };

    fetch(`${Api.url}getUser?id=${id}`, requestOptions)
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        }
        throw new Error(response.status);
      })
      .then((data) => {
        if (data) {
          this.setState({
            elementosE: [
              {
                id: "nombre_M",
                label: "Nombre",
                input: "text",
                required: true,
                maxLength: 60,
                value: data.user.nombre,
                upperCase: false,
              },
              {
                id: "apellido_paterno_M",
                label: "Apellido paterno",
                input: "text",
                required: true,
                maxLength: 60,
                value: data.user.apellido_paterno,
                upperCase: false,
              },
              {
                id: "apellido_materno_M",
                label: "Apellido materno",
                input: "text",
                required: true,
                maxLength: 60,
                // error: false
                value: data.user.apellido_materno,
                upperCase: false,
              },
              {
                id: "telefono_M",
                label: "Teléfono",
                input: "number",
                required: true,
                maxLength: 10,
                // error: false
                value: data.user.telefono,
              },
              {
                id: "email_M",
                label: "Correo electrónico",
                input: "email",
                required: true,
                maxLength: 100,
                value: data.user.email,
                // error: false
              },
              {
                id: "fecha_nacimiento_M",
                label: "Fecha de nacimiento",
                input: "date",
                required: true,
                value: data.user.fecha_nacimiento,
                // error: false
              },
              {
                id: "nivel_id_M",
                label: "Nivel",
                input: "select",
                select: levels,
                required: true,
                value: data.user.nivel_id,
                // error: false
              },
            ],
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: "No tiene acceso a ésta información",
          icon: "error",
          showCancelButton: false,
          cancelButtonColor: "green",
          confirmButtonText: "Continuar",
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = "/admin/inicio";
          }
        });
      });
  };

  handleUpdateUser = (data) => {
    // const theme = useTheme();
    const postData = {};
    Object.assign(postData, { id: this.state.userID });

    data.forEach((e) => {
      const newObject = {};
      newObject[e.nombre] = e.value;
      Object.assign(postData, newObject);
    });

    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.token}`,
      },
      body: JSON.stringify({ postData }),
    };

    fetch(`${Api.url}updateUser`, requestOptions)
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        }
        throw new Error(response.status);
      })
      .then((data) => {
        this.handleCloseE();

        Swal.fire({
          text: data.message,
          allowOutsideClick: false,
        });

        this.setUsers(); // Actualizo tabla de usuarios
        this.setUser(this.state.userID);
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: "No tiene acceso a ésta información",
          icon: "error",
          showCancelButton: false,
          cancelButtonColor: "green",
          confirmButtonText: "Continuar",
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = "/admin/inicio";
          }
        });
      });
  };

  render() {
    return (
      <Page title="Usuarios">
        <Container>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ width: "100%" }}
          >
            <Typography variant="h4" gutterBottom>
              Usuarios
            </Typography>
            <Box>
              <Button
                variant="contained"
                startIcon={<Icon icon={plusFill} />}
                onClick={() => this.handleOpen()}
              >
                Nuevo usuario
              </Button>
              <ModalInput
                title={title}
                elementos={this.state.elementosA}
                isOpen={this.state.modalShow}
                handleClose={this.handleClose}
                onHandleSubmit={this.handleAddNewUser}
              />
              <ModalEdit
                title={titleE}
                elementos={this.state.elementosE}
                isOpen={this.state.modalShowE}
                handleClose={this.handleCloseE}
                onHandleSubmit={this.handleUpdateUser}
                modalView={this.state.modalView}
              />
            </Box>
          </Stack>
          <Box sx={{ my: 2 }}>
            <Search
              onHandleSearch={this.handleSearch}
              elementos={this.state.searchArray}
            />
          </Box>
          <Card>
            <Scrollbar>
              <TableContainer sx={{ minWidth: "100%" }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      {tableHead.map((headCell, index) => (
                        <TableCell key={index} align="left">
                          {headCell.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.users.map((user, index) => (
                      <TableRow key={index}>
                        <TableCell align="left">{user.nombre}</TableCell>
                        <TableCell align="left">{user.email}</TableCell>
                        <TableCell align="left">{user.nivel}</TableCell>
                        <TableCell align="left">
                          <Label
                            variant="ghost"
                            color={(parseInt(user.status,10) === 0 && "error") || "success"}
                          >
                            {user.status_descripcion}
                          </Label>
                        </TableCell>
                        <TableCell>
                          <IconButton
                            onClick={() =>
                              this.handleGetUserView(user.usuario_id)
                            }
                          >
                            <Iconify icon="eva:eye-outline" />
                          </IconButton>
                          <IconButton
                            onClick={() =>
                              this.handleGetUserEdit(user.usuario_id)
                            }
                          >
                            <Iconify icon="eva:edit-2-outline" />
                          </IconButton>
                          <IconButton
                            onClick={() =>
                              this.handleStatusUser(user.usuario_id)
                            }
                          >
                            {parseInt(user.status,10) === 1 ? (
                              <Iconify icon="eva:close-fill" />
                            ) : (
                              <Iconify icon="eva:checkmark-outline" />
                            )}
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                  {this.state.notFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <Paper>
                            <Typography variant="body2" align="center">
                              No se encontraron resultados.
                            </Typography>
                          </Paper>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              labelRowsPerPage="Renglones por página"
              labelDisplayedRows={({ from, to, count }) =>
                `${from} - ${to} de ${count}`
              }
              count={this.state.total}
              rowsPerPage={this.state.rowsPerPage}
              page={this.state.page}
              onPageChange={this.handleChangePage}
              onRowsPerPageChange={this.handleChangeRowsPerPage}
            />
          </Card>
        </Container>
      </Page>
    );
  }
}
