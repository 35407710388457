import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const MenuCoordinadorConfig = [
  {
    id: 1,
    title: 'Inicio',
    path: '/admin/inicio',
    icon: getIcon('eva:home-outline')
  },
  {
    id: 10,
    title: 'Documentos',
    path: '/admin/carpetas',
    icon: getIcon('eva:folder-outline')
  }
];



export default MenuCoordinadorConfig;
