import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
// Icon
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
// material
import { styled, useTheme } from '@mui/material/styles';
import { Box, Stack, Popover, ListItemButton, ListItemText, Typography } from '@mui/material';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Api } from '../../app-controller';
import { MHidden } from '../../components/@material-extend';

// --------------------------------------------------------------------
const host = window.location.host.split(':')[0];

const ListItemStyleSubMenu = styled((props) => <ListItemButton disableGutters {...props} />)(
  ({ theme }) => ({
    ...theme.typography.body2,
    height: 48,
    position: 'relative',
    textTransform: 'capitalize',
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(2.5),
    color: theme.palette.primary.contrastText,
    borderBottom: `1px solid ${theme.palette.primary.contrastText}`,
    '&:before': {
      top: 0,
      right: 0,
      width: 3,
      bottom: 0,
      content: "''",
      display: 'none',
      position: 'absolute',
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 4,
      backgroundColor: theme.palette.primary.main
    }
  })
);

// --------------------------------------------------------------------

LinkTab.propTypes = {
  item: PropTypes.object
};

function LinkTab({ item, ...props }) {
  const { id, descripcion, links } = item;
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const anchorRef = useRef(null);

  const handleOpen = (event) => {
    event.stopPropagation();
    setOpen((prev) => !prev);
  };

  const handleClose = (event) => {
    event.stopPropagation();
    setOpen(false);
  };

  if (links.length !== 0) {
    return (
      <>
        <Tab
          key={id}
          icon={open ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
          iconPosition="end"
          onClick={handleOpen}
          ref={anchorRef}
          {...props}
        />

        <Popover
          open={open}
          onClose={handleClose}
          anchorEl={anchorRef.current}
          anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
          transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          PaperProps={{
            sx: {
              ml: 1,
              overflow: 'inherit',
              boxShadow: '#fff',
              border: `solid 1px #fff`,
              borderRadius: '0px',
              bgcolor: theme.palette.primary.main,
              width: '325px '
            }
          }}
        >
          {links.map((subTab) => (
            <ListItemStyleSubMenu
              key={`SubTab-${subTab.id}`}
              component="a"
              href={subTab.link}
              target="_blank"
              rel="noreferrer"
              onClick={handleClose}
            >
              <ListItemText
                disableTypography
                primary={
                  subTab.descripcion.length > 38
                    ? `${subTab.descripcion.substr(0, 38)}...`
                    : subTab.descripcion
                }
                color="white"
              />
            </ListItemStyleSubMenu>
          ))}
        </Popover>
      </>
    );
  }

  return (
    <Tab
      component="a"
      // href={item.a_link}
      // target="_blank"
      // rel="noreferrer"
      // style={textStyle}
      {...props}
    />
  );
}
// --------------------------------------------------------------------
StackLinks.propTypes = {
  footer: PropTypes.object
};

function StackLinks({ footer }) {
  return (
    <Stack alignItems="center">
      <Box
        sx={{
          flexGrow: 1,
          maxWidth: { xs: 320, sm: 560, md: 800, lg: 1000, xl: 1200 }
          /* bgcolor: theme.palette.primary.main */
        }}
      >
        <Tabs
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          aria-label="visible arrows tabs example"
          sx={{
            [`& .${tabsClasses.scrollButtons}`]: {
              color: 'white'
            },
            [`& .${tabsClasses.indicator}`]: {
              backgroundColor: 'white'
            }
          }}
          value={0}
        >
          {footer.map((item) => (
            <LinkTab
              key={`Tab-${item.id}`}
              label={item.descripcion}
              item={item}
              sx={{
                fontSize: '0.9em'
              }}
              style={{ color: 'white' }}
              wrapped
            />
          ))}
        </Tabs>
      </Box>
    </Stack>
  );
}
// --------------------------------------------------------------------
export default class Footer extends React.Component {
  constructor() {
    super();
    this.state = {
      footer: [],
      titulo: '',
      color:
        localStorage && localStorage.getItem('color')
          ? JSON.parse(localStorage.getItem('color'))
          : '#00AB55'
    };
  }

  componentDidMount() {
    fetch(`${Api.url}getFooter`)
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          this.setState({ footer: data.footer });
        }
      });

    fetch(`${Api.url}getNombrePagina`)
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          this.setState({ titulo: data.titulo });
        }
      });
  }

  render() {
    return (
      <Box backgroundColor={this.state.color}>
        {/* Stack que aparece cuando esta chico */}
        <MHidden width="lgUp">
          <StackLinks footer={this.state.footer} />
          <Box margin="1%">
            <Typography variant="caption" color="white">
              {this.state.titulo}
            </Typography>
          </Box>
        </MHidden>
        {/* Stack que aparece cuando esta grande */}
        <MHidden width="lgDown">
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Box margin="1%">
              <Typography variant="caption" color="white">
                {this.state.titulo}
              </Typography>
            </Box>
            <StackLinks footer={this.state.footer} />
          </Stack>
        </MHidden>
      </Box>
    );
  }
}
