import React from 'react';
import Swal from 'sweetalert2';

export const Api = React.createContext({});

let tok = '';

// BUX START Para mejorar la experiencia del usuario, podemos bloquear todo
// cuando sea necesario Api.loading(true) o Api.loading(false);
Api.loading = (bool) => {
  if (bool) {
    Swal.fire({
      title: 'Espere por favor...',
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });
  } else {
    Swal.close();
  }
};

// Fetch
// const host = window.location.host;
const {protocol, host} = window.location;
Api.protocol = protocol;
Api.url = `${protocol}//${host}/server/server.php/api/`;
Api.canFetch = true;
if (sessionStorage.sesion) {
  if (sessionStorage.sesion !== 'undefined') {
    Api.user_profile = JSON.parse(sessionStorage.sesion);
  }
}

// Api fetch recibe un servicio (url en API de laravel), los parameteros a
// enviar si se necesita (por default esta vacio) y el tipo de llamada (REST.
// Por default esta es get)
Api.fetch = async (service, params, metodo = 'GET') => {
  if (!Api.canFetch) {
    return false;
  }

  /*  if (metodo === "GET") {
    if (sessionStorage.nivel_actual) {
      if (service.indexOf("?") > 0) {
        service += "&nivel_actual=" + sessionStorage.nivel_actual;
      } else {
        service += "?nivel_actual=" + sessionStorage.nivel_actual;
      }
    }
  } else {
    if (sessionStorage.nivel_actual) {
      if (!params) {
        params = {};
      }
      params["nivel_actual"] = sessionStorage.nivel_actual;
    }
  } */

  // aqui se construye la respuesta, donde se toma el metodo dado en el fetch
  // (POST,GET, etc), el body/cuerpo (si se envia informacion al api) y los
  // headers

  const response = await fetch(Api.url + service, {
    method: metodo,
    body: params && JSON.stringify(params),
    headers: {
      'Content-Type': 'application/json',
      accept: 'application/json',
      Authorization: `Bearer${sessionStorage.token}`
    }
  });
  /*  todo esto servira cuando ya esta logeado */

  if (!response.ok) {
    if (response.status === 400) {
      sessionStorage.clear();
      Api.loading(0);
      Api.canFetch = true;
    }

    if (response.status === 401) {
      Api.canFetch = false;

      console.log('error 401');

      if (service !== 'auth/login') {
        sessionStorage.clear();
        Api.canFetch = false;
        Swal.fire({
          title: 'Error',
          text: 'Tu sesión ha expirado, por favor accede de nuevo.',
          icon: 'error',
          showCancelButton: false,
          cancelButtonColor: 'green',
          confirmButtonText: 'Continuar',
          allowOutsideClick: false
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = 'login';
          }
        });
      } else {
        Swal.fire({
          title: 'Error',
          text: 'Usuario y/o contraseña inválidos.',
          icon: 'error',
          showCancelButton: false,
          cancelButtonColor: 'green',
          confirmButtonText: 'Continuar',
          allowOutsideClick: false
        });

        sessionStorage.clear();
        Api.canFetch = true;
        return false;
      }
    }

    if (response.status === 500) {
      Swal.fire({
        title: 'Error de conexión a internet.',
        text: 'Por favor intente de nuevo su última acción.',
        icon: 'error',
        showCancelButton: false,
        cancelButtonColor: 'green',
        confirmButtonText: 'Continuar',
        allowOutsideClick: false
      });

      Api.canFetch = false;
      return false;
    }

    if (response.status !== 422) {
      throw response;
    } else {
      return response.json();
    }
  } else {
    return response.json();
  }
};

Api.xhr = (service, params, callBack) => {
  const http = new XMLHttpRequest();
  const { url } = Api;

  http.open('POST', url + service, true);

  http.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
  http.setRequestHeader('accept', 'application/json');

  if (tok) {
    http.setRequestHeader('Authorization', `Bearer ${tok}`);
    console.log(tok);
  }

  http.onreadystatechange = function () {
    // Call a function when the state changes.

    /*     if (http.status === 401 && http.readyState === 2) {
      if (!Api.auth.avoid.includes(window.location.pathname.split('/')[1])) {
        sessionStorage.clear();
        window.location.href = '/login';
        return false;
      }
    } */

    if (http.status === 401 && http.readyState === 2) {
      // supongo que este if es el mismo de arriba
      const pathP = window.location.pathname.split('/')[1];

      if (pathP === 'login' && Api.auth.avoid.includes(pathP)) {
        Swal.fire({
          title: 'Error',
          text: 'Usuario y/o contraseña inválidos.',
          icon: 'error',
          showCancelButton: false,
          cancelButtonColor: 'green',
          confirmButtonText: 'Continuar',
          allowOutsideClick: false
        }).then(() => {
          sessionStorage.clear();
          window.location.href = '/login';
        });
        return false;
      }
      
      sessionStorage.clear();
      window.location.href = '/login';
    }

    if (http.readyState === 4 && http.status === 200) {
      callBack(JSON.parse(http.responseText));
    }

    /* let tplErrors = "";
      if (http.readyState === 4 && http.status === 422) {
        const error = JSON.parse(http.responseText);
        error.errors.map((e, index) => {
           
            error.errors[index][0] = error.errors[index][0].replace("_", " "); // .replace("id","");
            tplErrors += `<li>${error.errors[index][0]}</li>`;
        });
        /* for (const e in error.errors) {
          if (error.errors[e][0] !== undefined) {
            error.errors[e][0] = error.errors[e][0].replace("_", " "); // .replace("id","");
            tplErrors += `<li>${error.errors[e][0]}</li>`;
          }
        } *
        Swal.fire({
          title: "Error al procesar",
          html: `<ul style="text-align:left">${tplErrors}</ul>`,
          icon: "error",
          showCancelButton: false,
          confirmButtonText: "Continuar",
          allowOutsideClick: false
        });
      } */
  };

  let prms = '';
  if (params && typeof params === 'object') {
    Object.entries(params).forEach(([key, value]) => {
      prms += `${key}=${value}&`;
    });
  } else {
    prms = params;
  }

  http.onerror = function (e) {
    console.log(`Error Status: ${e.target.status}`);
  };

  http.send(prms);
};

Api.auth = {
  token: /* sessionStorage.token */ tok,
  avoid: ['login', 'register', 'confirm', 'confirmed', 'password-request', 'password-reset'],
  setToken: (token) => {
    sessionStorage.token = token;
    Api.auth.token = token;
  },
  check: () => {
    if (!Api.auth.token) {
      if (!Api.auth.avoid.includes(window.location.pathname.split('/')[1])) {
        window.location.href = '/login';
      }
    } else {
      Api.auth.profile((response) => {
        if (!Api.auth.avoid.includes(window.location.pathname.split('/')[1])) {
          if (!response.email_verified_at) {
            Api.user_profile = response;
            sessionStorage.sesion = JSON.stringify(response);
            window.location.href = '/confirm';
          }
        }
      });
    }
  },
  login: (frmData, callBack) => {
    Api.xhr('auth/login', frmData, (response) => {
      Api.loading(1);
      callBack(response);
    });
  },
  logout: () => {
    Api.xhr('auth/logout', null, (r) => {
      sessionStorage.clear();
      window.location.href = '/login';
    });
  },
  profile: (callBack, tokenAux) => {
    tok = tokenAux;
    if (!Api.auth.avoid.includes(window.location.pathname.split('/')[1])) {
      Api.xhr('auth/profile', null, (r) => {
        console.log(r);
        sessionStorage.setItem('nivel', r.nivel);

        window.location.href = '/admin/inicio';
        callBack(r);
      });
    }
  }
};
