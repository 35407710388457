import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import plusFill from "@iconify/icons-eva/plus-fill";
// material
import {
  Box,
  Card,
  Table,
  Stack,
  Button,
  IconButton,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  Paper,
  TablePagination,
} from "@mui/material";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";
// components
import Label from "../components/Label";
import Page from "../components/Page";
import Scrollbar from "../components/Scrollbar";
import Iconify from "../components/Iconify";
import NuevoElementoTimeline from "../components/NuevoElementoTimeline";
import { Api } from "../app-controller";

// ----------------------------------------------------------------------
const host = window.location.host.split(":")[0];

const tableHead = [
  { id: "texto", label: "Contenido", alignRight: false },
  { id: "status", label: "Estado", alignRight: false },
  { id: "opciones", label: "Opciones", alignRight: true },
];

const title = "Nuevo";

class TimelineAdmin extends React.Component {
  constructor(props) {
    super();
    const { id } = props.params;

    this.inicialState = {
      elementoInfo: {
        fecha: "",
        texto: "",
      },
    };

    this.state = {
      timeline: [],
      elementoInfo: [],
      rpeInfo: [],
      rpeID: id,
      notFound: false,
      modalShow: false,
      editar: false,
      ver: false,
      nuevo: false,
    };
  }

  componentDidMount() {
    this.setElementos();
  }

  setElementos = () => {
    const id = this.state.rpeID;

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.token}`,
      },
    };

    fetch(
      `${Api.url}getElementosTimeline?id=${id}`,
      requestOptions
    )
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        }
        throw new Error(response.status);
      })
      .then((data) => {
        if (data) {
          const auxNotFound = data.timeline.length === 0;

          this.setState({
            timeline: data.timeline,
            rpeInfo: data.rpeInfo,
            notFound: auxNotFound,
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: "No tiene acceso a ésta información",
          icon: "error",
          showCancelButton: false,
          cancelButtonColor: "green",
          confirmButtonText: "Continuar",
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = "/admin/inicio";
          }
        });
      });
  };

  handleOpen = (e) => {
    if (e) this.setState({ nuevo: true });
    this.setState({ modalShow: true });
  };

  handleClose = () => {
    this.setState({
      modalShow: false,
      nuevo: false,
      editar: false,
      ver: false,
      elementoInfo: this.inicialState.elementoInfo,
    });
  };

  handleAddElemento = (e) => {
    e.preventDefault();

    const postData = this.state.elementoInfo;
    postData.rpeID = this.state.rpeID;

    if (this.state.nuevo) {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.token}`,
        },
        body: JSON.stringify(postData),
      };

      fetch(`${Api.url}createElementoTimeline`, requestOptions)
        .then((response) => {
          if (response.status === 200) {
            return response.json();
          }
          throw new Error(response.status);
        })
        .then((data) => {
          Swal.fire({
            text: data.message,
            allowOutsideClick: false,
          });
          this.handleClose();
          this.setElementos(); // Actualizo tabla de elementos
        })
        .catch((error) => {
          Swal.fire({
            title: "Error",
            text: "No tiene acceso a ésta información",
            icon: "error",
            showCancelButton: false,
            cancelButtonColor: "green",
            confirmButtonText: "Continuar",
            allowOutsideClick: false,
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.href = "/admin/inicio";
            }
          });
        });
    } else {
      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionStorage.token}`,
        },
        body: JSON.stringify(postData),
      };

      fetch(`${Api.url}editElementoTimeline`, requestOptions)
        .then((response) => {
          if (response.status === 200) {
            return response.json();
          }
          throw new Error(response.status);
        })
        .then((data) => {
          Swal.fire({
            text: data.message,
            allowOutsideClick: false,
          });
          this.handleClose();
          this.setElementos(); // Actualizo tabla de elementos
        })
        .catch((error) => {
          Swal.fire({
            title: "Error",
            text: "No tiene acceso a ésta información",
            icon: "error",
            showCancelButton: false,
            cancelButtonColor: "green",
            confirmButtonText: "Continuar",
            allowOutsideClick: false,
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.href = "/admin/inicio";
            }
          });
        });
    }
  };

  handleStatusElemento = (id) => {
    Swal.fire({
      icon: "question",
      title: "¿Está seguro de realizar la acción?",
      showDenyButton: true,
      denyButtonColor: "red",
      confirmButtonColor: "#4CD964",
      showCancelButton: false,
      confirmButtonText: `Continuar`,
      denyButtonText: `Regresar`,
      reverseButtons: true,
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        const requestOptions = {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.token}`,
          },
          body: JSON.stringify({ id }),
        };

        fetch(
          `${Api.url}deactivateElementoTimeline`,
          requestOptions
        )
          .then((response) => {
            if (response.status === 200) {
              return response.json();
            }
            throw new Error(response.status);
          })
          .then((data) => {
            Swal.fire({
              text: data.message,
              allowOutsideClick: false,
            });
            this.setElementos(); // Actualizo tabla de elementos
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              text: "No tiene acceso a ésta información",
              icon: "error",
              showCancelButton: false,
              cancelButtonColor: "green",
              confirmButtonText: "Continuar",
              allowOutsideClick: false,
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.href = "/admin/inicio";
              }
            });
          });
      }
    });
  };

  handleChangeValue = (e) => {
    const datos = { ...this.state.elementoInfo };

    switch (e.target.id) {
      case "titulo":
        datos.texto = e.target.value;
        break;
      case "fecha":
        datos.fecha = e.target.value;
        break;
      default:
        break;
    }

    this.setStateElementoInfo(datos);
  };

  setStateElementoInfo = (datos) => {
    this.setState({ elementoInfo: datos });
  };

  handleGetElementoEdit = (e, id, aux) => {
    e.preventDefault();

    if (aux) {
      this.setState({ editar: true });
    } else {
      this.setState({ ver: true });
    }

    this.setElemento(id);
  };

  setElemento = (id) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.token}`,
      },
    };

    fetch(
      `${Api.url}getElementoTimeline?id=${id}`,
      requestOptions
    )
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        }
        throw new Error(response.status);
      })
      .then((data) => {
        if (data) {
          this.setState({ elementoInfo: data });
          this.handleOpen();
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: "No tiene acceso a ésta información",
          icon: "error",
          showCancelButton: false,
          cancelButtonColor: "green",
          confirmButtonText: "Continuar",
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = "/admin/inicio";
          }
        });
      });
  };

  changeRowOrder = (id, up) => {
    Api.loading(true);
    const requestOptions = {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.token}`,
      },
    };

    fetch(
      `${Api.url}changeOrderRowTimeline?id=${id}&rpeID=${this.state.rpeID}&up=${up}`,
      requestOptions
    )
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        }
        throw new Error(response.status);
      })
      .then((data) => {
        if (data) {
          this.setElementos();
          Api.loading(false);
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: "No tiene acceso a ésta información",
          icon: "error",
          showCancelButton: false,
          cancelButtonColor: "green",
          confirmButtonText: "Continuar",
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = "/admin/inicio";
          }
        });
      });
  };

  render() {
    return (
      <Page title={this.state.rpeInfo.descripcion}>
        <Container>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ width: "100%" }}
          >
            <Typography variant="h4" gutterBottom>
              Línea del tiempo | {this.state.rpeInfo.descripcion}
            </Typography>
            <Box>
              <Button
                variant="contained"
                startIcon={<Icon icon={plusFill} />}
                onClick={(e) => this.handleOpen(true)}
              >
                Nuevo elemento
              </Button>
              <NuevoElementoTimeline
                title="elemento línea del tiempo"
                isOpen={this.state.modalShow}
                handleChangeValue={(e) => this.handleChangeValue(e)}
                handleClose={() => this.handleClose()}
                onHandleSubmit={(e) => this.handleAddElemento(e)}
                info={this.state.elementoInfo}
                editar={this.state.editar}
                ver={this.state.ver}
                nuevo={this.state.nuevo}
              />
            </Box>
          </Stack>
          <Card>
            <Scrollbar>
              <TableContainer sx={{ minWidth: "100%" }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell key='changeRow' align="center">
                        Orden
                      </TableCell>
                      {tableHead.map((headCell, index) => (
                        <TableCell key={index} align="left">
                          {headCell.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.timeline.map((c, index) => (
                      <TableRow key={index}>
                        <TableCell align="left">
                          <Stack>
                            {index !== 0 ? (
                              <IconButton
                                size="small"
                                onClick={() =>
                                  this.changeRowOrder(c.id, true)
                                }
                              >
                                <Iconify icon="eva:arrow-ios-upward-outline" />
                              </IconButton>
                            ) : null}

                            {index !== this.state.timeline.length - 1 ? (
                              <IconButton
                                size="small"
                                onClick={() =>
                                  this.changeRowOrder(c.id, false)
                                }
                              >
                                <Iconify icon="eva:arrow-ios-downward-outline" />
                              </IconButton>
                            ) : null}
                          </Stack>
                        </TableCell>
                        <TableCell align="left">{c.texto}</TableCell>
                        <TableCell align="left">
                          <Label
                            variant="ghost"
                            color={(parseInt(c.status,10) === 0 && "error") || "success"}
                          >
                            {c.status_descripcion}
                          </Label>
                        </TableCell>
                        <TableCell>
                          <IconButton
                            onClick={(e) =>
                              this.handleGetElementoEdit(e, c.id, false)
                            }
                          >
                            <Iconify icon="eva:eye-outline" />
                          </IconButton>
                          <IconButton
                            onClick={(e) =>
                              this.handleGetElementoEdit(e, c.id, true)
                            }
                          >
                            <Iconify icon="eva:edit-2-outline" />
                          </IconButton>
                          <IconButton
                            onClick={() => this.handleStatusElemento(c.id)}
                          >
                            {parseInt(c.status,10) === 1 ? (
                              <Iconify icon="eva:close-fill" />
                            ) : (
                              <Iconify icon="eva:checkmark-outline" />
                            )}
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                  {this.state.notFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <Paper>
                            <Typography variant="body2" align="center">
                              No se encontraron resultados.
                            </Typography>
                          </Paper>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>
          </Card>
        </Container>
      </Page>
    );
  }
}
export default (props) => <TimelineAdmin {...props} params={useParams()} />;