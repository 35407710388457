import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import plusFill from "@iconify/icons-eva/plus-fill";
import { Link } from "react-router-dom";
// material
import {
  Box,
  Card,
  Table,
  Stack,
  Button,
  IconButton,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  Paper,
  TablePagination,
} from "@mui/material";
import Swal from "sweetalert2";
// components
import Label from "../components/Label";
import Page from "../components/Page";
import Scrollbar from "../components/Scrollbar";
import Search from "../components/Search";
import ModalInput from "../components/ModalInput";
import ModalEdit from "../components/ModalEdit";
import Iconify from "../components/Iconify";
import { Api } from "../app-controller";

// ----------------------------------------------------------------------
const host = window.location.host.split(":")[0];

const tableHead = [
  { id: "descripcion", label: "Descripción", alignRight: false },
  { id: "link", label: "Liga", alignRight: false },
  { id: "status", label: "Estado", alignRight: false },
  { id: "opciones", label: "Opciones", alignRight: true },
];

const title = "Nueva pestaña";
const titleE = "Editar pestaña";

export default class Layout extends React.Component {
  constructor() {
    super();
    this.state = {
      pestanas: [],
      searchArray: [],
      pestanaID: null,
      elementosA: [],
      elementosE: [],
      modalShow: false,
      modalShowE: false,
      notFound: false,
      // search: "",
      // rowsPerPage: 25,
      // page: 0,
      // total: 0,
      // changeRow: false,
    };

    this.handleClose = this.handleClose.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.handleCloseE = this.handleCloseE.bind(this);
    this.handleOpenE = this.handleOpenE.bind(this);
  }

  componentDidMount() {
    this.setPestanas();
    this.setElementos();
    this.setCatalogos();
  }

  setPestanas = (query = "") => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.token}`,
      },
    };

    fetch(`${Api.url}getPestanas?${query}`, requestOptions)
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        }
        throw new Error(response.status);
      })
      .then((data) => {
        if (data) {
          const auxNotFound = data.pestanas.length === 0;

          this.setState({
            pestanas: data.pestanas,
            // total: data.pestanas.total,
            // page: data.pestanas.current_page - 1,
            notFound: auxNotFound,
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: "No tiene acceso a ésta información",
          icon: "error",
          showCancelButton: false,
          cancelButtonColor: "green",
          confirmButtonText: "Continuar",
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = "/admin/inicio";
          }
        });
      });
  };

  setCatalogos = () => {
    fetch(`${Api.url}getStatus`)
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          this.setSearch(data.statusArray);
        }
      });
  };

  setSearch = (statusArray) => {
    this.setState({
      searchArray: [
        {
          id: "descripcion",
          label: "Descripción",
          input: "text",
        },
        {
          id: "link",
          label: "Liga",
          input: "text",
        },
        {
          id: "status",
          label: "Estado",
          input: "select",
          select: statusArray,
        },
      ],
    });
  };

  setElementos = (arrayLevel) => {
    this.setState({
      elementosA: [
        {
          id: "descripcion_M",
          label: "Descripción",
          input: "text",
          value: "",
          required: true,
          maxLength: 100,
        },
        {
          id: "link_M",
          label: "Liga",
          input: "text",
          value: "",
          required: true,
          maxLength: 100,
        },
      ],
    });
  };

  handleSearch = (search) => {
    let src = "";
    search.forEach((param) => {
      if (
        param.value !== undefined &&
        param.value !== null &&
        param.value !== ""
      )
        src += `&${param.nombre}=${param.value}`;
    });

    /* this.setState({ search: src }); */
    this.setPestanas(src);
  };

  /*   handleChangeRowsPerPage = (event) => {
    const auxRowsPerPage = parseInt(event.target.value, 10);
    this.setState({ rowsPerPage: auxRowsPerPage, page: 0 });
    this.setPestanas(`rowsPerPage=${auxRowsPerPage}${this.state.search}`);
  }; */

  /*   handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
    this.setPestanas(
      `page=${newPage + 1}&rowsPerPage=${this.state.rowsPerPage}${
        this.state.search
      }`
    );
  }; */

  changeRowOrder = (id, up) => {
    Api.loading(true);
    const requestOptions = {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.token}`,
      },
    };

    fetch(
      `${Api.url}changeOrderRow?id=${id}&up=${up}`,
      requestOptions
    )
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        }
        throw new Error(response.status);
      })
      .then((data) => {
        if (data) {
          this.setPestanas();
          Api.loading(false);
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: "No tiene acceso a ésta información",
          icon: "error",
          showCancelButton: false,
          cancelButtonColor: "green",
          confirmButtonText: "Continuar",
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = "/admin/inicio";
          }
        });
      });
  };

  handleOpen = () => {
    this.setState({ modalShow: true });
  };

  handleClose = () => {
    this.setState({ modalShow: false });
  };

  handleOpenE = () => {
    this.setState({ modalShowE: true });
  };

  handleCloseE = () => {
    this.setState({ modalShowE: false, elementosE: [] });
  };

  handleAddLayout = (data) => {
    const postData = {};

    data.forEach((e) => {
      const newObject = {};
      newObject[e.nombre] = e.value;
      Object.assign(postData, newObject);
    });

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.token}`,
      },
      body: JSON.stringify({ postData }),
    };

    fetch(`${Api.url}createPestana`, requestOptions)
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        }
        throw new Error(response.status);
      })
      .then((data) => {
        this.handleClose();

        Swal.fire({
          text: data.message,
          allowOutsideClick: false,
        });

        this.setPestanas(); // Actualizo tabla de pestanas
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: "No tiene acceso a ésta información",
          icon: "error",
          showCancelButton: false,
          cancelButtonColor: "green",
          confirmButtonText: "Continuar",
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = "/admin/inicio";
          }
        });
      });
  };

  handleStatusPestana = (id) => {
    Swal.fire({
      icon: "question",
      title: "¿Está seguro de realizar la acción?",
      showDenyButton: true,
      denyButtonColor: "red",
      confirmButtonColor: "#4CD964",
      showCancelButton: false,
      confirmButtonText: `Continuar`,
      denyButtonText: `Regresar`,
      reverseButtons: true,
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        const requestOptions = {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.token}`,
          },
          body: JSON.stringify({ id }),
        };

        fetch(`${Api.url}deactivatePestana`, requestOptions)
          .then((response) => {
            if (response.status === 200) {
              return response.json();
            }
            throw new Error(response.status);
          })
          .then((data) => {
            Swal.fire({
              text: data.message,
              allowOutsideClick: false,
            });

            this.setPestanas(); // Actualizo tabla de pestañas
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              text: "No tiene acceso a ésta información",
              icon: "error",
              showCancelButton: false,
              cancelButtonColor: "green",
              confirmButtonText: "Continuar",
              allowOutsideClick: false,
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.href = "/admin/inicio";
              }
            });
          });
      }
    });
  };

  handleGetPestanaEdit = (id) => {
    this.setState({ pestanaID: id }, this.setPestana(id), this.handleOpenE());
  };

  setPestana = (id) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.token}`,
      },
    };

    fetch(`${Api.url}getPestana?id=${id}`, requestOptions)
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        }
        throw new Error(response.status);
      })
      .then((data) => {
        if (data) {
          this.setState({
            elementosE: [
              {
                id: "descripcion_M",
                label: "Descripción",
                input: "text",
                required: true,
                value: data.pestana.descripcion,
                maxLength: 100,
              },
              {
                id: "link_M",
                label: "Liga",
                input: "text",
                required: true,
                value: data.pestana.link,
                maxLength: 100,
              },
            ],
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: "No tiene acceso a ésta información",
          icon: "error",
          showCancelButton: false,
          cancelButtonColor: "green",
          confirmButtonText: "Continuar",
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = "/admin/inicio";
          }
        });
      });
  };

  handleUpdatePestana = (data) => {
    // const theme = useTheme();
    const postData = {};
    Object.assign(postData, { id: this.state.pestanaID });

    data.forEach((e) => {
      const newObject = {};
      newObject[e.nombre] = e.value;
      Object.assign(postData, newObject);
    });

    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.token}`,
      },
      body: JSON.stringify({ postData }),
    };

    fetch(`${Api.url}updatePestana`, requestOptions)
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        }
        throw new Error(response.status);
      })
      .then((data) => {
        this.handleCloseE();

        Swal.fire({
          text: data.message,
          allowOutsideClick: false,
        });

        this.setPestanas(); // Actualizo tabla de pestañas
        this.setPestana(this.state.pestanaID);
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: "No tiene acceso a ésta información",
          icon: "error",
          showCancelButton: false,
          cancelButtonColor: "green",
          confirmButtonText: "Continuar",
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = "/admin/inicio";
          }
        });
      });
  };

  render() {
    return (
      <Page title="Pestañas">
        <Container>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ width: "100%" }}
          >
            <Typography variant="h4" gutterBottom>
              Pestañas
            </Typography>
            <Box>
              <Button
                variant="contained"
                startIcon={<Icon icon={plusFill} />}
                onClick={() => this.handleOpen()}
              >
                Nueva pestaña
              </Button>
              <ModalInput
                title={title}
                elementos={this.state.elementosA}
                isOpen={this.state.modalShow}
                handleClose={this.handleClose}
                onHandleSubmit={this.handleAddLayout}
              />
              <ModalEdit
                title={titleE}
                elementos={this.state.elementosE}
                isOpen={this.state.modalShowE}
                handleClose={this.handleCloseE}
                onHandleSubmit={this.handleUpdatePestana}
              />
            </Box>
          </Stack>
          <Box sx={{ my: 2 }}>
            <Search
              onHandleSearch={this.handleSearch}
              elementos={this.state.searchArray}
            />
          </Box>
          <Card>
            <Scrollbar>
              <TableContainer sx={{ minWidth: "100%" }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell key='changeRow' align="center">
                        Orden
                      </TableCell>
                      {tableHead.map((headCell, index) => (
                        <TableCell key={index} align="left">
                          {headCell.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.pestanas.map((pestana, index) => (
                      <TableRow key={index}>
                        <TableCell align="left">
                          <Stack>
                            {index !== 0 ? (
                              <IconButton
                                size="small"
                                onClick={() =>
                                  this.changeRowOrder(pestana.id, true)
                                }
                              >
                                <Iconify icon="eva:arrow-ios-upward-outline" />
                              </IconButton>
                            ) : null}

                            {index !== this.state.pestanas.length - 1 ? (
                              <IconButton
                                size="small"
                                onClick={() =>
                                  this.changeRowOrder(pestana.id, false)
                                }
                              >
                                <Iconify icon="eva:arrow-ios-downward-outline" />
                              </IconButton>
                            ) : null}
                          </Stack>
                        </TableCell>
                        <TableCell align="left">
                          {pestana.descripcion}
                        </TableCell>
                        <TableCell align="left">{pestana.link}</TableCell>
                        <TableCell align="left">
                          <Label
                            variant="ghost"
                            color={
                              (parseInt(pestana.status,10) === 0 && "error") || "success"
                            }
                          >
                            {pestana.status_descripcion}
                          </Label>
                        </TableCell>
                        <TableCell>
                          {parseInt(pestana.default,10) === 0 ? (
                            <>
                              <IconButton
                                component={Link}
                                to={`/admin/pestana/${pestana.id}`}
                              >
                                <Iconify icon="eva:external-link-fill" />
                              </IconButton>
                              <IconButton
                                onClick={() =>
                                  this.handleGetPestanaEdit(pestana.id)
                                }
                              >
                                <Iconify icon="eva:edit-2-outline" />
                              </IconButton>
                            </>
                          ) : null}
                          <IconButton
                            onClick={() => this.handleStatusPestana(pestana.id)}
                          >
                            {parseInt(pestana.status,10) === 1 ? (
                              <Iconify icon="eva:close-fill" />
                            ) : (
                              <Iconify icon="eva:checkmark-outline" />
                            )}
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                  {this.state.notFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <Paper>
                            <Typography variant="body2" align="center">
                              No se encontraron resultados.
                            </Typography>
                          </Paper>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>
            {/* <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              labelRowsPerPage="Renglones por página"
              labelDisplayedRows={({ from, to, count }) =>
                `${from} - ${to} de ${count}`
              }
              component="div"
              count={this.state.total}
              rowsPerPage={this.state.rowsPerPage}
              page={this.state.page}
              onPageChange={this.handleChangePage}
              onRowsPerPageChange={this.handleChangeRowsPerPage}
            /> */}
          </Card>
        </Container>
      </Page>
    );
  }
}
