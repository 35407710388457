import React from 'react';
// material
import { Stack, Toolbar } from '@mui/material';
import { styled } from '@mui/material/styles';
// componente
import NavSectionMenu from './NavSectionMenu';

// -----------------------------------------------
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5)
  }
}));
// -----------------------------------------------

export default function MenuNavbar({ menu }) {
  const renderContent = <NavSectionMenu navconfig={menu} />
  return (
    <ToolbarStyle sx={{ justifyContent: 'center' }}>
      <Stack alignItems="center">{renderContent}</Stack>
    </ToolbarStyle>
  );
}
