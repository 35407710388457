/**
 * Si es posible sería mejor hacer el api.fetch aqui para tener el menu
 */
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const MenuAdministradorConfig = [
  {
    id: 1,
    title: 'Inicio',
    path: '/admin/inicio',
    icon: getIcon('eva:home-outline')
  },
  {
    id: 2,
    title: 'Información General',
    path: '/admin/general',
    icon: getIcon('eva:settings-2-outline')
  },
  {
    id: 3,
    title: 'Contacto',
    path: '/admin/contacto',
    icon: getIcon('eva:phone-outline')
  },
  {
    id: 4,
    title: 'Directorio',
    path: '/admin/directorio',
    icon: getIcon('ps:contact')
  },
  {
    id: 5,
    title: 'Usuarios',
    path: '/admin/user',
    icon: getIcon('eva:people-outline')
  },
  {
    id: 6,
    title: 'Pestañas',
    path: '/admin/pestanas',
    icon: getIcon('eva:browser-fill')
  },
  {
    id: 8,
    title: 'Convocatorias',
    path: '/admin/convocatorias',
    icon: getIcon('majesticons:megaphone-line')
  },
  {
    id: 6,
    title: 'Licitaciones',
    path: '/admin/licitaciones',
    icon: getIcon('eva:briefcase-outline')
  },
  {
    id: 9,
    title: 'Ligas',
    path: '/admin/links',
    icon: getIcon('eva:external-link-fill')
  },
  {
    id: 10,
    title: 'Pie de página',
    path: '/admin/piepagina',
    icon: getIcon('eva:link-2-fill')
  },
  {
    id: 11,
    title: 'Documentos',
    path: '/admin/carpetas',
    icon: getIcon('eva:folder-outline'),
    // children: [
    //   { title: 'Carpetas', path: '/admin/docs' },
    //   { title: 'Adquisiciones', path: '/admin/docs/1' }
    // ]
  }
];

export default MenuAdministradorConfig;
