import * as React from 'react';
// material
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  TextField,
  IconButton,
  Typography,
  Paper,
  Stack
} from '@mui/material';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ImageIcon from '@mui/icons-material/Image';
import Switch from '@mui/material/Switch';
import Iconify from './Iconify';
import UploadButton from './loadImages';
import EditorQuill from './EditorQuill';
import { Api } from '../app-controller';

const host = window.location.host.split(':')[0];
const label = { inputProps: { 'aria-label': 'Switch demo' } };

const imgStyle = {
  border: 'solid 0px red',
  float: 'center',
  padding: '0 10px 10px',
  height: '180px',
  width: '200px'
};

class NuevoElementoResumen extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
    this.windowWidth = window.innerWidth;
  }

  render() {
    return (
      <Dialog open={this.props.isOpen} onClose={(e) => this.props.handleClose(e)} fullWidth>
        <DialogTitle>
          {this.props.ver ? 'Ver' : ''} {this.props.editar ? 'Editar' : ''}{' '}
          {this.props.nuevo ? 'Nueva' : ''} {this.props.title}
          <IconButton
            aria-label="close"
            onClick={(e) => this.props.handleClose(e)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}
          >
            <Iconify icon="eva:close-outline" />
          </IconButton>
        </DialogTitle>
        <form onSubmit={(e) => this.props.onHandleSubmit(e)}>
          <DialogContent>
            <Typography>
              Titulo <span style={{ color: 'red' }}>*</span>
            </Typography>
            <TextField
              id="titulo"
              placeholder="Titulo"
              maxRows={5}
              // error //como lo puedo obtener?
              multiline
              fullWidth
              required
              value={this.props.info.titulo}
              disabled={this.props.ver}
              onChange={(e) => this.props.handleChangeValue(e)}
              inputProps={{ maxLength: '60' }}
            />

            <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-end">
              <Typography>Resumen</Typography>
              <Switch
                id="switch"
                checked={this.props.info.switchValue}
                value={this.props.info.switchValue}
                disabled={this.props.ver}
                onChange={(e) => this.props.handleChangeValue(e)}
                {...label}
              />
              <Typography> PDF</Typography>
            </Stack>

            {this.props.info.switchValue ? (
              <>
                <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-start">
                  {this.props.info.url_pdf !== null ? (
                    <>
                      <PictureAsPdfIcon />
                      <Typography sx={{ marginTop: 2 }}>
                        {this.props.info.url_pdf.name}
                      </Typography>
                    </>
                  ) : (
                    <>
                      {/* <span style={{ color: 'red' }}>Favor de seleccionar un archivo</span> */}
                      {this.props.mensajeError.error ? (
                        <Typography>
                          <span style={{ color: 'red' }}>{this.props.mensajeError.mensaje}</span>
                        </Typography>
                      ) : null}
                    </>
                  )}
                </Stack>

                <br />
                <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-start">
                  <Typography>
                    PDF <span style={{ color: 'red' }}>*</span>
                  </Typography>
                  <UploadButton
                    id="pdf"
                    isDisabled={this.props.ver}
                    onHandleChange={(e) => this.props.handleChangeValue(e)}
                    ext="application/pdf"
                  />
                </Stack>
              </>
            ) : (
              <>
                <Typography>
                  Resumen <span style={{ color: 'red' }}>*</span>
                </Typography>
                <TextField
                  id="resumen"
                  placeholder="Resumen ..."
                  maxRows={10}
                  // error //como lo puedo obtener?
                  multiline
                  fullWidth
                  required
                  value={this.props.info.resumen}
                  disabled={this.props.ver}
                  onChange={(e) => this.props.handleChangeValue(e)}
                  inputProps={{ maxLength: '150' }}
                />
                <br />
                <br />
                <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-start">
                  {this.props.info.imagen !== null && !this.props.ver ? (
                    <>
                      <ImageIcon />
                      <Typography sx={{ marginTop: 2 }}>
                        {typeof this.props.info.imagen === 'string'
                          ? this.props.info.imagen
                          : this.props.info.imagen.name}
                      </Typography>
                    </>
                  ) : (
                    <>
                      {this.props.mensajeError.error ? (
                        <Typography>
                          <span style={{ color: 'red' }}>{this.props.mensajeError.mensaje}</span>
                        </Typography>
                      ) : null}
                    </>
                  )}
                </Stack>
                <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-start">
                  <Typography>
                    Imagen <span style={{ color: 'red' }}>*</span>
                  </Typography>
                  <UploadButton
                    id="imagen"
                    isDisabled={this.props.ver}
                    onHandleChange={(e) => this.props.handleChangeValue(e)}
                    ext="image/*"
                    required
                  />
                </Stack>
                <br />
                <Paper>
                  {this.props.info.imagen !== null && this.props.ver ? (
                    <img
                      style={imgStyle}
                      src={`${Api.url}getArchivoTI?id=${this.props.info.id}`}
                      alt="Nombre de la imagen"
                    />
                  ) : null}
                </Paper>
                <br />
                {this.props.ver ? (
                  <div dangerouslySetInnerHTML={{ __html: this.props.info.contenido }} />
                ) : (
                  <>
                    <Typography>
                      Información <span style={{ color: 'red' }}>*</span>
                    </Typography>
                    <EditorQuill
                      onHandleContent={(e) => this.props.handleChangeEditor(e)}
                      only={this.props.ver}
                      valueDefaul={this.props.info.contenido}
                    />
                  </>
                )}
              </>
            )}

            {this.props.mensajeErrorSubmit.error ? (
              <Typography>
                <span style={{ color: 'red' }}>{this.props.mensajeErrorSubmit.mensaje}</span>
              </Typography>
            ) : null}
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="error" onClick={(e) => this.props.handleClose(e)}>
              {this.props.editar ? 'Cancelar' : 'Regresar'}
            </Button>

            {this.props.ver ? (
              <></>
            ) : (
              <Button variant="contained" type="submit">
                Guardar
              </Button>
            )}
          </DialogActions>
        </form>
      </Dialog>
    );
  }
}

export default NuevoElementoResumen;
