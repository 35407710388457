import React, { useRef, useState, useEffect } from 'react';
import { Wrapper, Status } from '@googlemaps/react-wrapper';

const render = (status) => {
  if (status === Status.LOADING) return <h3>{status} ..</h3>;
  if (status === Status.FAILURE) return <h3>{status} ...</h3>;
  return null;
};

function MyMapComponent({ center, zoom }) {
  const ref = useRef();
  const [map, setMap] = useState();
  const [marker, setMarker] = useState(null);
  const position = center;
  const visible = true;

  useEffect(() => {
    if (ref.current && !map) {
      setMap(new window.google.maps.Map(ref.current, { center, zoom }));
    }
    if (!marker && map) {
      setMarker(new window.google.maps.Marker({ position, map, visible }));
    }
  });

  return <div style={{ height: '100%', width: '100%' }} ref={ref} id="map" />;
}

export default function Maps({ center, zoom }) {
  return (
    <Wrapper apiKey="AIzaSyAkK5t0rVD-S_X_wU9x7TPhgVAJI00qZhU" render={render}>
      <MyMapComponent center={center} zoom={zoom} />
    </Wrapper>
  );
}
