import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Swal from 'sweetalert2';
import { Api } from '../app-controller';

const host = window.location.host.split(':')[0];

export default function ContactForm() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [tel, setTel] = useState('');
  const [message, setMessage] = useState('');
  const [reqTel, setReqTel] = useState(false);
  const [reqEmail, setReqEmail] = useState(false);

  const handleChange = (event) => {
    event.preventDefault();

    const element = event.target;

    switch (element.id) {
      case 'nombre':
        setName(element.value);
        break;
      case 'email':
        setEmail(element.value);
        break;
      case 'telefono':
        setTel(element.value);
        break;
      case 'mensaje':
        setMessage(element.value);
        break;
      default:
        break;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!email && !tel) {
      Swal.fire({
        icon: 'warning',
        text: 'Favor de ingresar correo electrónico o número de teléfono',
        allowOutsideClick: false
      });
    } else {
      const postData = {};
      Api.loading(true);
      Object.assign(postData, { nombre: name });
      Object.assign(postData, { correo: email });
      Object.assign(postData, { telefono: tel });
      Object.assign(postData, { mensaje: message });
      Object.assign(postData, { titulo: localStorage.titulo });

      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ postData })
      };

      fetch(`${Api.url}mensajeContacto`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          Api.loading(false);
          Swal.fire({
            text: data.message,
            allowOutsideClick: false
          });
          setName('');
          setEmail('');
          setTel('');
          setMessage('');
        });
    }
  };

  return (
    <div>
      <form onSubmit={(e) => handleSubmit(e)}>
      <TextField
          id="nombre"
          label="Nombre"
          required
          style={{ margin: 7 }}
          fullWidth
          InputLabelProps={{
            shrink: true
          }}
          value={name}
          inputProps={{
            maxLength: 255
          }}
          autoComplete="none"
          onChange={handleChange}
        />

        <TextField
          id="email"
          label="Correo electrónico"
          required={reqEmail}
          fullWidth
          style={{ margin: 7 }}
          type="email"
          InputLabelProps={{
            shrink: true
          }}
          value={email}
          inputProps={{
            maxLength: 255,
            pattern: '[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+[.]{1}[a-zA-Z]{2,}$'
          }}
          autoComplete="none"
          onChange={handleChange}
        />

        <TextField
          id="telefono"
          label="Número de teléfono"
          required={reqTel}
          placeholder="Teléfono"
          style={{ margin: 7 }}
          fullWidth
          InputLabelProps={{
            shrink: true
          }}
          value={tel}
          inputProps={{ inputMode: 'numeric', pattern: `[0-9]{13}`}}
          autoComplete="none"
          onChange={handleChange}
        />

        <TextField
          id="mensaje"
          label="Mensaje"
          required
          fullWidth
          InputLabelProps={{
            shrink: true
          }}
          style={{ margin: 7 }}
          multiline
          rows={5}
          value={message}
          inputProps={{
            maxLength: 255
          }}
          autoComplete="none"
          onChange={handleChange}
        />

        <div align="right">
          <Button size="large" style={{ marginTop: 5 }} variant="contained" type="submit">
            Enviar
          </Button>
        </div>
      </form>
    </div>
  );
}
