// material
import { alpha, styled } from '@mui/material/styles';
import { Card, Typography } from '@mui/material';
import Iconify from '../../Iconify';

// ----------------------------------------------------------------------

export default function AppWeeklySales({ icon, titulo }) {
  const RootStyle = styled(Card)(({ theme }) => ({
    boxShadow: 'none',
    textAlign: 'center',
    padding: theme.spacing(5, 0),
    color: 'black',
    backgroundColor: theme.palette.primary.main,
  }));

  const IconWrapperStyle = styled('div')(({ theme }) => ({
    margin: 'auto',
    display: 'flex',
    borderRadius: '50%',
    alignItems: 'center',
    width: theme.spacing(8),
    height: theme.spacing(8),
    justifyContent: 'center',
    marginBottom: theme.spacing(3),
    color: 'white',
    backgroundImage: `linear-gradient(135deg, ${alpha('#262526', 0.1)} 0%, ${alpha(
      '#262526',
      0.29
    )} 100%)`
  }));

  const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

  return (
    <RootStyle>
      <IconWrapperStyle>{getIcon(icon)}</IconWrapperStyle>
      <Typography variant="button" sx={{ opacity: 0.72 }}>
        {titulo}
      </Typography>
    </RootStyle>
  );
}
