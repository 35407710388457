import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { NavLink as RouterLink} from 'react-router-dom';
// Icon
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
// material
import { useTheme, styled } from '@mui/material/styles';
import { Box, List, ListItemText, ListItemButton } from '@mui/material';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
// components
import MenuPopover from './MenuPopover';

// ----------------------------------------------------------------------

const ListItemStyleSubMenu = styled((props) => <ListItemButton disableGutters {...props} />)(
  ({ theme }) => ({
    ...theme.typography.body2,
    height: 48,
    position: 'relative',
    textTransform: 'capitalize',
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(2.5),
    color: theme.palette.text.secondary,
    fontSize: '1em',
    '&:before': {
      top: 0,
      right: 0,
      width: 3,
      bottom: 0,
      content: "''",
      display: 'none',
      position: 'absolute',
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 4,
      backgroundColor: theme.palette.primary.main
    }
  })
);

// ----------------------------------------------------------------------

LinkTab.propTypes = {
  item: PropTypes.object,
  value: PropTypes.number,
  onChange: PropTypes.func
};

function LinkTab({ item, value, ...props }) {
  const { id, title, path, subMenu, mostrarSubMenu } = item;
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  const handleOpen = (event) => {
    event.stopPropagation();
    props.onChange(event, value);
    setOpen((prev) => !prev);
  };

  const handleClose = (event, reason) => {
    event.stopPropagation();

    if (reason === 'backdropClick') {
      // Cerrar sin seleccionar el menu
      props.onChange(event, value);
    }

    setOpen(false);
  };

  const handleCloseAux = (event, id) => {
    event.stopPropagation();
    props.onChange(event, id);
    setOpen(false);
  };

  if (mostrarSubMenu) {
    const idTab = id;
    return (
      <>
        <Tab
          key={`SubMenuTab-${title}`}
          onChange={(event) => {
            event.preventDefault();
            // event.stopPropagation();
            props.onChange(event, value);
          }}
          onClick={handleOpen}
          ref={anchorRef}
          {...props}
          icon={open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          iconPosition="end"
        />

        <MenuPopover
          key={`SubMenuPopover-${title}`}
          open={open}
          onClose={(event, reason) => {
            handleClose(event, reason);
          }}
          anchorEl={anchorRef.current}
          sx={{ width: '325px ' }} // 325px
        >
          {subMenu.length === 0 ? (
            <ListItemStyleSubMenu onClick={handleClose}>
              {/* RECORDATORIO si esta vacío ponerle que no esta disponible */}
              <ListItemText disableTypography primary="No disponibles" />
            </ListItemStyleSubMenu>
          ) : (
            <List component="div" disablePadding>
              {subMenu.map((subTab) => {
                const { title } = subTab;
                const path = `/${subTab.type}/${subTab.title}/${subTab.id}`;

                return (
                  <ListItemStyleSubMenu
                    key={title}
                    component={RouterLink}
                    to={path}
                    onClick={(event) => {
                      handleCloseAux(event, idTab - 1);
                    }}
                  >
                    <ListItemText
                      disableTypography
                      primary={title.length > 38 ? `${title.substr(0, 38)}...` : title}
                    />
                  </ListItemStyleSubMenu>
                );
              })}
            </List>
          )}
        </MenuPopover>
      </>
    );
  }

  return (
    <Tab
      key={`LinkMenu-${title}`}
      onChange={(event) => {
        event.preventDefault();
        event.stopPropagation();
        props.onChange(event, value);
      }}
      href={path}
      {...props}
    />
  );
}

NavSectionMenu.propTypes = {
  navconfig: PropTypes.array
};

export default function NavSectionMenu({ navconfig }) {
  const pathname = window.location.href;
  const [value, setValue] = useState(1);

  useEffect(() => {
    calcularValue(navconfig);
  });

  const calcularValue = (navconfig) => {
    const thePage = navconfig.find((tab) => tab.path === pathname || pathname.includes(tab.path) ); // Funciona para inicio

    if (thePage !== undefined) {
      setValue(thePage.id);
    }else{
      setValue(0);
    }
  };

  const theme = useTheme();

  const handleChange = (event, newValue) => {
    event.stopPropagation();
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        maxWidth: { xs: 320, sm: 560, md: 800, lg: 1000, xl: 1200 },
        bgcolor: 'background.paper'
      }}
    >
      <Tabs
        variant="scrollable"
        scrollButtons
        allowScrollButtonsMobile
        aria-label="visible arrows tabs example"
        // visibleScrollbar
        value={value}
        onChange={handleChange}
        sx={{
          [`& .${tabsClasses.scrollButtons}`]: {
            color: theme.palette.primary.main
          }
        }}
      >
        {navconfig.map((item) => (
          <LinkTab
            key={item.id}
            label={item.title}
            item={item}
            value={item.id}
            navconfig={navconfig}
            wrapped
            sx={{
              fontSize: '1em'
            }}
          />
        ))}
      </Tabs>
    </Box>
  );
}
