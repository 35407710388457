import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
// material
import { alpha,styled } from '@mui/material/styles';
import { Box, Link, IconButton, Drawer, Typography,AppBar,  Stack, Toolbar } from '@mui/material';
// components
import Logo from '../../components/Logo';
import Iconify from '../../components/Iconify';
import Scrollbar from '../../components/Scrollbar';
import NavSection from '../../components/NavSection';
import { MHidden } from '../../components/@material-extend';
import DashboardAdmin from './DashboardAdmin';
import AccountPopover from './AccountPopover';
//
import MenuAdministradorConfig from './MenuAdministradorConfig';
import MenuCoordinadorConfig from './MenuCoordinadorConfig';
import account from '../../_mocks_/account';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH
  }
}));

const RootStyleHorizont = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`
  }
}));

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.grey[200]
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5)
  }
}));

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
  onOpenSidebar: PropTypes.func
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar, onOpenSidebar, nivel }) {

  const { pathname } = useLocation();

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: '100%',
        '& .simplebar-content': { height: '100%', display: 'flex', flexDirection: 'column' }
      }}
    >
      <Box sx={{ px: 2.5, py: 3 }}>
        <Box component={RouterLink} to="/admin/inicio" sx={{ display: 'inline-flex' }}>
          <Logo />
        </Box>
      </Box>

      <Box sx={{ mb: 5, mx: 2.5 }}>
        <Link underline="none" component={RouterLink} to="#">
          <AccountStyle>
            <IconButton>
              <Iconify icon="eva:person-fill" />
            </IconButton>
            <Box sx={{ ml: 2 }}>
              <Typography variant="subtitle2" align="left" sx={{ color: 'text.primary' }}>
                {account.displayName}
              </Typography>
              <Typography variant="caption" align="center" sx={{ color: 'text.secondary' }}>
                {account.role}
              </Typography>
            </Box>
          </AccountStyle>
        </Link>
      </Box>
      { parseInt(nivel, 10) === 1 ? (
        <NavSection navconfig={MenuAdministradorConfig} />
      ) : (
        <NavSection navconfig={MenuCoordinadorConfig} />
      )}
    </Scrollbar>
  );

  return (
    <>
      <RootStyleHorizont>
        <MHidden width="lgUp">
          <ToolbarStyle>
            <Stack sx={{width: '100%'}} direction="row" alignItems="center" justifyContent="space-between">
              <IconButton
                onClick={onOpenSidebar}
                sx={{ mr: 1, color: 'text.primary', display: { lg: 'none' } }}
              >
                <Iconify icon="eva:menu-2-fill" />
              </IconButton>
              <AccountPopover />
            </Stack>
          </ToolbarStyle>
          <Drawer
            open={isOpenSidebar}
            onClose={onCloseSidebar}
            PaperProps={{
              sx: { width: DRAWER_WIDTH }
            }}
          >
            {renderContent}
          </Drawer>
        </MHidden>
      </RootStyleHorizont>
      <RootStyle>
        <MHidden width="lgDown">
          <Drawer
            open
            variant="persistent"
            PaperProps={{
              sx: {
                width: DRAWER_WIDTH,
                bgcolor: 'background.default'
              }
            }}
          >
            {renderContent}
          </Drawer>
        </MHidden>
      </RootStyle>
    </>
  );
}
