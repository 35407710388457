import * as React from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import expand from '@iconify/icons-eva/expand-outline';
import collapse from '@iconify/icons-eva/collapse-fill';
// material
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  IconButton
} from '@mui/material';
import Iconify from './Iconify';

Modal.propTypes = {
  title: PropTypes.string,
  information: PropTypes.any,
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func
};

export default function Modal({ title, information, isOpen, handleClose }) {
  const [FullScreen, setFullScreen] = useState(false);
  const [width, setWidth] = useState('lg');

  const handleFullScreen = () => {
    if (FullScreen === false) {
      setFullScreen(true);
      setWidth(false);
    } else {
      setFullScreen(false);
      setWidth('lg');
    }
  };

  return (
    <div>
      <Dialog open={isOpen} onClose={handleClose} maxWidth={width} fullScreen={FullScreen}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          {typeof information === 'object' ? (
            <DialogContentText>{information}</DialogContentText>
          ) : (
            <DialogContentText>
              <div dangerouslySetInnerHTML={{ __html: information }} />
            </DialogContentText>
          )}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}
          >
            <Iconify icon="eva:close-outline" />
          </IconButton>
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={handleClose}>
            Regresar
          </Button>
          <IconButton onClick={handleFullScreen}>
            {FullScreen === false ? (
              <Icon icon={expand} width={20} height={20} color="blue" />
            ) : (
              <Icon icon={collapse} width={20} height={20} color="blue" />
            )}
          </IconButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
