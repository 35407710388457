import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
// material
import { Paper, Button, Container, Typography } from '@mui/material';
// components
import Modal from '../components/Modal';
import Pdf from '../components/Pdf';
import Page from '../components/Page';
import { Api } from '../app-controller';

const host = window.location.host.split(":")[0];

const itemStyle = {
  padding: '20px 25px 15px 20px',
  margin: '0px',
  overflow: 'hidden',
  height: '250px'
};

const imgStyle = {
  float: 'left',
  padding: '10px 10px 10px',
  height: '200px',
  width: '300px'
};

const titleStyle = {
  border: 'solid 0px red',
  padding: '50px 0px 0px',
  overflow: 'hidden',
  height: '100px'
  /* width: '450px' */
};

const hiddenStyle = {
  display: 'none'
};

Item.propTypes = {
  item: PropTypes.object
};

function Item(props) {
  const windowWidth = window.innerWidth;
  const [modalShow, setModalShow] = useState(false);

  const handleOpen = (e) => {
    e.stopPropagation();

    setModalShow(true);
  };

  const handleClose = () => {
    setModalShow(false);
  };

  return (
    <Paper style={itemStyle} elevation={24}>
      {props.item.imagen !== null ? (
        <img
          src={`${Api.url}getArchivo?id=${props.item.id}`}
          alt="Nombre de la imagen"
          style={windowWidth > 400 ? imgStyle : hiddenStyle}
        />
      ) : (
        <div style={windowWidth > 400 ? imgStyle : hiddenStyle}>
          <Pdf urlPdf={`${Api.url}getArchivo?id=${props.item.id}`} modal={250} />
        </div>
      )}

      {/* <h2 style={titleStyle}>{props.item.title}</h2> */}
      <Typography gutterBottom variant="h6" component="div" sx={titleStyle}>
        {props.item.titulo}
      </Typography>
      <Typography variant="body2" color="text.secondary" sx={hiddenStyle} />

      <Button className="CheckButton" onClick={(e) => handleOpen(e, props.item)}>
        Leer más
      </Button>

      <Modal
        key={props.item.id}
        title={props.item.titulo}
        information={
          props.item.url_pdf === null ? (
            props.item.contenido
          ) : (
            <Pdf urlPdf={`${Api.url}getArchivo?id=${props.item.id}`} />
          )
        }
        isOpen={modalShow}
        handleClose={handleClose}
      />
    </Paper>
  );
}

export default function Publications() {
  const params = useParams();
  const [publicaciones, setPublicaciones] = useState([]);

  useEffect(() => {
    fetch(`${Api.url}getPublicacionContenido?id=${params.id}`)
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setPublicaciones([...data]);
        }
      });
  }, []);

  return (
    <Page title={params.title}>
      <Container>
        <Typography variant="h4" gutterBottom>
          {params.title}
        </Typography>

        {publicaciones.map((publicacion, i) => (
          <Item key={i} item={publicacion} />
        ))}
      </Container>
    </Page>
  );
}
