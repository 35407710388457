import React, { useEffect, useState } from 'react';

import { NavLink as Link, matchPath, useLocation, useSearchParams } from 'react-router-dom'; // material
import { Box, Grid, Container, Typography, Paper } from '@mui/material';
// components
import Page from '../components/Page';
import { AppWeeklySales } from '../components/_dashboard/app';
import { Api } from '../app-controller';
// ----------------------------------------------------------------------
const host = window.location.host.split(':')[0];

const styles = {
  paperContainer: {
    height: '500px',
    backgroundImage: `url('/static/foto-coordinador.png')`
  }
};
export default function IndexAdmin() {
  const [nivel, setNivel] = useState('');

  useEffect(() => {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.token}`
      }
    };

    fetch(`${Api.url}auth/getNivelActual`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setNivel(data);
        }
      });
  }, [nivel]);

  return (
    <Page title="Inicio">
      <Container maxWidth="xl">
        {parseInt(nivel,10) === 1 ? (
          <>
            <Box sx={{ pb: 5 }}>
              <Typography variant="h4">Bienvenido, {sessionStorage.nombre}.</Typography>
            </Box>
            <Grid container spacing={3}>
              <Grid
                component={Link}
                to="/admin/general"
                sx={{ textDecoration: 'none' }}
                item
                xs={12}
                sm={6}
                md={4}
              >
                <AppWeeklySales
                  icon="eva:settings-2-fill"
                  titulo="Información General"
                  color="#C9EEC7"
                />
              </Grid>
              <Grid
                component={Link}
                to="/admin/contacto"
                sx={{ textDecoration: 'none' }}
                item
                xs={12}
                sm={6}
                md={4}
              >
                <AppWeeklySales icon="eva:phone-fill" titulo="Contacto" />
              </Grid>

              <Grid
                component={Link}
                to="/admin/directorio"
                sx={{ textDecoration: 'none' }}
                item
                xs={12}
                sm={6}
                md={4}
              >
                <AppWeeklySales icon="ps:contact" titulo="Directorio" />

              </Grid>
              <Grid
                component={Link}
                to="/admin/user"
                sx={{ textDecoration: 'none' }}
                item
                xs={12}
                sm={6}
                md={4}
              >
                <AppWeeklySales icon="eva:people-fill" titulo="Usuarios" />
              </Grid>
              <Grid
                component={Link}
                to="/admin/pestanas"
                sx={{ textDecoration: 'none' }}
                item
                xs={12}
                sm={6}
                md={4}
              >
                <AppWeeklySales icon="eva:browser-fill" titulo="Pestañas" />
              </Grid>
              <Grid
                component={Link}
                to="/admin/convocatorias"
                sx={{ textDecoration: 'none' }}
                item
                xs={12}
                sm={6}
                md={4}
              >
                <AppWeeklySales
                  icon="eva:clipboard-outline"
                  titulo="Convocatorias"
                  color="#C9EEC7"
                />
              </Grid>
              <Grid
                component={Link}
                to="/admin/licitaciones"
                sx={{ textDecoration: 'none' }}
                item
                xs={12}
                sm={6}
                md={4}
              >
                <AppWeeklySales
                  icon="eva:clipboard-outline"
                  titulo="Licitaciones"
                  color="#C9EEC7"
                />
              </Grid>
              <Grid
                component={Link}
                to="/admin/links"
                sx={{ textDecoration: 'none' }}
                item
                xs={12}
                sm={6}
                md={4}
              >
                <AppWeeklySales icon="eva:external-link-fill" titulo="Ligas" />
              </Grid>
              <Grid
                component={Link}
                to="/admin/piepagina"
                sx={{ textDecoration: 'none' }}
                item
                xs={12}
                sm={6}
                md={4}
              >
                <AppWeeklySales icon="eva:link-2-fill" titulo="Pie de página" />
              </Grid>
              <Grid
                component={Link}
                to="/admin/carpetas"
                sx={{ textDecoration: 'none' }}
                item
                xs={12}
                sm={6}
                md={4}
              >
                <AppWeeklySales icon="eva:folder-fill" titulo="Documentos" />
              </Grid>
            </Grid>
          </>
        ) : (
          <Grid container>
            <Grid item xs={12} sm={12} md={12}>
              <Paper style={styles.paperContainer}>
                <Box>
                  <Typography variant="h3" align="center" sx={{ opacity: 0.72 }}>
                    Bienvenido, {sessionStorage.nombre}.
                  </Typography>
                  <br />
                  <br />
                  <br />
                  <Typography variant="h3" align="center" sx={{ opacity: 0.72 }}>
                    {localStorage.titulo}
                  </Typography>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        )}
      </Container>
    </Page>
  );
}
