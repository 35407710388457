import React, { useState } from "react";
import PropTypes from 'prop-types';
import ReactQuill, {Quill} from 'react-quill';
import ImageUploader from 'quill-image-uploader';
import 'react-quill/dist/quill.snow.css';

Quill.register("modules/imageUploader", ImageUploader);

const modules = {
  toolbar: [
    ["bold", "italic", "underline", "strike"], // toggled buttons
    ["image", "link", "video"],
    ["blockquote"],

    // [{ header: 1 }, { header: 2 }], // custom button values
    [{ list: "ordered" }, { list: "bullet" }],
    // [{ script: "sub" }, { script: "super" }], // superscript/subscript
    // [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
    // [{ direction: "rtl" }], // text direction

    // [{ size: ["small", false, "large", "huge"] }], // custom dropdown
    [{ header: [1, 2, 3, 4, 5, 6, false] }],

    // [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    // [{ font: [] }],
    // [{ align: [] }],

    ["clean"]
  ],
  // # 4 Add module and upload function
  imageUploader: {
    upload: file => 
      new Promise((resolve, reject) => {
        const formData = new FormData();
        formData.append("image", file);
        formData.append("key", "94f2fe3481b7c0e3a1a1d2b10a6b71c5");
        formData.append("expiration", 36000);
        fetch("https://api.imgbb.com/1/upload?key", {
          method: "POST",
          body: formData
        })
        .then((response) => response.json())
        .then((result) => {
          resolve(result.data.display_url);
        })
        .catch((error) => {
          reject( new Error ("Upload failed"));
          console.error("Error:", error);
        });
      })
  }
};

EditorQuill.propTypes = {
  onHandleContent: PropTypes.func,
  only: PropTypes.bool,
  valueDefaul: PropTypes.string,
};

export default function EditorQuill({onHandleContent, only = false, valueDefaul = '' }) {
  const [value, setValue] = useState(valueDefaul);

  const handldeChangeContent = (e) => {
    setValue(e);

    onHandleContent(e);
  } 
  
  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
    "imageBlot" // #5 Optinal if using custom formats
  ];

  return (
    <ReactQuill 
      theme="snow"
      value={value}
      modules={modules}
      formats={formats}
      onChange={(e) => handldeChangeContent(e)}
      readOnly={only} />
  );
}