import * as Yup from 'yup';
import { useState } from 'react';
import Swal from 'sweetalert2';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-outline';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// material
import { Link, Stack, TextField, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Api } from '../../../app-controller';

// ----------------------------------------------------------------------
const host = window.location.host.split(':')[0];

export default function LoginForm() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email('Correo electrónico no válido')
      .required('Correo electrónico es obligatorio'),
    password: Yup.string()
      .min(6, 'Contraseña mínima de 6 carácteres')
      .max(30, 'Contraseña máxima de 30 carácteres')
      .required('Constraseña es requerida')
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      remember: true
    },
    validationSchema: LoginSchema,
    onSubmit: (value) => {
      Api.auth.login(value, (e) => handleAux(e));
    }
  });

  const handleAux = (response) => {
    if (response) {
      sessionStorage.setItem('isAuthenticated', 'true');
      sessionStorage.setItem('token', response.token);

      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${response.token}`
        }
      };

      fetch(`${Api.url}auth/profile`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          sessionStorage.setItem(
            'nombre',
            `${response.user.nombre} ${response.user.apellido_paterno} ${response.user.apellido_materno}`
          );
          sessionStorage.setItem('email', response.user.email);
          sessionStorage.setItem('nivel', data.nivel);
          window.location.href = '/admin/inicio';
        })
        .catch((error) => {
          Swal.fire({
            title: 'Error',
            text: 'Tu sesión ha expirado, por favor accede de nuevo.',
            icon: 'error',
            showCancelButton: false,
            cancelButtonColor: 'green',
            confirmButtonText: 'Continuar',
            allowOutsideClick: false
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.href = '/login';
            }
          });
        });
    }
  };

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label="Correo electrónico"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Contraseña"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <Link component={RouterLink} variant="subtitle2" to="/recuperar-contrasena">
            ¿Olvidaste tu contraseña?
          </Link>
        </Stack>

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          Iniciar sesión
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}
