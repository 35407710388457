import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// material
import {
  Card,
  Table,
  IconButton,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableContainer
} from '@mui/material';
import Scrollbar from '../Scrollbar';
import Iconify from '../Iconify';

TablaLista.propTypes = {
  arrayColumn: PropTypes.array,
  arrayRows: PropTypes.array,
  onHandleDelete: PropTypes.func
};

export default function TablaLista({ arrayColumn, arrayRows, onHandleDelete, view = false }) {
  const [columnas, setColumnas] = useState(arrayColumn);
  useEffect(() => {
    setColumnas(arrayColumn);
  }, [arrayColumn]);

  const [rows, setRows] = useState(arrayRows);
  useEffect(() => {
    setRows(arrayRows);
  }, [arrayRows]);

  return (
    <Card>
      <Scrollbar>
        <TableContainer sx={{ minWidth: '100%' }}>
          <Table>
            <TableBody>
              {rows.map((row, index) => (
                <TableRow key={index}>
                  <TableCell align="left">{row.descripcion}</TableCell>
                  <TableCell align="left">{row.link}</TableCell>
                  {!view ? (
                    <TableCell align="center">
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={(e) => onHandleDelete(e, index)}
                      >
                        <Iconify icon="eva:close-fill" />
                      </IconButton>
                    </TableCell>
                  ) : null}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>
    </Card>
  );
}
