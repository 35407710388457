import React, {useState, useEffect} from 'react';
import { useParams } from 'react-router-dom';
// material
import { Container, Stack, Typography } from '@mui/material';
// components
import Page from '../components/Page';
import Pdf from '../components/Pdf';
import Pdf1 from '../lorem-ipsum.pdf';
import { Api } from '../app-controller';

export default function Convocatorias() {
  const host = window.location.host.split(":")[0];
  const params = useParams();
  const [ext, setExt] = useState(true);
  const [titulo, setTitulo] = useState('');
  
  useEffect(() => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };

    fetch(
      `${Api.url}getConvocatoria?id=${params.id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        setExt(data.convocatoria.ext === "pdf");
        setTitulo(data.convocatoria.titulo);
      });
  }, [params.id]);

  return (
    <Page title={`Convocatoria | ${params.title}`}>
      <Container>
        <br/>
        <Typography variant="h4" align="center" gutterBottom>
          {titulo}
        </Typography>
        <Stack alignItems="center" justifyContent="space-between" mb={5}>
        {ext ? (
            <Pdf urlPdf={`${Api.url}getConvocatoriaPdf?id=${params.id}`} />
          ) : (
            <img
              src={`${Api.url}getConvocatoriaPdf?id=${params.id}`}
              alt="Nombre de la imagen"
              style={{width: '100%'}}
            />
          )}
        </Stack>
      </Container>
    </Page>
  );
}
