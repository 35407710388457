import * as React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/system';
import { List, ListItem, Stack, Typography, Grid } from '@mui/material';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Scrollbar from './Scrollbar';
import CardInformation from './CardInformation';

CardList.propTypes = {
  titulo: PropTypes.string,
  arrayCard: PropTypes.array
};

export default function CardList({ titulo, arrayCard }) {
  const columnas = arrayCard.length > 4 ? 4 : arrayCard.length;

  return (
    <Box>
      <Scrollbar sx={{ width: '100%' }}>
        <List
          sx={{
            display: 'flex',
            flexDirection: 'row',
            padding: 0
          }}
        >
          {arrayCard.map((card, index) => (
            <ListItem>
              <CardInformation  key={index} contents={card}/>
            </ListItem>
          ))}
        </List>
      </Scrollbar>
    </Box>
  );
}
