import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import plusFill from "@iconify/icons-eva/plus-fill";
import { Link } from "react-router-dom";
// material
import {
  Box,
  Card,
  Table,
  Stack,
  Button,
  IconButton,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  Paper,
  TablePagination,
} from "@mui/material";
import Swal from "sweetalert2";
// components
import Label from "../components/Label";
import Page from "../components/Page";
import Scrollbar from "../components/Scrollbar";
import Search from "../components/Search";
import ModalInput from "../components/ModalInput";
import ModalEdit from "../components/ModalEdit";
import Iconify from "../components/Iconify";
import Modal from "../components/Modal";
import Pdf from "../components/Pdf";
import { Api } from "../app-controller";

// ----------------------------------------------------------------------
const host = window.location.host.split(":")[0];

const tableHead = [
  { id: "titulo", label: "Documento", alignRight: false },
  { id: "usuario", label: "Creador", alignRight: false },
  { id: "status", label: "Estado", alignRight: false },
  { id: "opciones", label: "Opciones", alignRight: true },
];

const title = "Nuevo documento";

export default class Layout extends React.Component {
  constructor() {
    super();
    const location = window.location.pathname;
    const id = location.slice(-1);

    this.state = {
      documentos: [],
      carpeta: [],
      searchArray: [],
      elementosA: [],
      elementosE: [],
      carpetaID: id,
      nivel: null,
      acceso: null,
      documentoID: null,
      notFound: false,
      rowsPerPage: 5,
      page: 0,
      total: 0,
      search: "",
      modalShow: false,
      modalShowE: false,
      modalShowV: false,
      archivo: "",
      archivo_id: 0,
    };
  }

  componentDidMount() {
    this.setDocumentos();
    this.setElementos();
    this.setCatalogos();
  }

  setCatalogos = () => {
    fetch(`${Api.url}getStatus`)
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          this.setSearch(data.statusArray);
        }
      });
  };

  setDocumentos = (query = "") => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.token}`,
      },
    };

    fetch(
      `${Api.url}getDocumentos?id=${this.state.carpetaID}&${query}`,
      requestOptions
    )
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        }
        throw new Error(response.status);
      })
      .then((data) => {
        if (data) {
          const auxNotFound = data.documentos.total === 0;

          this.setState({
            documentos: data.documentos.data,
            carpeta: data.carpeta,
            total: data.documentos.total,
            page: data.documentos.current_page - 1,
            notFound: auxNotFound,
            nivel: data.nivel,
            acceso: data.acceso,
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: "No tiene acceso a ésta información",
          icon: "error",
          showCancelButton: false,
          cancelButtonColor: "green",
          confirmButtonText: "Continuar",
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = "/admin/inicio";
          }
        });
      });
  };

  setSearch = (statusArray) => {
    this.setState({
      searchArray: [
        {
          id: "titulo",
          label: "Documento",
          input: "text",
        },
        {
          id: "usuario",
          label: "Creador",
          input: "text",
        },
        {
          id: "status",
          label: "Estado",
          input: "select",
          select: statusArray,
        },
      ],
    });
  };

  setElementos = () => {
    this.setState({
      elementosA: [
        {
          id: "titulo_M",
          label: "Título",
          input: "text",
          required: true,
          value: "",
          maxLength: 100,
        },
        {
          id: "documento_M",
          label: "Documento",
          input: "archivo",
          value: null,
          ext: "multipart/form-data",
          required: true,
        },
      ],
    });
  };

  handleSearch = (search) => {
    let src = "";
    search.forEach((param) => {
      if (
        param.value !== undefined &&
        param.value !== null &&
        param.value !== ""
      )
        src += `&${param.nombre}=${param.value}`;
    });

    this.setState({ search: src });
    this.setDocumentos(src);
  };

  handleChangeRowsPerPage = (event) => {
    const auxRowsPerPage = parseInt(event.target.value, 10);
    this.setState({ rowsPerPage: auxRowsPerPage, page: 0 });
    this.setDocumentos(`rowsPerPage=${auxRowsPerPage}${this.state.search}`);
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
    this.setDocumentos(
      `page=${newPage + 1}&rowsPerPage=${this.state.rowsPerPage}${
        this.state.search
      }`
    );
  };

  handleOpen = () => {
    this.setState({ modalShow: true });
  };

  handleClose = () => {
    this.setState({ modalShow: false });
  };

  handleOpenE = () => {
    this.setState({ modalShowE: true });
  };

  handleCloseE = () => {
    this.setState({ modalShowE: false, elementosE: [] });
  };

  handleAddDocumento = (data) => {
    this.handleClose();
    Api.loading(true);

    const postData = new FormData();
    postData.append("carpetaID", this.state.carpeta.id);

    data.forEach((e) => {
      postData.append(e.nombre, e.value);
    });

    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${sessionStorage.token}`,
      },
      body: postData,
    };

    fetch(`${Api.url}createDocumento`, requestOptions)
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        }
        throw new Error(response.status);
      })
      .then((data) => {
        Api.loading(false);

        Swal.fire({
          text: data.message,
          allowOutsideClick: false,
        });
        this.setDocumentos(); // Actualizo tabla de documentos
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: "No tiene acceso a ésta información",
          icon: "error",
          showCancelButton: false,
          cancelButtonColor: "green",
          confirmButtonText: "Continuar",
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = "/admin/inicio";
          }
        });
      });
  };

  handleStatusDocumento = (id) => {
    Swal.fire({
      icon: "question",
      title: "¿Está seguro de realizar la acción?",
      showDenyButton: true,
      denyButtonColor: "red",
      confirmButtonColor: "#4CD964",
      showCancelButton: false,
      confirmButtonText: `Continuar`,
      denyButtonText: `Regresar`,
      reverseButtons: true,
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        const requestOptions = {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.token}`,
          },
          body: JSON.stringify({ id }),
        };

        fetch(`${Api.url}deactivateDocumento`, requestOptions)
          .then((response) => {
            if (response.status === 200) {
              return response.json();
            }
            throw new Error(response.status);
          })
          .then((data) => {
            Swal.fire({
              text: data.message,
              allowOutsideClick: false,
            });
            this.setDocumentos(); // Actualizo tabla de documentos
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              text: "No tiene acceso a ésta información",
              icon: "error",
              showCancelButton: false,
              cancelButtonColor: "green",
              confirmButtonText: "Continuar",
              allowOutsideClick: false,
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.href = "/admin/inicio";
              }
            });
          });
      }
    });
  };

  handleGetDocumentoEdit = (id) => {
    this.setState({ documentoID: id }, this.setDocumento(id));
    this.handleOpenE();
  };

  setDocumento = (id) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.token}`,
      },
    };

    fetch(`${Api.url}getDocumento?id=${id}`, requestOptions)
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        }
        throw new Error(response.status);
      })
      .then((data) => {
        if (data) {
          this.setState({
            elementosE: [
              {
                id: "titulo_M",
                label: "Título",
                input: "text",
                required: true,
                value: data.documento.titulo,
                maxLength: 100,
              },
              {
                id: "documento_M",
                label: "Documento",
                input: "archivo",
                ext: "multipart/form-data",
                required: true,
                value: data.documento.url,
              },
            ],
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: "No tiene acceso a ésta información",
          icon: "error",
          showCancelButton: false,
          cancelButtonColor: "green",
          confirmButtonText: "Continuar",
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = "/admin/inicio";
          }
        });
      });
  };

  handleUpdateDocumento = (data) => {
    this.handleCloseE();
    Api.loading(true);

    const postData = new FormData();
    postData.append("id", this.state.documentoID);
    postData.append("_method", "PUT");

    data.forEach((e) => {
        postData.append(e.nombre, e.value);
    });

    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${sessionStorage.token}`,
      },
      body: postData,
    };

    fetch(`${Api.url}updateDocumento`, requestOptions)
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        }
        throw new Error(response.status);
      })
      .then((data) => {
        Api.loading(false);

        Swal.fire({
          text: data.message,
          allowOutsideClick: false,
        });

        this.setDocumentos(); // Actualizo tabla de documentos
        this.setDocumento(this.state.documentoID);
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: "No tiene acceso a ésta información",
          icon: "error",
          showCancelButton: false,
          cancelButtonColor: "green",
          confirmButtonText: "Continuar",
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = "/admin/inicio";
          }
        });
      });
  };

  handleVerArchivo = (id) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionStorage.token}`,
      },
    };

    fetch(`${Api.url}getDocumento?id=${id}`, requestOptions)
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        }
        throw new Error(response.status);
      })
      .then((data) => {
        if (data) {
          console.log(data.ext);
          if (
            data.ext !== "jpg" ||
            data.ext !== "png" ||
            data.ext !== "pdf" ||
            data.ext !== "peg"
          ) {
            this.setState({
              archivo_id: id,
              archivo: data.documento.ext,
            });
            this.setStateVer();
          } else {
            Swal.fire({
              text: "Debido al tipo de archivo no se puede visualizar",
              allowOutsideClick: false,
            });
          }
          console.log(this.state.archivo_id);
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: "No tiene acceso a ésta información",
          icon: "error",
          showCancelButton: false,
          cancelButtonColor: "green",
          confirmButtonText: "Continuar",
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = "/admin/inicio";
          }
        });
      });
  };

  setStateVer = () => {
    this.setState({
      modalShowV: true,
    });
  };

  handleDownloadArchivo = (id) => {
    const link = document.createElement("a");
    link.href = `${Api.url}getArchivoCarpeta?id=${id}&download=1`;
    link.click();
  };

  render() {
    return (
      <Page title={this.state.carpeta.titulo}>
        <Container>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ width: "100%" }}
          >
            <Typography variant="h4" gutterBottom>
              Documentos | {this.state.carpeta.titulo}
            </Typography>
            <Box>
              {parseInt(this.state.acceso, 10) === 1 ||
              parseInt(this.state.nivel, 10) === 1 ? (
                <Button
                  variant="contained"
                  startIcon={<Icon icon={plusFill} />}
                  onClick={() => this.handleOpen(true)}
                >
                  Nuevo documento
                </Button>
              ) : null}

              <ModalInput
                title={title}
                elementos={this.state.elementosA}
                isOpen={this.state.modalShow}
                handleClose={this.handleClose}
                onHandleSubmit={this.handleAddDocumento}
              />
              <ModalEdit
                title="Editar documento"
                elementos={this.state.elementosE}
                isOpen={this.state.modalShowE}
                handleClose={this.handleCloseE}
                onHandleSubmit={this.handleUpdateDocumento}
              />
            </Box>
          </Stack>
          <Box sx={{ my: 2 }}>
            <Search
              onHandleSearch={this.handleSearch}
              elementos={this.state.searchArray}
            />
          </Box>
          <Card>
            <Scrollbar>
              <TableContainer sx={{ minWidth: "100%" }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      {tableHead.map((headCell, index) => (
                        <TableCell key={index} align="left">
                          {headCell.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.documentos.map((documento, index) => (
                      <TableRow key={index}>
                        <TableCell align="left">{documento.titulo}</TableCell>
                        <TableCell align="left">{documento.nombre}</TableCell>
                        <TableCell align="left">
                          <Label
                            variant="ghost"
                            color={
                              (parseInt(documento.status, 10) === 0 &&
                                "error") ||
                              "success"
                            }
                          >
                            {documento.status_descripcion}
                          </Label>
                        </TableCell>
                        <TableCell>
                          {documento.ext === "pdf" ||
                          documento.ext === "jpeg" ||
                          documento.ext === "jpg" ||
                          documento.ext === "png" ? (
                            <IconButton
                              onClick={() =>
                                this.handleVerArchivo(documento.id)
                              }
                            >
                              <Iconify icon="eva:eye-outline" />
                            </IconButton>
                          ) : (
                            <IconButton disabled>
                              <Iconify icon="eva:eye-outline" />
                            </IconButton>
                          )}
                          <IconButton
                            onClick={() =>
                              this.handleDownloadArchivo(documento.id)
                            }
                          >
                            <Iconify icon="eva:download-fill" />
                          </IconButton>
                          {parseInt(this.state.nivel, 10) === 1 ? (
                            <>
                              <IconButton
                                onClick={() =>
                                  this.handleGetDocumentoEdit(documento.id)
                                }
                              >
                                <Iconify icon="eva:edit-2-outline" />
                              </IconButton>
                              <IconButton
                                onClick={() =>
                                  this.handleStatusDocumento(documento.id)
                                }
                              >
                                {parseInt(documento.status, 10) === 1 ? (
                                  <Iconify icon="eva:close-fill" />
                                ) : (
                                  <Iconify icon="eva:checkmark-outline" />
                                )}
                              </IconButton>
                            </>
                          ) : null}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                  {this.state.notFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <Paper>
                            <Typography variant="body2" align="center">
                              No se encontraron resultados.
                            </Typography>
                          </Paper>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              labelRowsPerPage="Renglones por página"
              labelDisplayedRows={({ from, to, count }) =>
                `${from} - ${to} de ${count}`
              }
              count={this.state.total}
              rowsPerPage={this.state.rowsPerPage}
              page={this.state.page}
              onPageChange={this.handleChangePage}
              onRowsPerPageChange={this.handleChangeRowsPerPage}
            />
          </Card>
          <Modal
            title="Ver Archivo"
            information={
              this.state.archivo === "pdf" ? (
                <Pdf
                  urlPdf={`${Api.url}getArchivoCarpeta?id=${this.state.archivo_id}`}
                />
              ) : (
                <img
                  // style={imgStyle}
                  src={`${Api.url}getArchivoCarpeta?id=${this.state.archivo_id}`}
                  alt="Nombre de la imagen"
                />
              )
            }
            isOpen={this.state.modalShowV}
            handleClose={() => this.setState({ modalShowV: false })}
          />
        </Container>
      </Page>
    );
  }
}
