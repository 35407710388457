import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import { Stack, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Api } from '../../../app-controller';

// ----------------------------------------------------------------------

const host = window.location.host.split(":")[0];

export default function RecoverForm() {

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email('Correo electrónico no válido')
      .required('Correo electrónico es obligatorio')
  });

  const formik = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: LoginSchema,
    onSubmit: (value) => {
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(value)
      };

      fetch(`${Api.url}confirmEmail`, requestOptions)
        .then((response) => {
          if (response.status === 200) {
            return response.json();
          }
          throw new Error(response.msg);
        })
        .then((data) => {
          Swal.fire({
            text: data.msg,
            icon: 'success',
            allowOutsideClick: false
          }).then(() => {
            window.location.href = '/login';
          });
        })
        .catch((error) => {
          Swal.fire({
            title: 'Error',
            text: `La cuenta ${value.email} no se encuentra registrada, comuniquese con el administrador.`,
            icon: 'error',
            showCancelButton: false,
            cancelButtonColor: 'green',
            confirmButtonText: 'Continuar',
            allowOutsideClick: false
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.href = '/login';
            }
          });
        });
    }
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3} alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <Typography variant="h8" gutterBottom>
            Ingrese el correo electrónico con el que dió de alta su cuenta
          </Typography>

          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label="Correo electrónico"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />
        </Stack>

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          Recuperar contraseña
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}
