import React from 'react';
// material
import { Box, Grid, Button, Container, Stack, Typography } from '@mui/material';
import Swal from 'sweetalert2';
import TextField from '@mui/material/TextField';
// components
import Page from '../components/Page';
import { Api } from '../app-controller';
// ----------------------------------------------------------------------
const host = window.location.host.split(':')[0];

export default class ContactAdmin extends React.Component {
  constructor() {
    super();
    this.state = {
      direccion: '',
      telefono_principal: '',
      telefono_secundario: '',
      email: '',
      otros: ''
    };
  }

  componentDidMount() {
    this.getInfo();
  }

  getInfo = () => {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.token}`
      }
    };

    fetch(`${Api.url}getInfoContactAdmin`, requestOptions)
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        }
        throw new Error(response.status);
      })
      .then((data) => {
        if (data) {
          this.setState({ direccion: data.info.direccion });
          this.setState({ telefono_principal: data.info.telefono_principal });
          this.setState({ telefono_secundario: data.info.telefono_secundario });
          this.setState({ email: data.info.email });
          this.setState({ otros: data.info.otros });
        }
      })
      .catch((error) => {
        Swal.fire({
          title: 'Error',
          text: 'No tiene acceso a ésta información',
          icon: 'error',
          showCancelButton: false,
          cancelButtonColor: 'green',
          confirmButtonText: 'Continuar',
          allowOutsideClick: false
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = '/admin/inicio';
          }
        });
      });
  };

  handleChange = (event) => {
    const element = event.target;

    switch (element.id) {
      case 'direccion':
        this.setState({ direccion: element.value });
        break;
      case 'telefono_principal':
        this.setState({ telefono_principal: element.value });
        break;
      case 'telefono_secundario':
        this.setState({ telefono_secundario: element.value });
        break;
      case 'email':
        this.setState({ email: element.value });
        break;
      case 'otros':
        this.setState({ otros: element.value });
        break;
      default:
        break;
    }
  };

  handleSubmit = (data) => {
    data.preventDefault();
    const postData = {};

    Object.assign(postData, { direccion: this.state.direccion });
    Object.assign(postData, { telefono_principal: this.state.telefono_principal });
    Object.assign(postData, { telefono_secundario: this.state.telefono_secundario });
    Object.assign(postData, { email: this.state.email });
    Object.assign(postData, { otros: this.state.otros });

    const requestOptions = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionStorage.token}`
      },
      body: JSON.stringify({ postData })
    };

    fetch(`${Api.url}updateContactInfo`, requestOptions)
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        }
        throw new Error(response.status);
      })
      .then((data) => {
        Swal.fire({
          text: data.message,
          allowOutsideClick: false
        });
      })
      .catch((error) => {
        Swal.fire({
          title: 'Error',
          text: 'No tiene acceso a ésta información',
          icon: 'error',
          showCancelButton: false,
          cancelButtonColor: 'green',
          confirmButtonText: 'Continuar',
          allowOutsideClick: false
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = '/admin/inicio';
          }
        });
      });
  };

  render() {
    return (
      <Page title="Información de contacto">
        <Container>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ width: '100%' }}
          >
            <Typography variant="h4" gutterBottom>
              Información Contacto
            </Typography>
          </Stack>
          <br />
          <Box>
            <form onSubmit={(e) => this.handleSubmit(e)}>
              <Typography sx={{ marginTop: 2 }}>
                Dirección <span style={{ color: 'red' }}>*</span>
              </Typography>
              <TextField
                defaultValue={this.state.direccion}
                onChange={this.handleChange}
                required
                id="direccion"
                placeholder="Dirección"
                multiline
                rows={2}
                fullWidth
                inputProps={{ maxLength: 150 }}
              />
              <Typography sx={{ marginTop: 2 }}>
                Teléfono principal <span style={{ color: 'red' }}>*</span>
              </Typography>
              <TextField
                value={this.state.telefono_principal}
                onChange={this.handleChange}
                required
                id="telefono_principal"
                placeholder="Teléfono principal"
                fullWidth
                inputProps={{ maxLength: 10 }}
              />
              <Typography sx={{ marginTop: 2 }}>Teléfono secundario</Typography>
              <TextField
                value={this.state.telefono_secundario}
                onChange={this.handleChange}
                id="telefono_secundario"
                placeholder="Teléfono secundario"
                fullWidth
                inputProps={{ maxLength: 10 }}
              />
              <Typography sx={{ marginTop: 2 }}>
                Correo electrónico <span style={{ color: 'red' }}>*</span>
              </Typography>
              <TextField
                value={this.state.email}
                onChange={this.handleChange}
                required
                id="email"
                placeholder="Correo electrónico"
                fullWidth
                inputProps={{ maxLength: 100 }}
              />
              <Typography sx={{ marginTop: 2 }}>Otros</Typography>
              <TextField
                value={this.state.otros}
                onChange={this.handleChange}
                id="otros"
                placeholder="Otros"
                multiline
                rows={3}
                fullWidth
                inputProps={{ maxLength: 255 }}
              />
              <div align="right">
                <Button size="large" variant="contained" type="submit" sx={{ marginTop: 2 }}>
                  Guardar
                </Button>
              </div>
            </form>
          </Box>
        </Container>
      </Page>
    );
  }
}
