import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
// material
import { Container, Card, Stack, Typography } from "@mui/material";
// components
import Page from "../components/Page";
import Pdf from "../components/Pdf";
import { Api } from "../app-controller";


export default function DirectorioPublico() {
  const params = useParams();
  const [ext, setExt] = useState(true);
  const [carga, setCargando] = useState(false);
  const [titulo, setTitulo] = useState("Directorio");

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };

    fetch(`${Api.url}getDirectorioPublico`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data[0] !== null) {
          setExt(data.esPdf);
          setCargando(true);
        }
      });
  }, []);

  return (
    <Page title={titulo}>
      <Container>
        <br />
        <Typography variant="h4" align="center" gutterBottom>
          {titulo}
        </Typography>
        <Card>
          {carga ? (
            <Stack alignItems="center" justifyContent="space-between" mb={5}>
              {ext ? (
                <Pdf urlPdf={`${Api.url}getDirectorio`} />
              ) : (
                <img
                  src={`${Api.url}getDirectorio`}
                  alt="Nombre de la imagen"
                  style={{ width: "100%" }}
                />
              )}{" "}
            </Stack>
          ) : null}
        </Card>
      </Container>
    </Page>
  );
}
