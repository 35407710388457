import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
//
import Login from './pages/Login';
import IndexAdmin from './pages/IndexAdmin';
import DashboardApp from './pages/DashboardApp';
import User from './pages/User';
import Convocatorias from './pages/Convocatorias';
import Licitaciones from './pages/Licitaciones';
import Publications from './pages/Publications';
import Contact from './pages/Contact';
import Carpetas from './pages/Carpetas';
import Carpeta from './pages/Carpeta';
import Links from './pages/Links';
import PestanaAdmin from './pages/PestanaAdmin';
import PaginaPrueba from './pages/PaginaPrueba';
import GeneralInfo from './pages/GeneralInfo';
import ContactAdmin from './pages/ContactAdmin';
import Directorio from './pages/Directorio';
import DirectorioPublico from './pages/DirectorioPublico';
import FooterAdmin from './pages/FooterAdmin';
import Pestana from './pages/Pestana';
import Carrousel from './pages/Carrousel';
import LicitacionesAdmin from './pages/LicitacionesAdmin';
import ConvocatoriasAdmin from './pages/ConvocatoriasAdmin';
import PublicationsAdmin from './pages/PublicationsAdmin';
import TimelineAdmin from './pages/TimelineAdmin';
import GalleryAdmin from './pages/GalleryAdmin';
import ResumenAdmin from './pages/ResumenAdmin';
import RecoverPassword from './pages/RecoverPassword';
import ChangePassword from './pages/ChangePassword';
import Page404 from './pages/Page404';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/pagina#inicio" replace /> },
        { path: '/inicio', element: <DashboardApp /> },
        { path: 'convocatorias/:title/:id', element: <Convocatorias /> },
        { path: 'licitaciones/:title/:id', element: <Licitaciones /> },
        { path: 'publicaciones/:title/:id', element: <Publications /> },
        { path: 'contacto', element: <Contact /> },
        { path: 'login', element: <Login /> },
        { path: 'pagina', element: <PaginaPrueba /> },
        { path: 'recuperar-contrasena', element: <RecoverPassword /> },
        { path: 'cambiar-contrasena/:token', element: <ChangePassword /> },
        { path: '*', element: <Navigate to="/404" /> },
        { path: '/404', element: <Page404 /> },
        { path: 'directoriopublico', element: <DirectorioPublico /> }

      ] 
    },
    {
      path: '/admin/',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/inicio" replace /> },
        { path: 'inicio', element: <IndexAdmin /> },
        { path: 'user', element: <User /> },
        { path: 'carpetas', element: <Carpetas /> },
        { path: 'links', element: <Links /> },
        { path: 'pestanas', element: <PestanaAdmin /> },
        { path: 'piepagina', element: <FooterAdmin />},
        { path: 'contacto', element: <ContactAdmin /> },
        { path: 'directorio', element: <Directorio /> },
        { path: 'general', element: <GeneralInfo /> },
        { path: 'pestana/:id', element: <Pestana /> },
        { path: 'carpeta/:id', element: <Carpeta /> },
        { path: 'convocatorias', element: <ConvocatoriasAdmin /> },
        { path: 'carrusel/:id', element: <Carrousel /> },
        { path: 'publicaciones/:id', element: <PublicationsAdmin /> },
        { path: 'licitaciones', element: <LicitacionesAdmin /> },
        { path: 'timeline/:id', element: <TimelineAdmin /> },
        { path: 'galeria/:id', element: <GalleryAdmin /> },
        { path: 'resumen/:id', element: <ResumenAdmin /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}