import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  List,
  ListItem,
  Stack,
  DialogActions,
  Button,
  Paper,
  Checkbox,
  Typography
} from '@mui/material';
import Iconify from './Iconify';
import TablaListaAccesosCarpeta from './auxiliaresComponentes/TablaListaAccesosCarpeta';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.text.secondary
}));

export default function ModalSelectMultiple({
  title,
  usuarios,
  seleccionados,
  isOpen,
  handleClose,
  onHandleSubmit
}) {
  const fullWidth = true;
  const [value, setValue] = useState('');
  const [aux, setAux] = useState('');

  const handleChangeSelect = (event, value, index) => {
    const selectElement = document.querySelector(`#select`);
    selectElement.value = value;
    setValue(value);
  };

  const addToList = () => {
    const id = document.getElementById('select').value;
    seleccionados.push(usuarios.find((i) => i.id === id));
    const index = usuarios.findIndex((x) => x.id === id);
    usuarios.splice(index, 1);
    setValue('');

    const seleccionado = seleccionados.find((i) => i.id === id);
    seleccionado.acceso = 0;
  };

  const acceso = (event, id) => {
    event.preventDefault();
    const seleccionado = seleccionados.find((i) => i.id === id);
    if(parseInt(seleccionado.acceso,10) === 1 ) {
      seleccionado.acceso = 0;
    } else {
      seleccionado.acceso = 1;
    }

    setAux(id);
  };

  const deleteItem = (event, index) => {
    event.preventDefault();
    usuarios.push(seleccionados.find((i) => i.id === index));
    const selId = seleccionados.findIndex((x) => x.id === index);
    seleccionados.splice(selId, 1);
    setAux(index);
  };

  useEffect(() => {
    setAux(null);
  }, [aux]);

  const handleGetData = (event) => {
    event.preventDefault();

    const data = seleccionados;

    onHandleSubmit(data);
  };
  return (
    <div>
      <Dialog open={isOpen} onClose={handleClose} fullWidth={fullWidth}>
        <DialogTitle>
          {title}
          {handleClose ? (
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500]
              }}
            >
              <Iconify icon="eva:close-outline" />
            </IconButton>
          ) : null}
        </DialogTitle>

        <form onSubmit={(e) => handleGetData(e)} fullWidth>
          <DialogContent>
            <Typography sx={{ marginTop: 2, marginBottom: 2 }}>
              Seleccione los usuarios que tendrán acceso a los documentos
            </Typography>
            <FormControl fullWidth>
              <InputLabel htmlFor="select">Seleccione usuario</InputLabel>
              <Box sx={{ width: '100%' }}>
                <Grid container spacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                  <Grid item xs={10}>
                    <Item>
                      <Select id="select" label="Seleccione usuario" fullWidth>
                        {usuarios.map((option, index) => (
                          <MenuItem
                            key={option.id}
                            value={option.id}
                            onClick={(e) => handleChangeSelect(e, option.id, index)}
                          >
                            {option.nombre}
                          </MenuItem>
                        ))}
                      </Select>
                    </Item>
                  </Grid>
                  <Grid item xs={2}>
                    <Item>
                      {value ? (
                        <IconButton onClick={addToList}>
                          <Iconify icon="eva:plus-outline" />
                        </IconButton>
                      ) : (
                        <IconButton disabled onClick={addToList}>
                          <Iconify icon="eva:plus-outline" />
                        </IconButton>
                      )}
                    </Item>
                  </Grid>
                </Grid>
              </Box>

              {/* </Stack> */}

              {seleccionados.length > 0 ? (
                <>
                  <Typography sx={{ marginTop: 2, marginBottom: 2 }}>
                    Seleccionar casilla a usuarios que tendrán acceso a subir documentación
                  </Typography>
                  <TablaListaAccesosCarpeta
                    arrayColumn={[
                      { id: 'descripcion', label: ' ', alignRight: false },
                      { id: 'link', label: ' ', alignRight: false },
                      { id: ' ', label: ' ', alignRight: false }
                    ]}
                    arrayRows={seleccionados}
                    onHandleDelete={deleteItem}
                    onHandleAcceso={acceso}
                  />
                </>
              ) : null}
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" type="submit">
              Guardar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
