import * as React from 'react';
import PropTypes, { element } from 'prop-types';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import { Icon } from '@iconify/react';
import searchFill from '@iconify/icons-eva/search-fill';
// material
import {
  Box,
  Card,
  Button,
  IconButton,
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
  Select,
  FormGroup,
  FormControlLabel,
  Checkbox,
  RadioGroup,
  Radio,
  Stack
} from '@mui/material';
import { useState } from 'react';

Search.propTypes = {
  onHandleSearch: PropTypes.func
};

export default function Search({ onHandleSearch, elementos }) {
  const [date, setDate] = useState(null);

  const le = elementos.length;

  const handleGetSearch = () => {
    const data = [];
    elementos.forEach((item) => {
      if (item.input === 'date') {
        data.push({ nombre: item.id, value: date });
      } else {
        data.push({ nombre: item.id, value: document.getElementById(item.id).value });
      }
    });

    onHandleSearch(data);
  };

  const handleChangeSelect = (event, id, value) => {
    const selectElement = document.querySelector(`#${id}`);
    selectElement.value = value;
  };

  let n = '';

  if (le === 2) {
    n = '48%';
  } else if (le % 3 === 0) {
    n = '31%';
  } else if (le % 4 === 0 || le === 7) {
    n = '23%';
  } else {
    n = '18%';
  }

  return (
    <Card>
      <Box
        component="form"
        sx={{
          '& > :not(style)': { m: 1 }
        }}
      >
        {elementos.map((item) => (
          <FormControl sx={{ width: n }}>
            {item.input === 'select' ? (
              <>
                <InputLabel htmlFor={item.id}>{item.label}</InputLabel>
                <Select
                  id={item.id}
                  label={item.id}
                >
                  {item.select.map((option, index) => (
                    <MenuItem value={index} onClick={(e) => handleChangeSelect(e, item.id, option.valor)}>
                      {option.descripcion}
                    </MenuItem>
                  ))}
                </Select>
              </>
            ) : null}
            {item.input === 'number' ? (
              <TextField
                // autoFocus
                id={item.id}
                label={item.label}
                type={item.input}
                variant="outlined"
              />
            ) : null}
            {item.input === 'text' ? (
              <TextField
                // autoFocus
                id={item.id}
                label={item.label}
                maxRows={10}
                multiline
                variant="outlined"
              />
            ) : null}
            {item.input === 'date' ? (
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  id={item.id}
                  value={date}
                  onChange={(newValue) => {
                    setDate(newValue);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            ) : null}
          </FormControl>
        ))}
      </Box>
      <Box sx={{ pb: 1, pr: 1 }} textAlign="right">
        <Button color="warning" onClick={handleGetSearch} startIcon={<Icon icon={searchFill} />}>
          Buscar
        </Button>
      </Box>
    </Card>
  );
}
